import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPreviewPost({
  id,
  idType = "DATABASE_ID",
  previewToken,
}) {
  const data = await serverSideCmsFetch(
    `
    query PreviewPost($id: ID!, $idType: ContentNodeIdTypeEnum) {
      contentNode(id: $id, idType: $idType) {
        databaseId
        slug
        status
        contentTypeName
      }
    }`,
    {
      variables: { id, idType },
    },
    false,
    previewToken
  );

  return data.contentNode;
}
