import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Layout Options",
  items: [
    { name: "MinimalLayout" },
  ],
});

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/MinimalLayout")),
  {
    name: "MinimalLayout",
    image: assembleTablerIconUrl("columns-1"),
    inputs: [
      {
        type: "boolean",
        name: "enableMinimalLayout",
        defaultValue: true,
        helperText: "Enabling minimal layout will remove the navigation items from the header of this page."
      }
    ],
  }
);
