import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getSearchLinks() {
  const data = await serverSideCmsFetch(
    `query GetSearchLinks {
      acfOptionsSearch {
        search {
          searchLinks {
            title
            posts {
              ... on Article {
                id: articleId
                title
                slug
                contentType {
                  node {
                    labels {
                      singularName
                    }
                  }
                }
              }
              ... on Video {
                id: videoId
                title
                slug
                contentType {
                  node {
                    labels {
                      singularName
                    }
                  }
                }
              }
              ... on Workshop {
                id: workshopId
                title
                slug
                contentType {
                  node {
                    labels {
                      singularName
                    }
                  }
                }
              }
              ... on Script {
                id: scriptId
                title
                slug
                contentType {
                  node {
                    labels {
                      singularName
                    }
                  }
                }
              }
              ... on Event {
                id: eventId
                title
                slug
                contentType {
                  node {
                    labels {
                      singularName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`,
    {}
  );
  return data.acfOptionsSearch.search.searchLinks;
}
