const placeholderQuery = `
acfOptionsPlaceholders {
  optionsPlaceholderImages {
    articlePlaceholder {
      mediaItemUrl
    }
    videoPlaceholder {
      mediaItemUrl
    }
    podcastPlaceholder {
      mediaItemUrl
    }
    scriptPlaceholder {
      mediaItemUrl
    }
    workshopPlaceholder {
      mediaItemUrl
    }
    eventPlaceholder {
      mediaItemUrl
    }
    pagePlaceholder {
      mediaItemUrl
    }
    completionBadgeBackground {
      mediaItemUrl
    }
  }
}`;

export default placeholderQuery;
