export enum SectionVisibility {
  Show = "show",
  Hide = "hide",
  TimeGated = "time_gated",
}

export type TimeGatingConfiguration = {
  startDay: number;
  endDay: number;
};

type Experiments = {
  experiment: string;
  variant: string;
  visibility: SectionVisibility;
  timeGatingConfiguration: TimeGatingConfiguration | null;
}[];

type Control = {
  visibility: SectionVisibility;
  timeGatingConfiguration: TimeGatingConfiguration | null;
};

export type AbTestFields = {
  control: Control | null;
  experiments: Experiments | null;
};

const abTestQuery = `
  abtesting {
    control {
      visibility
      timeGatingConfiguration {
        startDay
        endDay
      }
    }
    experiments {
      experiment
      variant
      visibility
      timeGatingConfiguration {
        startDay
        endDay
      }
    }
  }
`;

export default abTestQuery;
