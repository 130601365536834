import { serverSideCmsFetch } from "../serverSideCmsApi";
import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";
import {
  MappedMenuItem,
  MappedMenuItemsDictionary,
  MenuData,
  MenuItemNode,
  MenuKey,
} from "@/types/menu";
import flatToTree from "util/flatToTree";

type GetAllMenuOptions = { isServer?: boolean };

type GetMenuResponse = {
  menus: MenuData;
};

const GET_ALL_MENUS_QUERY = `query GetAllMenus {
  menus {
    nodes {
      name
      menuItems(first: 100) {
        nodes {
          id
          parentId
          label
          path
          target
          customMenuFields {
            topic {
              topicCustomData {
                topicThumbnail {
                  mediaItemUrl
                }
                new
                subscriptionDateThreshold
              }
              databaseId
            }
          }
          title
        }
      }
    }
  }
}
`;

export async function getAllMenus(options?: GetAllMenuOptions) {
  const data = (
    !!options?.isServer
      ? await serverSideCmsFetch(GET_ALL_MENUS_QUERY)
      : await clientSideCmsFetch(GET_ALL_MENUS_QUERY)
  ) as GetMenuResponse;
  const menus: MappedMenuItemsDictionary = {};

  data?.menus?.nodes?.forEach((menu) => {
    if (!menu?.menuItems?.nodes) return;
    const tree = flatToTree<MenuItemNode>(menu.menuItems.nodes);
    const items: Array<MappedMenuItem & { children?: MappedMenuItem[] }> =
      tree.map((item) => {
        const parent = {
          label: item.label,
          url: item.path,
          target: item.target,
          mediaItemUrl:
            item.customMenuFields?.topic?.topicCustomData?.topicThumbnail
              ?.mediaItemUrl,
          new: item.customMenuFields?.topic?.topicCustomData?.new,
          topicId: item.customMenuFields?.topic?.databaseId,
          children: [] as MappedMenuItem[],
          title: item.title,
          subscriptionDateThreshold:
            item.customMenuFields?.topic?.topicCustomData
              ?.subscriptionDateThreshold,
        };
        item.children.length &&
          item.children.forEach((child) => {
            parent.children.push({
              label: child.label,
              url: child.path,
              target: child.target,
              mediaItemUrl:
                child.customMenuFields?.topic?.topicCustomData?.topicThumbnail
                  ?.mediaItemUrl,
              new: child.customMenuFields?.topic?.topicCustomData?.new,
              topicId: child.customMenuFields?.topic?.databaseId,
              title: child.title,
              subscriptionDateThreshold:
                child.customMenuFields?.topic?.topicCustomData
                  ?.subscriptionDateThreshold,
            });
          });

        return parent;
      });

    menus[menu.name as MenuKey] = items;
  });
  return menus;
}
