import * as Sentry from "@sentry/nextjs";
import axios from "axios";

/**
 * This method wraps an axios post to a serverless function that accesses our CMS.
 * Because this function can only be called from our app it provides a middle layer
 * of security to our gated CMS API.
 *
 * This function should be used for client side CMS requests. There is an optimized
 * server-side call named `serverSideCmsFetch`

 */
export async function clientSideCmsFetch(
  query: string,
  variables = {},
  previewToken?: string
) {
  const headers = { "Content-Type": "application/json" };
  const { data } = await axios.post(
    "/api/cms-request",
    {
      query,
      variables,
      previewToken,
    },
    { timeout: 10000, headers }
  );

  if (data.errors) {
    console.error(data.errors);
    Sentry.captureException(data.errors);
  }

  return data;
}
