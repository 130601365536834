import { useUI } from "@/context/UIContext";
import { MENU_KEY } from "@/lib/constants";
import { getAllMenus } from "@/lib/requests";
import {
  MappedMenuItemsDictionary,
  MappedMenuItemWithChildren,
  MenuKey,
} from "@/types/menu";
import { useQuery } from "@tanstack/react-query";

const STALE_TIME = 60 * 60 * 1000; // 60 minutes in milliseconds
const CACHE_TIME = 60 * 60 * 1000; // 60 minutes in milliseconds
const RETRY_DELAY = 10 * 1000; // 10 seconds in milliseconds

type UseMenuReturnType =
  | MappedMenuItemWithChildren[]
  | MappedMenuItemsDictionary;

export const useMenu = (menu?: MenuKey): UseMenuReturnType => {
  const { setDisplayStatusBanner } = useUI();
  const cachedMenu =
    typeof window !== "undefined"
      ? window.localStorage.getItem(MENU_KEY)
      : "undefined";

  try {
    const menuResponse = useQuery<Awaited<UseMenuReturnType>>(
      ["getAllMenus"],
      async () => {
        return await getAllMenus({ isServer: false });
      },
      {
        placeholderData: JSON.parse(
          cachedMenu !== "undefined" ? cachedMenu || "[]" : "[]"
        ),
        staleTime: STALE_TIME, // 60 minutes in milliseconds
        cacheTime: CACHE_TIME, // 60 minutes in milliseconds
        retryDelay: RETRY_DELAY, // 10 seconds in milliseconds
      }
    );

    if (menuResponse.data && typeof window !== "undefined") {
      window.localStorage.setItem(MENU_KEY, JSON.stringify(menuResponse.data));
    } else {
      return [];
    }

    return menu
      ? (menuResponse.data[menu] as MappedMenuItemWithChildren[])
      : (menuResponse.data as MappedMenuItemsDictionary);
  } catch (error) {
    setDisplayStatusBanner(true);
    return [];
  }
};
