import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getRelatedCarouselHeadings() {
  const data = await serverSideCmsFetch(
    `
    query relatedCarouselText {
        acfOptionsGlobalContent {
            optionsGlobalContent {
                relatedContentCarouselHeadings {
                heading
                subheading
                }
            }
        }
    }
    `,
    {}
  );

  return data.acfOptionsGlobalContent.optionsGlobalContent
    .relatedContentCarouselHeadings;
}
