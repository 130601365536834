import { useUser } from "@/context/UserContext";
import Cookies from "js-cookie";
import Script from "next/script";
import { useEffect } from "react";
import GTM from "react-gtm-module";

const FooterScripts = () => {
  const { user } = useUser();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      GTM.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        preview: process.env.NEXT_PUBLIC_GTM_ENV,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      });
    }
  }, []);

  function gtag() {
    window?.dataLayer?.push(arguments);
  }

  useEffect(() => {
    const client_id = Cookies.get("_ga")?.substring(6);
    Cookies.set("GA_client_id", client_id);
    if (window?.dataLayer) {
      const sessionIdPromise = new Promise((resolve) => {
        gtag("get", process.env.NEXT_PUBLIC_GOOGLE_ID, "session_id", resolve);
      });
      const sessionNumPromise = new Promise((resolve) => {
        gtag(
          "get",
          process.env.NEXT_PUBLIC_GOOGLE_ID,
          "session_number",
          resolve
        );
      });
      Promise.all([sessionIdPromise, sessionNumPromise]).then(function (
        session_data
      ) {
        Cookies.set("GA_session_id", session_data[0]);
        Cookies.set("GA_session_number", session_data[1]);
      });
    }
  }, []);

  useEffect(() => {
    if (!user) return;
  
    const isStripeUser = user.subscription?.source === "stripe";
    profitwell("start", isStripeUser ? { user_email: user.email } : {});

  }, [user]);

  return (
    <Script
      id="profitwell-js"
      data-pw-auth={process.env.NEXT_PUBLIC_PROFITWELL_KEY}
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
  a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
  s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
  })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`,
      }}
    ></Script>
  );
};

export default FooterScripts;
