import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder, withChildren } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Forms",
  items: [{ name: "BrazeSubmissionForm" }, { name: "BrazeSurveyQuestion" }],
});

const BrazeForm = withChildren(
  dynamic(() => import("./components/BuilderIO/Forms/BrazeSubmissionForm.js"))
);

Builder.registerComponent(BrazeForm, {
  name: "BrazeSubmissionForm",
  image: assembleTablerIconUrl("forms"),
  inputs: [
    {
      name: "tracking",
      type: "object",
      helperText:
        "If you do not have a tracking plan, please reach out to the GI Analytics team.",
      subFields: [
        {
          name: "form_type",
          type: "string",
          friendlyName: "Segment: Form Type",
          helperText:
            "Lead Form Completed: 'form_type' + Survey Question Answered: 'survey_type'",
        },
        {
          name: "location",
          type: "string",
          friendlyName: "Segment: Location",
          helperText: "Lead Form Completed: 'location'",
        },
        {
          name: "lead_form_source",
          type: "string",
          friendlyName: "Braze: Lead Form Source",
          helperText: "Braze custom attribute: 'lead_form_source'",
        },
        {
          name: "braze_attributes",
          type: "list",
          subFields: [
            {
              name: "attribute",
              type: "string",
              friendlyName: "Braze Custom Attribute",
              helperText: "The name of the custom attribute object in Braze (i.e. 'acquisition_topic')",
            },
            {
              name: "properties",
              type: "list",
              subFields: [
                {
                  name: "key",
                  type: "string",
                  helperText: "The name of the property (i.e. 'topic1')",
                },
                {
                  name: "value",
                  type: "boolean",
                },
              ],
            }
          ],
          friendlyName: "Braze: Custom Attributes",
          helperText:
            "Custom attributes to be sent to Braze. These attributes will be sent automatically when the form is submitted. If you want the user to select which attributes to send, use the 'BrazeSurveyQuestion' component.",
        },
      ],
    },
    {
      name: "formInstructions",
      type: "string",
      defaultValue: "Sign up for our newsletter",
      helperText: "This text will be displayed at the top of the form.",
    },
    {
      name: "helperText",
      type: "richText",
      helperText: "e.g. can be used to provide a link to members-only content.",
      defaultValue:
        "<p>Already a member? Access the <a href='/topic/all'>library</a></p>",
    },
    {
      name: "emailLabel",
      type: "string",
      defaultValue: "Email address",
      helperText:
        "Displayed above the input. (If blank, 'Email address' will  be read aloud to screen readers.)",
    },
    {
      name: "emailLabelStyle",
      type: "string",
      defaultValue: "",
      enum: [
        { label: "Default", value: "" },
        { label: "Title", value: "heading__sm" },
      ],
    },
    {
      name: "emailInputColor",
      type: "string",
      defaultValue: "!bg-light-gray",
      enum: [
        { label: "White", value: "!bg-white" },
        { label: "Gray", value: "!bg-light-gray" },
      ],
    },
    {
      name: "autopopulateUserEmail",
      type: "boolean",
      defaultValue: true,
      helperText:
        "If true, the user's email address will be automatically populated if they are logged in.",
    },
    {
      name: "emailInputPlaceholder",
      type: "string",
      defaultValue: "Email address",
      helperText:
        "This placeholder text will be displayed inside the input field.",
    },
    {
      name: "requiredText",
      type: "string",
      defaultValue: "(Required)",
    },
    {
      name: "position",
      type: "string",
      enum: [
        { label: "Above children", value: "above" },
        { label: "Below children", value: "below" },
      ],
      defaultValue: "below",
      helperText:
        "Show the email input above or below child elements. Applicable if children components have been added to the form.",
    },
    {
      name: "desktopButtonAlignment",
      friendlyName: "Button Alignment (Desktop Only)",
      type: "string",
      defaultValue: "left",
      enum: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
        { label: "Full Width", value: "full" },
      ],
    },
    {
      name: "buttonText",
      type: "string",
      defaultValue: "Sign me up",
    },
    {
      name: "submittedMessage",
      type: "string",
      defaultValue: "Thanks for subscribing!",
      helperText: "This message will be displayed after the form is submitted.",
    },
    {
      name: "redirectUrl",
      type: "url",
      helperText:
        "If provided, the user will be redirected to this URL after the form is submitted.",
    },
    {
      name: "redirectingMessage",
      type: "string",
      showIf: (options) => options.get("redirectUrl") !== "",
    },
    {
      name: "submissionErrorMessage",
      type: "string",
      defaultValue: "An error occurred. Please try again.",
    },
    {
      name: "localStorageKey",
      type: "string",
      helperText: "If provided, this local storage key will be set in the user's browser on submission."
    }
  ],
});

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Forms/BrazeSurveyQuestion.js")),
  {
    name: "BrazeSurveyQuestion",
    image: assembleTablerIconUrl("list-details"),
    inputs: [
      {
        name: "tracking",
        type: "object",
        helperText:
          "If you do not have a tracking plan, please reach out to the GI Analytics team.",
        subFields: [
          {
            name: "brazeCustomAttribute",
            type: "string",
            required: true,
            helperText:
              "Braze: custom attribute name. All checkboxes will be sent as an object under this attribute, with their value as true or false. (e.g. topic: { topic1: true, topic2: false} , where 'topic' is the custom attribute name, and true or false are whether the user selected the value.)",
          },
        ],
      },
      {
        name: "surveyQuestionTitle",
        type: "string",
        required: true,
        defaultValue: "Title",
        helperText:
          "Sent to Segment under the 'Survey Question Answered' event. This will also be set as the legend of this form section (important for accessibility).",
      },
      {
        name: "subtext",
        type: "string",
        defaultValue: "Subtext",
      },
      {
        name: "required",
        type: "boolean",
        defaultValue: false,
        helperText: "Require at least one checkbox to be checked",
      },
      {
        name: "useSubtextAsValidationMessage",
        type: "boolean",
        defaultValue: true,
        helperText:
          "Use the subtext as the validation message when no checkboxes are checked. This text will turn red when invalid.",
        showIf: (options) => options.get("required") === true,
      },
      {
        name: "validationMessage",
        type: "string",
        defaultValue: "Please select at least one option",
        showIf: (options) =>
          options.get("required") === true &&
          options.get("useSubtextAsValidationMessage") === false,
      },
      {
        name: "checkboxes",
        type: "list",
        subFields: [
          {
            name: "label",
            type: "string",
            defaultValue: "Label",
          },
          {
            name: "value",
            type: "string",
            defaultValue: "Value",
          },
          {
            name: "doNotTrack",
            type: "boolean",
            defaultValue: false,
            helperText:
              "If true, this checkbox value will not be tracked in Braze or Segment when the form is submitted.",
          },
          {
            name: "doNotShow",
            type: "boolean",
            defaultValue: false,
            helperText:
              "If true, this checkbox will not be displayed, but its value will always be true. Useful for automatically tracking a value without user input.",
          },
        ],
        defaultValue: [
          { label: "Option 1", value: "option1" },
          { label: "Option 2", value: "option2" },
          { label: "Option 3", value: "option3" },
          { label: "Option 4", value: "option4" },
        ],
      },
      {
        name: "desktopSingleRow",
        type: "boolean",
        defaultValue: false,
        helperText: "Display checkboxes in a single row on desktop",
      },
      {
        name: "desktopColumns",
        type: "number",
        defaultValue: 2,
        min: 0,
        max: 4,
        helperText: "Number of columns on desktop",
        showIf: (options) =>
          options.get("desktopSingleRow") === false ||
          options.get("desktopSingleRow") === undefined,
      },
      {
        name: "mobileColumns",
        type: "number",
        defaultValue: 1,
        min: 0,
        max: 4,
        helperText: "Number of columns on mobile",
      },
    ],
    defaultStyles: {
      marginBottom: "25px",
    },
  }
);
