import ActionButton from "@/components/ActionButton";
import cn from "classnames";
import FocusTrap from "focus-trap-react";
import Link from "next/link";
import { isValidElement, forwardRef } from "react";
import { useEffect, useState } from "react";

const Dialog = ({ modal, close, children }, ref) => {
  const modalContentAsChild = isValidElement(modal.content);
  const [focusContainer, setFocusContainer] = useState([]);

  useEffect(() => {
    const onetrustBanner = document.getElementById("onetrust-banner-sdk");
    const onetrustPopup = document.getElementById("onetrust-pc-sdk");

    if (ref.current && (modal.content || children)) {
      if (onetrustBanner && !focusContainer.includes(onetrustBanner)) {
        setFocusContainer((prev) => [...prev, onetrustBanner]);
      }

      if (onetrustPopup && !focusContainer.includes(onetrustPopup)) {
        setFocusContainer((prev) => [...prev, onetrustPopup]);
      }

      if (!focusContainer.includes(ref.current)) {
        setFocusContainer((prev) => [...prev, ref.current]);
      }
    } else if (onetrustBanner) {
      // focus can be set (not trapped) for onetrust banner
      onetrustBanner.focus();
    }
  }, [ref, focusContainer, modal, children]);

  if (!modal.content && !children) return null;

  return (
    <FocusTrap containerElements={focusContainer}>
      <dialog
        className={cn("block", {
          "w-screen p-0 overflow-y-auto sm:rounded-2xl sm:w-fit h-fit md-max:bg-light-gray":
            !modal.position,
          "fixed bottom-0 w-full p-0": modal.position === "bottom",
        })}
        ref={ref}
      >
        {modal.dismissable ? (
          <ActionButton
            icon="close"
            className="absolute z-30 bg-transparent top-2 right-2"
            onClick={close}
          />
        ) : (
          // FocusTrap requires a tabbable element to be able to focus the dialog
          <Link className="sr-only" href="/">
            Visit Good Inside Home
          </Link>
        )}
        {modal.content && modalContentAsChild && <>{modal.content}</>}
        {children && children}
      </dialog>
    </FocusTrap>
  );
};

export default forwardRef(Dialog);
