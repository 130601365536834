import {
  COMMUNITY_API_URL,
  COMMUNITY_ID,
  COMMUNITY_API_KEY,
} from "@/lib/constants";

async function fetchCirclePosts(url, options, topic) {
  try {
    const res = await fetch(url, options);

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    const data = await res.json();
    return { [topic]: data };
  } catch (error) {
    console.error(`Error for topic "${topic}":`, error);
    return { [topic]: { error: error.message } };
  }
}

export async function getPersonalizedCirclePosts(
  personalizedCircleData,
  userTopicList,
  numPosts = 8,
  maxRetries = 3
) {
  const promises = [];

  async function retryRequest(url, options, topic, retries) {
    const result = await fetchCirclePosts(url, options, topic);

    if (result[topic].error && retries < maxRetries) {
      console.log(
        `Retrying request for topic "${topic}" (Retry ${
          retries + 1
        }/${maxRetries})`
      );
      return retryRequest(url, options, topic, retries + 1);
    }

    return result;
  }

  userTopicList.push({
    name: "Default",
    displayName: "Default",
  });

  for (const topic of userTopicList) {
    const matchedTopic = personalizedCircleData.find((item) => {
      return item.onboardingTopic === topic.displayName || item.onboardingTopic === topic.name;
    });

    if (matchedTopic && matchedTopic.spaceId) {
      const spaceId = matchedTopic.spaceId;
      const searchTerm = matchedTopic.searchTerm;

      const url = new URL(`${COMMUNITY_API_URL}/posts`);
      url.searchParams.append("community_id", COMMUNITY_ID);
      url.searchParams.append("space_id", spaceId);
      url.searchParams.append("per_page", numPosts);
      
      if (!!searchTerm) {
        url.searchParams.append("search_text", searchTerm);
      }

      const headers = new Headers();
      headers.append("Authorization", `Token ${COMMUNITY_API_KEY}`);

      const options = {
        method: "GET",
        headers,
        redirect: "follow",
      };

      const promise = retryRequest(url, options, topic.name, 0);
      promises.push(promise);
    }
  }

  const circlePostObjectArray = await Promise.all(promises);
  const circlePostObject = Object.assign({}, ...circlePostObjectArray);

  return circlePostObject;
}
