import { MobileMenuProps, useMobileMenuLogic } from "./useMobileMenuLogic";
import { MobileMenuItem } from "@/components/Menu/MobileMenu/MobileMenuItem";
import Slideover from "@/components/Slideover";

export const MobileMenu = (props: MobileMenuProps) => {
  const {
    items,
    showMobileMenu,
    showSubMenu,
    children,
    handleOpen,
    handleClose,
    closeMainMenu,
  } = useMobileMenuLogic(props);
  return (
    <Slideover
      show={showMobileMenu}
      setShow={closeMainMenu}
      closeButton={undefined}
      headerLabel={undefined}
    >
      {items?.map((item, index) => (
        <MobileMenuItem
          key={`${index}-${item.label}`}
          index={index}
          item={item}
          showSubMenu={showSubMenu}
          handleClose={handleClose}
          handleOpen={handleOpen}
          closeMainMenu={closeMainMenu}
        />
      ))}
      {children}
    </Slideover>
  );
};
