import ArticleTypeFields from "./articleTypeFields";
import PodcastTypeFields from "./podcastTypeFields";
import ScriptTypeFields from "./scriptTypeFields";
import VideoTypeFields from "./videoTypeFields";
import WorkshopTypeFields from "./workshopTypeFields";

export {
  ArticleTypeFields,
  VideoTypeFields,
  ScriptTypeFields,
  PodcastTypeFields,
  WorkshopTypeFields,
};
