import Banner from "@/components/Banner";
import Link from "next/link";

const StatusBanner = () => {
  return <>
    <Banner bgColor="bg-medium-blue">
      <div className="container flex items-center justify-center h-full mx-auto">
        <div className="">
          <span className="inline-block w-2 h-2 mr-2 rounded-full bg-yellow animate-pulse"></span>
          <span className="text__b3">
            Good Inside is experiencing connectivity issues with our content
            host.
          </span>
          <Link
            href="https://support.goodinside.com"
            passHref
            className="ml-1 font-medium underline text__b3">
            
              See our support site for updates.
            
          </Link>
        </div>
      </div>
    </Banner>
  </>;
};

export default StatusBanner;
