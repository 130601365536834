import Button from "@/components/Button";
import Icon from "@/components/Icon";

export interface MenuButtonProps {
  iconName?: string;
  buttonHandler: VoidFunction;
}

export const MenuButton = ({ buttonHandler, iconName }) => (
  <Button
    variant="base"
    size="large"
    onClick={buttonHandler}
    className="!outline-none text-current w-8 h-8 !px-0 !py-0"
  >
    <Icon name={iconName} />
  </Button>
);
