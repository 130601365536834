import completionBadgeQuery from "./graphQLqueries/completionBadgeQuery";

const WorkshopTypeFields = `
  fragment WorkshopTypeFields on Workshop {
    title
    slug
    date
    excerpt
    workshopPageContent {
      eventEndTime
      eventStartTime
      rsvpLink
      price
      ${completionBadgeQuery}
    }
    workshopGatedContent {
      downloadableContent {
        downloadableFile {
          id
        }
      }
      modules {
        lessons {
          fieldGroupName
        }
        fieldGroupName
      }
    }
    contentDuration {
      timeToConsume
    }
    featuredImage {
      node {
        altText
        mediaItemUrl
        mediaDetails {
          height
          width
        }
      }
    }
    topics {
      nodes {
        topicId
        name
      }
    }
    contentType {
      node {
        labels {
          singularName
        }
      }
    }
    workshopId
    workshopAltTitle {
      altTitle
    }
  }
`;
export default WorkshopTypeFields;
