import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";

export async function getSocialLinks() {
  const data = await clientSideCmsFetch(
    `query GetSocialLinks {
      acfOptions3rdPartyLinks {
        socialChannels {
          socialLinks {
            instagram
            facebook
            twitter
            tiktok
            linkedin
            youtube
            pinterest
          }
        }
      }
    }`,
    {}
  );
  return data.acfOptions3rdPartyLinks.socialChannels.socialLinks;
}
