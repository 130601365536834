import Button from "@/components/Button";
import Image from "@/components/Fallback/OptimizedImageWithFallback";
import Layout from "@/components/Layout";
import SplitLayout from "@/components/SplitLayout";
import useHomeUrl from "@/hooks/useHomeUrl";

const LayoutError = ({ heading, message }) => {
  const url = useHomeUrl();

  return (
    <Layout className="" mainClassName="flex justify-center">
      <SplitLayout
        leftClasses="bg-blue"
        leftContent={
          <figure className="w-48 transition sm:w-72 2xl:mr-32">
            <Image
              src="/misc/lost-illustration.svg"
              alt=""
              layout="responsive"
              width={287}
              height={279}
            />
          </figure>
        }
        rightContent={
          <>
            <h1 className="mt-0 heading__lg">{heading}</h1>
            <p className="max-w-xs font-mono text-sm leading-relaxed">
              {message}
            </p>

            <Button href={url} className="mt-4">
              Go to homepage
            </Button>
          </>
        }
        rightClasses="py-15 pb-18 sm:py-8 md:mb-8 lg:mb-12"
      />
    </Layout>
  );
};

export default LayoutError;
