import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPlans() {
  const data = await serverSideCmsFetch(
    `query getPlans {
        plans {
          nodes {
            title
            plan_content {
              price
              unit
              description
              disablePurchaseButton
            }
          }
        }
      }
      `,
    {}
  );
  return data.plans.nodes;
}
