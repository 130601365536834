import BuilderIORegistration from "./Registration/BuilderIORegistration";
import Dialog from "@/components/Dialog";
import SearchModal from "@/components/SearchModal";
import TestimonialModal from "@/components/TestimonialModal";
import VideoModal from "@/components/VideoModal";
import { useUI } from "@/context/UIContext";
import useMediaQuery, { desktopQuery } from "@/hooks/useMediaQuery";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import {
  HEADER_HEIGHT,
  MOBILE_BANNER_HEIGHT,
  DESKTOP_BANNER_HEIGHT,
} from "@/lib/constants";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import cn from "classnames";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";

const Modal = ({ placeholders, searchLinks }) => {
  const { dispatchModal, modal, displayStatusBanner, displayAlertBanner } =
    useUI();
  const isDesktop = useMediaQuery(desktopQuery);
  const router = useRouter();
  const dialogRef = useRef(null);

  const hasBanner = displayAlertBanner || displayStatusBanner;
  const bannerMultiplier = isDesktop
    ? DESKTOP_BANNER_HEIGHT
    : MOBILE_BANNER_HEIGHT;
  const bannerHeight = (hasBanner ? 1 : 0) * bannerMultiplier;

  const calculatedHeaderHeight = HEADER_HEIGHT + bannerHeight;

  useOnClickOutside(
    dialogRef,
    useCallback(
      (e) => {
        close();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [modal.dismissable]
    )
  );

  const close = useCallback(
    (forceClose = false) => {
      if (!modal.dismissable && !forceClose) return;

      dispatchModal({ type: "CLEAR" });
      enableBodyScroll(dialogRef.current);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatchModal, modal.dismissable]
  );

  const handleSearchClose = () => {
    if (modal.type === "search") {
      close();
    }
  };

  useEffect(() => {
    if (modal.open) {
      // Prevent scroll on iOS devices by freezing requestAnimation
      // https://github.com/willmcpo/body-scroll-lock/issues/237#issuecomment-1062527105

      // Cache requestAnimationFrame
      const storedRequestAnimationFrame = window.requestAnimationFrame;

      // Set to any value, I guess?
      window.requestAnimationFrame = () => null;
      disableBodyScroll(dialogRef.current);
      // Restore value
      window.requestAnimationFrame = storedRequestAnimationFrame;
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [modal.open, close]);

  useEffect(() => {
    const closeModal = () => {
      dispatchModal({ type: "CLEAR" });
    };

    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        closeModal();
      }
      return true;
    });
  }, [dispatchModal, router]);

  const getStyles = () => {
    if (modal.type === "search") {
      return {
        top: isDesktop ? `${calculatedHeaderHeight}px` : 0,
        height: isDesktop
          ? `calc(100vh - ${calculatedHeaderHeight}px)`
          : "100vh",
      };
    } else return null;
  };

  return (
    <div
      className={cn(
        "fixed inset-0 z-[100000] flex full-height-with-dvh w-full items-center justify-center transition-opacity duration-300 bg-black/60",
        {
          "invisible opacity-0": !modal.open,
          "sm:!z-20 !items-start": modal.type === "search",
        }
      )}
      onClick={() => handleSearchClose()}
      style={getStyles()}
    >
      {(() => {
        switch (modal.type) {
          case "referrals":
          case "paywall":
          case "registration":
            return (
              <Dialog modal={modal} ref={dialogRef} close={close}>
                <BuilderIORegistration type={modal.type} />
              </Dialog>
            );
          case "video":
            return <VideoModal modal={modal} close={close} ref={dialogRef} />;
          case "testimonial":
            return (
              <TestimonialModal modal={modal} close={close} ref={dialogRef} />
            );
          case "search":
            return (
              <SearchModal
                placeholders={placeholders}
                searchLinks={searchLinks}
                close={close}
              />
            );
          default:
            return <Dialog modal={modal} close={close} ref={dialogRef} />;
        }
      })()}
    </div>
  );
};

export default Modal;
