import {
  placeholderQuery,
  searchLinksQuery,
  acfGlobalOptionsQuery,
} from "@/lib/fragments/graphQLqueries";
import { referralsQuery } from "@/lib/fragments/graphQLqueries/referralsQuery";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getArticle({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query ArticleQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID, asPreview: ${preview}) {
        ... on Article {
          databaseId
          slug
          title
          date
          excerpt
          content
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          contentDuration {
            timeToConsume
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
          topics {
            nodes {
              name
              slug
              topicId
              topicCustomData {
                circleSpaceUrl
              }
            }
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          ${acfGlobalOptionsQuery}
          ${referralsQuery}
        }
      }
      ${placeholderQuery}
      ${searchLinksQuery}
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return data;
}
