import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getAllPostSlugsOfType({ types = [] }) {
  const data = await serverSideCmsFetch(
    `
    query PostSlugQuery($types: [ContentTypeEnum!]) {
      contentNodes(first: 1000, where: {contentTypes: $types}) {
        edges {
          node {
            slug
            id: databaseId
          }
        }
      }
    }

  `,
    {
      variables: {
        types: types,
      },
    }
  );

  return data?.contentNodes.edges;
}
