import axios from "axios";
import { useState } from "react";

export function useBrazeMergeAliases() {
  const [isSending, setIsSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleMergeBrazeAliases = async (email: string, id: string) => {
    if (!email || !id) return false;

    const requestBody = {
      email: email,
      id,
    };

    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };


    if (!!email && !!id) {
      setIsSending(true);
      try {
        await axios.post(
          "/api/braze-merge-aliases",
          requestBody,
          requestConfig
        );
        setIsSending(false);
        setIsSubmitted(true);
        return true;
      } catch (error) {
        setIsSending(false);
        setIsSubmitted(false);
      }
    }
    return false;
  };

  return {
    isSending,
    isSubmitted,
    handleMergeBrazeAliases,
  };
}
