import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getSmsForm() {
  const data = await serverSideCmsFetch(
    `query getSmsForm {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          weeklyFlexOptInForm {
            icon {
              mediaItemUrl
              altText
            }
            aboveFormContent
            fieldGroupName
            belowFormContent
            successText
            comingSoonText
            ctaText
          }
        }
      }
    }
      `,
    {}
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent.weeklyFlexOptInForm;
}
