import z from "zod";

const referralsQuery = `
referralsContent {
  memberNudgeContent {
    title
    content
  }
  shareeNudgeContent {
    title
    content
  }
  referralCalloutContent {
    headline
    description
    image {
      altText
      mediaItemUrl
      mediaDetails {
        width
        height
      }
    }
    ctaText
    backgroundColor
    boxShadowToggle
  }
}
`;

const referralsSchema = z.object({
  referralsContent: z.object({
    memberNudgeContent: z.object({
      title: z.string(),
      content: z.string(),
    }),
    shareeNudgeContent: z.object({
      title: z.string(),
      content: z.string(),
    }),
    referralCalloutContent: z.object({
      headline: z.string(),
      description: z.string(),
      image: z.object({
        altText: z.string(),
        mediaItemUrl: z.string(),
        mediaDetails: z.object({
          width: z.number(),
          height: z.number(),
        }),
      }),
      backgroundColor: z.string(),
      boxShadowToggle: z.boolean(),
    }),
  }),
});

export { referralsQuery, referralsSchema };
