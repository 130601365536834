import Icon from "@/components/Icon";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import cn from "classnames";

const SearchIcon = ({
  btnHandler = () => {},
  className = "",
}: {
  btnHandler?: () => void;
  className?: string;
}) => {
  const { user } = useUser();
  const { modal, dispatchModal } = useUI();

  return (
    <>
      {user?.subscription_active && (
        <button
          className={cn(
            "relative lg:scale-75 lg:translate-y-1",
            "before:absolute before:top-[52px] before:left-0 before:w-full before:h-[5px] before:bg-yellow before:opacity-0 before:transition-opacity hover:before:opacity-100 hover:before:transition-opacity",
            {
              "before:opacity-100": modal.open && modal.type === "search",
            },
            className
          )}
          onClick={() => {
            dispatchModal({
              type: "SET_TYPE",
              value: "search",
            });
            dispatchModal({
              type: "SET_OPEN",
              value: !modal.open,
            });
            btnHandler();
          }}
        >
          <Icon name="search" />
        </button>
      )}
    </>
  );
};

export default SearchIcon;
