import { ReactNode } from "react";

export type ButtonProps = {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  href?: string | boolean | null;
  target?: string;
  fullWidth?: boolean;
  spaceBetween?: boolean;
  prefixIcon?: string | null;
  role?: string;
  suffixIcon?: string | null;
  iconClasses?: string | boolean;
  largeIcon?: boolean;
  size?: keyof typeof btnSizes | "";
  title?: string;
  variant?: keyof typeof btnVariants;
  tabIndex?: number;
  dataId?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
};

export const btnVariants = {
  base: "btn",
  primary: "btn btn-primary",
  secondary: "btn btn-secondary",
  hollow: "btn btn-secondary-inverse",
  text: "btn btn-text",
  clear: "btn btn-clear",
  transparent: "btn btn-transparent",
  dark: "btn btn-dark",
};

export const btnSizes = {
  large: "px-8 py-4",
  small: "px-5 py-3.5 text-xs font-medium",
  wide: "px-7.5 py-2.5",
};
