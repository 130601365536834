import { BASE_URL } from "@/lib/constants";

const isValidRelativeURL = (url: string): boolean => {
  if (!url) return false;
  try {
    new URL(url, BASE_URL);
    return true;
  } catch (error) {
    return false;
  }
};

export default isValidRelativeURL;
