import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Media",
  items: [{ name: "BrightcoveVideo" }],
});

Builder.registerComponent(
  dynamic(() => import("@/components/VideoPlayer/SingleVideo.js")),
  {
    name: "BrightcoveVideo",
    image: assembleTablerIconUrl("player-play-filled"),
    inputs: [
      {
        name: "videoId",
        type: "string",
        required: true,
        defaultValue: "default",
      },
      {
        name: "playOnIntersect",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "playImmediately",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "audioOnly",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "loop",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "allowPiP",
        friendlyName: "Allow Picture in Picture",
        type: "boolean",
        defaultValue: false,
      },
    ],
  }
);
