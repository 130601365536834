import NewsletterForm from "./NewsletterForm";
import { useBrazeFormSubmit } from "@/hooks/useBrazeFormSubmit";
import { useEffect } from "react";

export default function Newsletter() {
  const { isSubmitted, isSending, handleSubmit, setLeadFormTracking } =
    useBrazeFormSubmit();

  useEffect(() => {
    setLeadFormTracking({
      form_type: "newsletter",
      location: "newsletter",
    });
  }, [setLeadFormTracking]);

  return (
    <section className="relative z-10 px-4 pt-16 pb-32 overflow-hidden text-center bg-dark-green md:pt-11">
      <div className="mx-auto max-w-[500px]">
        <span className="absolute left-1/2 bottom-0 z-[-1] h-[150vw] w-[150vw] -translate-x-1/2 rounded-full rounded-b-full bg-medium-green sm:h-[140vw] sm:w-[140vw]"></span>
        <h2 className="heading__lg">
          Come for parenting guidance. Stay for something much bigger.
        </h2>
        <h3 className="px-3 pt-6 m-0 text__eb sm:px-5 sm:pt-7">
          Subscribe to our newsletter to get simple strategies sent straight to
          your inbox.
        </h3>
        {isSubmitted ? (
          <div className="relative mt-10 min-h-[58px] text-center md:mt-8">
            <p className="absolute left-0 right-0 heading__xl md:text-2xl">
              Thank you!
            </p>
          </div>
        ) : (
          <NewsletterForm
            handleSubmit={(email) => {
              handleSubmit(email);
            }}
            isSending={isSending}
          />
        )}
      </div>
    </section>
  );
}
