import cn from "classnames";
import Button from "@/components/Button";

const Badge = ({ children, className, ...props }) => {
  return (
    <Button
      className={cn(
        "rounded-2xl bg-yellow",
        "p-1.5 py-0.5",
        "text-2xs font-medium leading-normal tracking-widest",
        className
      )}
      size=""
      variant="base"
      {...props}
    >
      {children}
    </Button>
  );
};

export default Badge;
