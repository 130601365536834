import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getWorkshopTestimonials({ id, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `query getWorkshopTestimonials($id: ID!) {
        testimonials(where: {whichWorkshop: $id}, first:100){
          nodes {
            title
            testimonial_content {
              author
              content
            }
          }
        }
      }
      `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return data.testimonials.nodes;
}
