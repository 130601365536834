import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";
import {
  ArticleTypeFields,
  PodcastTypeFields,
  ScriptTypeFields,
  VideoTypeFields,
  WorkshopTypeFields,
} from "@/lib/fragments";

/**
 * Query for getting all posts by Id
 */

export async function getPostsById({ ids, numPost }) {
  const numberPost = numPost ? numPost : 12;
  const data = await clientSideCmsFetch(
    `
    query PostsById($ids: [ID!]) {
      contentNodes(where: {in: $ids}, first: ${numberPost}) {
        edges {
          node {
            ... on Article {
              id: articleId
              ...ArticleTypeFields
            }
            ... on Video {
              id: videoId
              ...VideoTypeFields
            }
            ... on Podcast {
              id: podcastId
              ...PodcastTypeFields
            }
            ... on Script {
              id: scriptId
              ...ScriptTypeFields
            }
            ... on Workshop {
              id: workshopId
              ...WorkshopTypeFields
            }
          }
        }
      }
    }
    ${ArticleTypeFields}
    ${ScriptTypeFields}
    ${VideoTypeFields}
    ${PodcastTypeFields}
    ${WorkshopTypeFields}
  `,
    { ids: ids }
  );

  return data?.contentNodes.edges;
}
