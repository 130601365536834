import _ from "./style.module.css";
import Spinner from "@/components/Spinner";
import { useBrazeFormSubmit } from "@/hooks/useBrazeFormSubmit";
import cn from "classnames";
import { useState } from "react";

export default function NewsletterForm({
  isSending,
  hero,
  handleSubmit,
  className,
}) {
  const [email, setEmail] = useState("");
  const { validationMessage } = useBrazeFormSubmit();

  return (
    <form
      className={cn(
        "relative mt-10 md:mt-8",
        {
          [_.braze]: !hero,
          "flex flex-col sm:flex-row": hero,
        },
        className
      )}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(email);
      }}
    >
      {isSending && (
        <div
          className={cn(
            "absolute -left-2 -right-2 -top-2 -bottom-2 z-10 flex items-center justify-center",
            { "bg-medium-green": !hero, "bg-white": hero }
          )}
        >
          <Spinner />
        </div>
      )}

      <input
        type="text"
        className={cn(
          "tester transition duration-200 ease-in-out !placeholder-black",
          {
            "border-2 !border-black": hero,
            "!bg-medium-green !border-black !text-black": !hero,
          }
        )}
        placeholder="Enter your email"
        aria-label="Enter your email"
        aria-invalid="false"
        autoComplete="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />

      <input
        type="submit"
        aria-label="Submit email"
        className={cn({
          "btn btn-primary mt-4 mb-6 flex justify-center sm:my-0 sm:ml-4 p-4":
            hero,
        })}
        value={hero ? "subscribe" : "submit"}
      />

      {validationMessage && (
        <div className="absolute left-0 w-full text-xs -bottom-6">
          {validationMessage}
        </div>
      )}
    </form>
  );
}
