import FormError from "./FormError";
import OnboardingButton from "@/components//OnboardingButton";
import Button from "@/components/Button";
import GoogleButton from "@/components/GoogleButton";
import OrDivider from "@/components/OrDivider";
import { useAuth } from "@/context/AuthContext";
import { USER_AUTH_URL } from "@/lib/constants";
import cn from "classnames";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";

const LoginForm = ({
  variant = "base",
  title = "Welcome Back!",
  subtitle,
  googleText = "Log in with Google",
  buttonText = "Login",
  purchaseUrl,
  postId,
  isRegistering,
  nextUrl,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [disableButton, setDisableButton] = useState(false);
  const { login } = useAuth();
  const router = useRouter();

  const queryParams = {
    purchaseUrl,
    isRegistering: false,
    postId,
  };

  const submitLoginForm = async ({ email, password }) => {
    setDisableButton(true);
    try {
      await login({
        email,
        password,
        purchaseUrl,
        postId,
        isRegistering,
        nextUrl,
      });
    } catch (error) {
      setDisableButton(false);

      setError("form", {
        message: "Login failed. Check your username and password.",
      });
    }
  };

  return (
    <>
      <div
        className={cn(
          "text-center flex flex-col",
          {
            "gap-5 items-center": variant === "base",
          },
          {
            "gap-8": variant === "abandoned-cart",
          }
        )}
      >
        <div className="flex flex-col gap-2 md:gap-3">
          {title && (
            <h2
              className={cn(
                { heading__lg: variant === "base" },
                {
                  "font-black text-xl md:text-lgr leading-none":
                    variant === "abandoned-cart",
                }
              )}
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <p className={cn({ "text-md": variant === "abandoned-cart" })}>
              {subtitle}
            </p>
          )}
        </div>
        <GoogleButton
          queryParams={queryParams}
          className={cn({
            "border-1 border-gray !rounded-full !font-bold text-base !text-black shadow-none w-full":
              variant === "abandoned-cart",
          })}
        >
          {googleText}
        </GoogleButton>
      </div>

      <OrDivider
        bgColor={variant === "abandoned-cart" ? "bg-white" : "bg-light-gray"}
        margin={variant === "abandoned-cart" ? "my-5" : "my-4"}
      />

      <div className="max-w-xl mx-auto">
        <form
          onSubmit={handleSubmit(submitLoginForm)}
          onChange={() => {
            clearErrors("form");
          }}
          className="flex justify-center form-group"
        >
          <label
            htmlFor="email"
            className={cn("mb-2", { "sr-only": variant === "abandoned-cart" })}
          >
            Email Address
          </label>
          <input
            id="email"
            placeholder="Email Address"
            autoComplete="email"
            autoCapitalize="off"
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", {
              required: "Email is required",
              minLength: { value: 3, message: "Email is too short" },
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Enter a valid email",
              },
            })}
            type="email"
            className={cn({ alt: variant === "abandoned-cart" })}
          />
          {errors.email && <FormError error={errors.email} />}

          <label
            htmlFor="password"
            className={cn("pt-4 mb-2", {
              "sr-only": variant === "abandoned-cart",
            })}
          >
            Password
          </label>
          <input
            placeholder={variant === "abandoned-cart" ? "Password" : "********"}
            autoComplete="current-password"
            id="password"
            className={cn("font-mono placeholder:font-sans", {
              "alt mt-4 md:mt-5": variant === "abandoned-cart",
            })}
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password needs to be more than 6 characters",
              },
            })}
            type="password"
          />

          <button
            className={cn(
              "ml-1 text-xs leading-3.5 underline underline-offset-1",
              { "mt-2.5": variant === "abandoned-cart" }
            )}
            type="button"
            onClick={() => {
              router.push(USER_AUTH_URL + "/forgot-password");
            }}
          >
            Forgot your password?
          </button>

          {errors.password && <FormError error={errors.password} />}

          {errors.form && <FormError isCentered error={errors.form} />}

          <Button
            type="submit"
            disabled={disableButton}
            className={cn("justify-center w-full", {
              "mt-9 md:mt-8": variant === "abandoned-cart",
              "mt-5": variant === "base",
            })}
          >
            {buttonText}
          </Button>
        </form>
        <div className="mt-4 text-sm text-center leading">
          {`Don't have an account?`}
          <OnboardingButton
            variant="base"
            className="underline underline-offset-1 capitalize font-sans ml-1.5 !p-0 tracking-normal font-bold"
          >
            Get Started
          </OnboardingButton>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
