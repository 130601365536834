import Button from "@/components/Button";
import Container from "@/components/Containers/Container";
import Icon from "@/components/Icon";
import Newsletter from "@/components/Newsletter";
import { useUser } from "@/context/UserContext";
import useHomeUrl from "@/hooks/useHomeUrl";
import { useMenu } from "@/hooks/useMenu";
import { useSocialLinks } from "@/hooks/useSocialLinks";
import { useTrackedLink } from "@/hooks/useTrackedLink";
import Link from "next/link";
import { Fragment, useState, useEffect } from "react";
import { ONE_TRUST_KEY } from "@/lib/constants";

export default function Footer() {
  const [hasMounted, setHasMounted] = useState(false);
  const oneTrustKey = ONE_TRUST_KEY;

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const { user } = useUser();
  const homeUrl = useHomeUrl();

  const items = useMenu(
    user?.subscription_active ? "Footer Menu" : "Guest Footer"
  );
  const socialLinks = useSocialLinks();
  const { handleTrackedLink } = useTrackedLink();

  const handleOneTrustModal = () => {
    if (!window?.OneTrust) return;
    window.OneTrust.ToggleInfoDisplay();
  }

  return (
    hasMounted && (
      <>
        <footer className="text-black bg-dark-green relative" role="contentinfo">
          {!user && <Newsletter className="pb-12" />}
          <Container>
            <div className="flex flex-row flex-wrap pt-8 pb-8 lg:pt-18">
              <div className="w-1/2 sm:w-full md:w-5/12 lg:w-6/12">
                <Link
                  href={homeUrl}
                  title="Home"
                  className="inline-block"
                  passHref
                >
                  <Icon name="giLogoLarge" />
                </Link>
              </div>
              <div className="w-full pt-12 sm:w-2/3 md:w-5/12 md:pt-0 lg:w-6/12">
                {!!items && items.length > 0 && (
                  <ul className="p-0 m-0 list-none xl:gap-24 columns-2 md:-mt-2">
                    {items.map((item, k) => (
                      <li key={`${k}-${item.label}`} className="pb-3 md:pb-0">
                        <Button
                          variant=""
                          size=""
                          href={item.url}
                          target={item.target}
                          onClick={(e) =>
                            handleTrackedLink(e, item.url, item.target)
                          }
                          className="py-2 transition-colors text__cta md:hover:text-white"
                        >
                          {item.label}
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
                {!!socialLinks && Object.keys(socialLinks).length > 0 && (
                  <div className="flex items-center justify-start pt-10 md:pt-8 md:items-start lg:justify-start">
                    <ul className="flex flex-wrap grid-flow-col p-0 m-0 list-none gap-7 md:items-center">
                      {Object.entries(socialLinks).map(([key, value], i) => (
                        <Fragment key={`${key}-${i}`}>
                          {value && (
                            <li>
                              <a
                                href={value}
                                onClick={(e) =>
                                  handleTrackedLink(e, value, "_blank")
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="w-6 text-2xl transition md:hover:text-white"
                              >
                                <Icon name={key} />
                              </a>
                            </li>
                          )}
                        </Fragment>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="pt-10 md:pt-8">
                  <div className="flex flex-col xl:flex-row mb-[0.675rem] gap-[0.625rem] text-left">
                    <Link className="font-bold underline text__b2" href="/accessibility" passHref>
                      Accessibility Statement
                    </Link>
                    {oneTrustKey && (
                      <>
                        <span className="hidden text__b2 xl:block">|</span>
                        <Link className="font-bold underline text__b2" href="/privacy-policy#requests-to-opt-out" passHref>
                          Do Not Sell Or Share My Personal Information
                        </Link>
                      </>
                    )}
                  </div>
                  <p className="text__b2">
                    &copy; {new Date().getFullYear()} Good Inside. All Rights
                    Reserved.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </footer>
      </>
    )
  );
}
