import Results from "./Results";
import Search from "./Search";
import Container from "@/components/Containers/Container";
import Icon from "@/components/Icon";
import { useUI } from "@/context/UIContext";
import Middleware from "@/lib/middleware/search-insights";
import algoliasearch from "algoliasearch/lite";
import { useEffect, useRef } from "react";
import { InstantSearch, Configure } from "react-instantsearch-hooks-web";

const SearchModal = ({ placeholders, searchLinks }) => {
  const { modal, dispatchModal } = useUI();
  const scrollableRef = useRef();

  useEffect(() => {
    if (modal.type === "search") {
      if (modal.open) {
        document.body.classList.add("search-open");
      } else {
        document.body.classList.remove("search-open");
      }
    }
    return () => document.body.classList.remove("search-open");
  }, [modal]);

  const algoliaClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
  );

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      const newRequests = requests.map((request) => {
        if (!request.params.query || request.params.query.length === 0) {
          request.params.analytics = false;
        }
        return request;
      });
      return algoliaClient.search(newRequests);
    },
  };

  const closeSearch = () => {
    dispatchModal({
      type: "SET_OPEN",
      value: false,
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const searchBox = document.querySelector("#algolia-search-box");
      if (searchBox && searchBox === document.activeElement) {
        searchBox.blur();
      }
    };
  
    const currentRef = scrollableRef.current; 
    currentRef?.addEventListener("touchmove", handleScroll);
  
    return () => {
      currentRef?.removeEventListener("touchmove", handleScroll);
    };
  }, [scrollableRef]);

  return (
    <dialog
      className="block w-full h-auto p-0 bg-white"
      onClick={(e) => e.stopPropagation()}
      tabIndex={0}
    >
      <Container>
        <header className="relative flex items-center h-17 lg:h-[6.5rem] lg:flex-row-reverse lg:justify-between">
          <button onClick={() => closeSearch()}>
            <Icon name="close" />
          </button>
          <span className="lg-max:absolute lg-max:-translate-x-1/2 lg-max:-translate-y-1/2 lg-max:top-1/2 lg-max:left-1/2 text__cta whitespace-nowrap">
            Search The Library
          </span>
        </header>
        <article
          ref={scrollableRef}
          className="overflow-y-auto max-h-[calc(100vh-4.375rem)] lg:max-h-[calc(100vh-10.875rem)] pb-15 lg:pb-0"
        >
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX}
          >
            <Configure
              attributesToHighlight={["title", "excerpt"]}
              clickAnalytics
            />
            <Middleware />
            <Search />
            <Results placeholders={placeholders} searchLinks={searchLinks} />
          </InstantSearch>
        </article>
      </Container>
    </dialog>
  );
};

export default SearchModal;
