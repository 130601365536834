import { useUI } from "@/context/UIContext";
import { MappedMenuItemWithChildren } from "@/types/menu";
import { useRef, useState } from "react";

export interface DesktopMenuProps {
  workshopView?: boolean;
  workshopNav?: boolean;
  items: MappedMenuItemWithChildren[];
}

export const useDesktopMenuLogic = ({
  items,
  workshopNav = false,
  workshopView = false,
}: DesktopMenuProps) => {
  const timeoutDuration = 100;
  let timeout = useRef<NodeJS.Timeout>();
  const menuRef = useRef<HTMLLIElement>(null);
  const menuListRef = useRef<HTMLUListElement>(null);

  const [buttonRef, setButtonRef] = useState<HTMLElement | null>();
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
  const { setFocusHeader, modal, dispatchModal } = useUI();

  const [open, setOpen] = useState<number | null>();

  const closeSearch = () => {
    dispatchModal({
      type: "SET_OPEN",
      value: false,
    });
  };

  const openDropdown = (index: number, hasChildren?: boolean) => {
    if (timeout.current) clearTimeout(timeout.current);
    setOpen(index);
    if (hasChildren) setFocusHeader(true);

    document.addEventListener("mousedown", handleClickOutside);
  };

  const closeDropdown = () => {
    if (window.scrollY === 0 && modal.type === "search" && !modal.open)
      setFocusHeader(false);
    timeout.current = setTimeout(() => {
      setOpen(null);
    }, timeoutDuration);

    document.removeEventListener("mousedown", handleClickOutside);
  };

  const toggleDropdown = (index: number, hasChildren?: boolean) => {
    if (open === index) {
      closeDropdown();
    } else {
      openDropdown(index, hasChildren);
    }
  };

  const handleClickOutside = (event) => {
    if (menuListRef.current && !menuListRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  return {
    items,
    menuRef,
    buttonRef,
    popoverRef,
    menuListRef,
    workshopNav,
    workshopView,
    open,
    toggleDropdown,
    closeSearch,
    setPopoverRef,
    setButtonRef,
  };
};

export type DesktopMenuComponentProps = ReturnType<typeof useDesktopMenuLogic>;
