import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getAllTopicCards({ slug }) {
  const data = await serverSideCmsFetch(
    `
    {
        topics(first: 100) {
            edges {
                node {
                    name
                    slug
                    topicCustomData {
                      topicThumbnail {
                        mediaItemUrl
                        }
                        active
                    }
                }
            }
        }
    }
    `,
    { variables: {} }
  );

  // Filter out topics with no image set
  data.topics.edges = data.topics.edges.filter(
    ({ node }) =>
      node.topicCustomData.topicThumbnail && node.topicCustomData.active
  );

  return data;
}
