import { track } from "@/lib/helpers/analytics";
import axios from "axios";
import { useState } from "react";
import { isEmail } from "validator";

interface LeadFormTracking {
  form_type: string;
  location: string;
}

// todo update survey_tool if more tools are added
// todo update survey_response_type if more types are added
interface SurveyQuestionTracking {
  survey_tool: "Builder";
  survey_type: string;
  survey_response_type: "multiple_select";
  survey_question_text: string;
  survey_answer_text: {};
}

export function useBrazeFormSubmit() {
  const [isSending, setIsSending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [targetEmail, setTargetEmail] = useState("");
  const [userProfile, setUserProfile] = useState({});
  const [leadFormTracking, setLeadFormTracking] = useState<LeadFormTracking>({
    form_type: "",
    location: "",
  });
  const [surveyQuestions, setSurveyQuestions] = useState<
    SurveyQuestionTracking[]
  >([]);

  const trackLeadFormCaptured = (email) => {
    track("Lead Form Completed", {
      email: email?.toLowerCase(),
      form_type: leadFormTracking?.form_type,
      location: leadFormTracking?.location,
    });
  };

  const trackSurveyQuestionAnswered = () => {
    surveyQuestions.forEach((surveyQuestion) => {
      track("Survey Question Answered", surveyQuestion);
    });
  };

  const handleSubmit = async (email: string, trackForm: boolean = true) => {
    const requestBody = { email, userProfile };

    const requestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!!email && isEmail(email)) {
      setValidationMessage("");
      setIsSending(true);
      setTargetEmail(email);
      try {
        await axios.post(
          "/api/braze-subscribe-email/",
          requestBody,
          requestConfig
        );
        setIsSending(false);
        if (trackForm) {
          trackLeadFormCaptured(email);
          if (surveyQuestions?.length > 0) {
            trackSurveyQuestionAnswered();
          }
        }
        setIsSubmitted(true);
        return true;
      } catch (error) {
        console.log(error);
        setIsSending(false);
        setIsSubmitted(false);
        setSubmissionError(true);
      }
    } else {
      setValidationMessage("Please enter a valid e-mail");
    }
    return false;
  };

  return {
    isSending,
    isSubmitted,
    targetEmail,
    validationMessage,
    setValidationMessage,
    handleSubmit,
    userProfile,
    setUserProfile,
    leadFormTracking,
    setLeadFormTracking,
    surveyQuestions,
    setSurveyQuestions,
  };
}
