import cn from "classnames";

const FormError = ({ isCentered = false, error, className = "" }) => (
  <div
    className={cn("mt-2 mb-3 text-sm tracking-0.5 text-red", className, {
      "mt-3 mb-0 text-center": isCentered,
    })}
  >
    {error.message}
  </div>
);

export default FormError;
