import {
  acfGlobalOptionsQuery,
  placeholderQuery,
  searchLinksQuery,
} from "@/lib/fragments/graphQLqueries";
import { referralsQuery } from "@/lib/fragments/graphQLqueries/referralsQuery";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getScript({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query ScriptQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID, asPreview: ${preview}) {
        ... on Script {
          databaseId
          title
          slug
          date
          excerpt

          contentDuration {
            timeToConsume
          }
          topics {
              nodes {
                name
                slug
                topicId
                topicCustomData {
                  circleSpaceUrl
                }
              }
          }
          scriptContent {
            allowDownload
            scriptImage {
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
            summary
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          ${acfGlobalOptionsQuery}
          ${referralsQuery}
        }
      }
      ${placeholderQuery}
      ${searchLinksQuery}
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return data;
}
