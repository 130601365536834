import Button from "@/components/Button";
import GoogleButton from "@/components/GoogleButton";
import OrDivider from "@/components/OrDivider";
import FormError from "@/components/Registration/FormError";
import { useAuth } from "@/context/AuthContext";
import Link from "next/link";
import { useState } from "react";
import { useForm } from "react-hook-form";

const RegisterForm = ({ setFormState, purchaseUrl, postId, nextUrl }) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { register: registerUser } = useAuth();
  const [disableButton, setDisableButton] = useState(false);

  const queryParams = {
    purchaseUrl,
    postId,
    isRegistering: !purchaseUrl,
  };

  const submitRegister = async ({
    email,
    password,
    password_confirmation,
    first_name,
    last_name,
    accepted_terms,
  }) => {
    setDisableButton(true);
    try {
      await registerUser({
        email,
        password,
        password_confirmation,
        first_name,
        last_name,
        purchaseUrl,
        postId,
        accepted_terms: accepted_terms,
        nextUrl: nextUrl,
      });
    } catch (error) {
      setDisableButton(false);
      console.error(error);

      const parsedError = JSON.parse(error.data);
      for (const key in parsedError) {
        setError("form", {
          type: key,
          message: parsedError[key][0],
        });
      }
    }
  };

  return (
    <div>
      <div className="text-center">
        <h2 className="mb-5 heading__lg">Create Your Account</h2>
        <GoogleButton queryParams={queryParams}>
          Sign up with Google
        </GoogleButton>
      </div>

      <OrDivider bgColor="bg-light-gray" />

      <div className="max-w-xl mx-auto">
        <form
          onSubmit={handleSubmit(submitRegister)}
          onChange={() => {
            clearErrors("form");
          }}
          className="flex justify-center form-group"
        >
          <label htmlFor="email" className="mb-2">
            Email Address
          </label>
          <input
            placeholder="Email Address"
            autoComplete="email"
            autoCapitalize="off"
            id="email"
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", {
              required: "Email is required",
              minLength: { value: 3, message: "Email is too short" },
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Enter a valid email",
              },
            })}
            type="email"
          />
          {errors.email && <FormError error={errors.email} />}

          <div className="lg:flex">
            <div className="w-full mr-6">
              <label htmlFor="password" className="mt-4 mb-2">
                Create Password
              </label>
              <input
                id="password"
                autoComplete="new-password"
                placeholder="Create Password"
                className="font-mono placeholder:font-sans"
                aria-invalid={errors.password ? "true" : "false"}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password needs to be more than 6 characters",
                  },
                })}
                type="password"
              />
              {errors.password && <FormError error={errors.password} />}
            </div>

            <div className="w-full">
              <label htmlFor="password-confirmation" className="mt-4 mb-2">
                Confirm Password
              </label>
              <input
                placeholder="Confirm Password"
                autoComplete="new-password"
                id="password-confirmation"
                className="font-mono placeholder:font-sans"
                aria-invalid={errors.password_confirmation ? "true" : "false"}
                {...register("password_confirmation", {
                  required: "Please confirm your password",
                  validate: () => {
                    const passwordValue = getValues("password");
                    const passwordConfirmValue = getValues(
                      "password_confirmation"
                    );

                    return passwordValue === passwordConfirmValue;
                  },
                })}
                type="password"
              />
              {errors.password_confirmation && (
                <FormError error={{ message: "Passwords do not match" }} />
              )}
            </div>
          </div>

          <div className="lg:flex">
            <div className="w-full mr-6">
              <label htmlFor="first-name" className="mt-4 mb-2">
                First Name
              </label>
              <input
                placeholder="First Name"
                autoComplete="given-name"
                id="first-name"
                aria-invalid={errors.first_name ? "true" : "false"}
                {...register("first_name", {
                  required: "First name is required",
                })}
                type="text"
              />
              {errors.first_name && <FormError error={errors.first_name} />}
            </div>
            <div className="w-full">
              <label htmlFor="last-name" className="mt-4 mb-2">
                Last Name
              </label>
              <input
                placeholder="Last Name"
                autoComplete="family-name"
                id="last-name"
                aria-invalid={errors.last_name ? "true" : "false"}
                {...register("last_name", {
                  required: "Last name is required",
                })}
                type="text"
              />
              {errors.last_name && <FormError error={errors.last_name} />}
            </div>
          </div>

          <div>
            <div className="w-full flex lg:pt-4 pt-[1.125em] pl-1">
              <input
                id="accepted-terms"
                aria-invalid={errors.accepted_terms ? "true" : "false"}
                {...register("accepted_terms", {
                  required:
                    "You must agree to the Terms of Use and Privacy Policy",
                })}
                type="checkbox"
                className=""
              />
              <label
                htmlFor="accepted-terms"
                className="ml-2.5 font-sans tracking-tight font-normal leading-3.75 text-gray-15 text-xs"
              >
                I agree to the{" "}
                <Link
                  href="/terms-of-use/"
                  className="underline"
                  target="_blank"
                >
                  Terms of Use
                </Link>{" "}
                and{" "}
                <Link
                  href="/privacy-policy/"
                  className="underline"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                .*
              </label>
            </div>
            <div className="w-full pb-3.5">
              {errors.accepted_terms && (
                <FormError
                  className="mb-0 text-xs leading-3.75 tracking-tight"
                  error={errors.accepted_terms}
                />
              )}
            </div>
          </div>
          {errors.form && <FormError isCentered error={errors.form} />}

          <Button
            type="submit"
            disabled={disableButton}
            className="justify-center w-full mt-5"
          >
            Create Account
          </Button>
        </form>
        <div className="text-sm text-center md-max:pb-8">
          Already have an account?
          <button
            className="ml-1 font-semibold underline underline-offset-1"
            onClick={() => {
              setFormState("login");
            }}
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
