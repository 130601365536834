import { DesktopMenuItem } from "./DesktopMenuItem";
import { DesktopMenuProps, useDesktopMenuLogic } from "./useDesktopMenuLogic";
import cn from "classnames";

export const DesktopMenu = (props: DesktopMenuProps) => {
  const {
    open,
    items,
    menuRef,
    buttonRef,
    popoverRef,
    menuListRef,
    workshopNav,
    workshopView,
    closeSearch,
    setButtonRef,
    setPopoverRef,
    toggleDropdown,
  } = useDesktopMenuLogic(props);

  return (
    <ul
      onClick={() => closeSearch()}
      className={cn(
        "absolute top-full -left-full m-0 h-[100vh] w-full list-none overflow-y-scroll px-6",
        "lg:relative lg:top-0 lg:left-0 lg:flex lg:h-full lg:items-center lg:overflow-y-visible lg:p-0 lg:shadow-none"
      )}
      ref={menuListRef}
    >
      {items?.map((item, index) => {
        return (
          <DesktopMenuItem
            key={`${index}-${item.label}`}
            item={item}
            index={index}
            itemsCount={items.length}
            openIndex={open}
            menuRef={menuRef}
            buttonRef={buttonRef}
            popoverRef={popoverRef}
            workshopNav={workshopNav}
            workshopView={workshopView}
            setButtonRef={setButtonRef}
            setPopoverRef={setPopoverRef}
            toggleDropdown={toggleDropdown}
          />
        );
      })}
    </ul>
  );
};
