import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getEventLogo() {
  const data = await serverSideCmsFetch(
    `query getEventLogo {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          eventLogo {
            desktop {
              altText
              mediaItemUrl
            }
            mobile {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
      `,
    {}
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent.eventLogo;
}
