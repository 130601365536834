import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getShowreel() {
  const data = await serverSideCmsFetch(
    `query Showreel {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          featuredLibraryContent {
            sectionTitle
            sectionSubtitle
            sectionContent {
                topic {
                    topicId
                    name
                }
                cards {
                  ... on Article {
                    databaseId
                    title
                    slug
                    featuredImage {
                        node {
                        sourceUrl
                        altText
                        }
                    }
                    topics {
                      nodes {
                        name
                      }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                      timeToConsume
                    }
                  }
                  ... on Video {
                    databaseId
                    title
                    featuredImage {
                        node {
                        sourceUrl
                        altText
                        }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Script {
                    databaseId
                    title
                    featuredImage {
                      node {
                        sourceUrl
                        altText
                      }
                    }
                    slug
                    topics {
                      nodes {
                        name
                      }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Workshop {
                    databaseId
                    title
                    featuredImage {
                        node {
                        sourceUrl
                        altText
                        }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Podcast {
                    databaseId
                    title
                    featuredImage {
                        node {
                        sourceUrl
                        altText
                        }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
              }
            }
          }
        }
      }
    }`,
    {}
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent
    .featuredLibraryContent;
}
