import Container from "@/components/Containers/Container";
import cn from "classnames";

export default function PreviewWarning({ preview }) {
  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 z-50 flex h-12 items-center bg-yellow",
        {}
      )}
    >
      <Container>
        <div className="text-sm text-center text-black">
          {preview && (
            <>
              This is a page preview.
              <form method="get" action="/api/exit-preview" className="inline">
                <input
                  value="Click Here"
                  type="submit"
                  className="p-1 mx-2 cursor-pointer btn"
                />
              </form>
              to exit preview mode.
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
