import AppErrorBoundary from "../components/Error/AppBoundary";
import CookieConsentManager from "@/components/CookieConsentManager";
import FooterScripts from "@/components/FooterScripts";
import Modal from "@/components/Modal";
import PageTracking from "@/components/PageTracking";
import SEO from "@/components/SEO";
import { AuthProvider } from "@/context/AuthContext";
import { BuilderContext } from "@/context/BuilderContext";
import { FilterProvider } from "@/context/FilterContext";
import { GrowthbookProvider } from "@/context/GrowthbookContext";
import { ShareableContentProvider } from "@/context/ShareableContentContext";
import { UIProvider } from "@/context/UIContext";
import { UserAgentProvider } from "@/context/UserAgentContext";
import { UserProvider } from "@/context/UserContext";
import { builderFullHeadAssembler } from "@/lib/helpers/seoFullHead";
import "@/styles/index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Montserrat, Roboto_Mono } from "next/font/google";
import localFont from "next/font/local";

const montserrat = Montserrat({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700", "800", "900"],
  display: "swap",
  preload: true,
});

const roboto_mono = Roboto_Mono({
  subsets: ["latin"],
  display: "swap",
  preload: true,
});

const trademarkLocal = localFont({
  src: [
    {
      path: "../lib/fonts/trademarks.woff2",
      weight: "400",
    },
    {
      path: "../lib/fonts/trademarks.woff2",
      weight: "500",
    },
    {
      path: "../lib/fonts/trademarks-semi.woff2",
      weight: "600",
    },
    {
      path: "../lib/fonts/trademarks-semi.woff2",
      weight: "700",
    },
  ],
  adjustFontFallback: false,
});

const queryClient = new QueryClient();

function App({ Component, pageProps }) {
  const getPageSEO = () => {
    if (
      pageProps?.builderIoPage &&
      pageProps?.builderIoPage?.published == "published"
    ) {
      const builderFullHead = builderFullHeadAssembler(
        pageProps?.builderIoPage
      );

      const builderSEO = {
        title:
          pageProps?.builderIoPage?.data?.title + " - Good Inside" ||
          "Good Inside",
        description:
          pageProps?.builderIoPage?.data?.description ||
          pageProps?.builderIoPage?.data?.shortExcerpt ||
          pageProps?.fallbacks?.find(
            (item) => item?.name === "Meta Description"
          ).data?.value,
        metaRobotsNofollow: pageProps?.builderIoPage?.data?.noindex ? "nofollow" : "follow",
        metaRobotsNoindex: pageProps?.builderIoPage?.data?.noindex ? "noindex" : "index",
        fullHead: builderFullHead,
      };
      return builderSEO;
    } else {
      return pageProps?.seo;
    }
  };
  const pageSEO = getPageSEO();

  return (
    <>
      <style jsx global>
        {`
          :root {
            --font-trademarks: ${trademarkLocal.style.fontFamily};
            --font-montserrat: ${montserrat.style.fontFamily};
            --font-roboto-mono: ${roboto_mono.style.fontFamily};
          }
        `}
      </style>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.userLoggedIn = null;
            window.isAuthVerified = false;
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <SEO seo={pageSEO} />
        <GrowthbookProvider>
          <UserProvider>
            <AuthProvider>
              <UserAgentProvider>
                <UIProvider>
                  <AppErrorBoundary>
                    <ShareableContentProvider>
                      <FilterProvider>
                        <BuilderContext.Provider>
                          <PageTracking />
                          <main>
                            <Component {...pageProps} />
                          </main>
                          <ReactQueryDevtools />
                          <Modal
                            placeholders={pageProps.placeholders}
                            searchLinks={pageProps.searchLinks}
                          />
                          <CookieConsentManager />
                          <FooterScripts />
                        </BuilderContext.Provider>
                      </FilterProvider>
                    </ShareableContentProvider>
                  </AppErrorBoundary>
                </UIProvider>
              </UserAgentProvider>
            </AuthProvider>
          </UserProvider>
        </GrowthbookProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
export { reportWebVitals } from "next-axiom";
