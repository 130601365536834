import { useRouter } from "next/router";
import { useState } from "react";

export interface LibraryDropdownItemProps {
  textOnly?: boolean;
  href?: string | null;
  label?: string | null;
  iconUrl?: string | null;
  newItem?: boolean | null;
  onClick?: VoidFunction;
}

export const useLibraryDropdownItemLogic = ({
  href,
  label,
  newItem,
  iconUrl,
  textOnly,
  onClick,
}: LibraryDropdownItemProps) => {
  const router = useRouter();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isCurrentlySelected = href && router.asPath.startsWith(href);

  const handleMouseOver = () => {
    if (!textOnly) return;
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    if (!textOnly) return;
    setIsMouseOver(false);
  };

  return {
    href,
    label,
    newItem,
    iconUrl,
    textOnly,
    isMouseOver,
    isCurrentlySelected,
    handleMouseOver,
    handleMouseLeave,
    onClick: !textOnly ? onClick : undefined,
  };
};

export type LibraryDropdownItemComponentProps = ReturnType<
  typeof useLibraryDropdownItemLogic
>;
