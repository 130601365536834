import { builder } from "@builder.io/react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  isAndroid,
  isIOS,
  isMacOs,
  isWindows,
  browserName,
} from "react-device-detect";

const UserAgentContext = createContext();

function UserAgentProvider({ children }) {
  const [userBrowser, setUserBrowser] = useState(null);
  const [userDevice, setUserDevice] = useState(null);
  const userEnvironment =
    process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "production"
      : "staging";

  useEffect(() => {
    setUserBrowser(browserName);

    if (isAndroid) {
      setUserDevice("Android");
    } else if (isIOS) {
      setUserDevice("iOS");
    } else if (isMacOs) {
      setUserDevice("MacOS");
    } else if (isWindows) {
      setUserDevice("Windows");
    } else {
      setUserDevice("Other");
    }
  }, []);

  useEffect(() => {
    builder.setUserAttributes({
      browser: userBrowser,
      device: userDevice,
      environment: userEnvironment,
    });
  }, [userBrowser, userDevice, userEnvironment]);

  const value = {
    userBrowser,
    userDevice,
  };

  return (
    <UserAgentContext.Provider value={value}>
      {children}
    </UserAgentContext.Provider>
  );
}

function useUserAgent() {
  const context = useContext(UserAgentContext);
  if (context === undefined) {
    throw new Error("useUserAgent must be used within a UserProvider");
  }
  return context;
}

export { UserAgentProvider, useUserAgent };
