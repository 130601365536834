import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";

export async function getAlertBanners() {
  const { acfOptionsGlobalContent } = await clientSideCmsFetch(
    `query GetAlertBanner {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          alertBannersContent {
            alertBanners {
              active
              audience
              color
              dismissable
              linkurl
              linktext
              message
              slug
            }
          }
        }
      }
    }`
  );

  return acfOptionsGlobalContent?.optionsGlobalContent?.alertBannersContent
    ?.alertBanners;
}
