import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Blog Sections",
  items: [{ name: "featuredArticleHero" }, { name: "articleGrid" }],
});

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/FeaturedArticleHero.tsx")),
  {
    name: "featuredArticleHero",
    image: assembleTablerIconUrl("star"),
    inputs: [
      {
        name: "mobileImage",
        type: "file",
        allowedFileTypes: ["jpeg", "png"],
        helperText: "This will override the mobile image for the featured blog article.",
      },
      {
        name: "mobileImageAlt",
        type: "string",
        showIf: (options) => options.get("mobileImage") !== undefined,
      },
      {
        name: "desktopImage",
        type: "file",
        allowedFileTypes: ["jpeg", "png"],
        helperText:
          "This will override the desktop image for the featured blog article.",
      },
      {
        name: "desktopImageAlt",
        type: "string",
        showIf: (options) => options.get("desktopImage") !== undefined,
      },
      {
        name: "subtitle",
        type: "string",
        defaultValue: "Featured Article",
      },
      {
        name: "buttonText",
        type: "string",
        defaultValue: "Read Now",
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/ArticleGrid.tsx")),
  {
    name: "articleGrid",
    image: assembleTablerIconUrl("layout-grid"),
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "All Articles",
      },
      {
        name: "mobileSectionAmount",
        friendlyName: "Number of articles (mobile)",
        type: "number",
        defaultValue: 3,
        helperText: "Additional items will load via 'Load More' button.",
      },
      {
        name: "desktopSectionAmount",
        friendlyName: "Number of articles (desktop)",
        type: "number",
        defaultValue: 6,
        helperText: "Additional items will load via 'Load More' button.",
      },
    ],
  }
);