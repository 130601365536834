import { USER_AUTH_URL } from "@/lib/constants";

const getUserIdFromEmail = async (email: string) => {
  if (!USER_AUTH_URL) throw new Error("Auth url not defined");
  try {
    const userApiRes = await fetch(
      `${USER_AUTH_URL}/api/user/id/${email}?billing=true`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const user: { userId: number } | null = await userApiRes.json();
    if (user == null) throw new Error("Cannot find user email");
    return user.userId;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default getUserIdFromEmail;
