import Footer from "@/components/Footer";
import Meta from "@/components/Meta";
import PreviewWarning from "@/components/PreviewWarning";
import SiteHeader from "@/components/SiteHeader";
import { MobileMenuProvider } from "@/context/MobileMenuContext";
import { useUI } from "@/context/UIContext";
import cn from "classnames";

export default function Layout({
  preview = undefined,
  eventLogo,
  children,
  showHeaderCta = undefined,
  className = "",
  mainClassName = "",
  transparentHeader = undefined,
  searchLinks,
  headerCta = undefined,
}) {
  const { headerRef } = useUI();
  return (
    <>
      {preview && <PreviewWarning preview={preview} />}
      <Meta />
      <div
        className={cn(
          "flex min-h-screen flex-col md-max:overflow-x-hidden transition-all",
          className
        )}
      >
        <MobileMenuProvider>
          <SiteHeader
            ref={headerRef}
            showHeaderCta={showHeaderCta}
            eventLogo={eventLogo}
            transparent={transparentHeader}
            searchLinks={searchLinks}
            headerCta={headerCta}
          />
        </MobileMenuProvider>

        <main role="main" className={cn("flex-1", mainClassName)}>
          {children}
        </main>
        <div className="fixed bottom-8 right-4 z-dockedMedia w-80 md:bottom-16 md:right-10 md:w-96"></div>
      </div>
      <Footer />
    </>
  );
}
