import Banner from "@/components/Banner";
import Link from "@/components/NextLink";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import { useIssue } from "@/hooks/useIssue";
import { DISMISSED_BANNERS_KEY } from "@/lib/constants";
import { getAlertBanners } from "@/lib/requests";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const AlertBanner = () => {
  const { displayStatusBanner, displayAlertBanner, setDisplayAlertBanner } =
    useUI();
  const [bannerContent, setBannerContent] = useState({});
  const [validBanners, setValidBanners] = useState(new Map());
  const [validBannersSize, setValidBannersSize] = useState(0);
  const issue = useIssue();
  const { user } = useUser();

  const { data: alertBanners } = useQuery(
    ["alertBanners"],
    async () => {
      return await getAlertBanners();
    },
    {
      staleTime: 10 * 60 * 1000, // 10 minutes in milliseconds
      cacheTime: 10 * 60 * 1000, // 10 minutes in milliseconds
    }
  );

  useEffect(() => {
    return () => {
      setDisplayAlertBanner(false);
    };
  }, []);

  useEffect(() => {
    if (!alertBanners?.length || user === null) return;

    /**
     * Generate a list of valid banners from the CMS
     * Map over alert banners in order and determine whether a user should see
     * the banner based on current state.
     */
    let validBannersMap = new Map();
    alertBanners.forEach((banner) => {
      const { active, audience, slug } = banner;

      // If banner is not 'active' in the CMS return.
      if (!active) return;

      // Ensure 'audience' matches users current state
      const allBanner = audience === "all";
      const memberBanner = user.subscription_active && audience === "members";
      const guestBanner =
        (!user || !user.subscription_active) && audience === "guest";

      if (allBanner || memberBanner || guestBanner) {
        // Check localStorage to see if user has previously dismissed the banner
        const dismissedBanners =
          JSON.parse(window.localStorage.getItem(DISMISSED_BANNERS_KEY)) || [];

        if (dismissedBanners.includes(slug)) return;

        validBannersMap.set(slug, banner);
      }
    });

    const deferredSetValidBannersTimer = setTimeout(() => {
      setValidBanners(validBannersMap);
    }, 5000);
    return () => clearTimeout(deferredSetValidBannersTimer);
  }, [alertBanners, displayAlertBanner, user]);

  useEffect(() => {
    if (!validBanners && !issue) return;

    // To prevent the Map from causing infinite loops, compare the size of the Map
    // and then cache the value to prevent rerenders.
    if (validBannersSize === validBanners.size) return;

    setValidBannersSize(validBanners.size);

    // If no issues or alert from the CMS we can return
    if (validBanners?.size) {
      const [banner] = validBanners.values();

      setBannerContent({
        active: banner.active,
        message: banner.message,
        buttonLabel: banner.linktext,
        buttonHref: banner.linkurl,
        dismissable: banner.dismissable,
        slug: banner.slug,
        color: banner.color,
      });
      setDisplayAlertBanner(true);
    } else if (issue?.message && bannerContent?.message !== issue.message) {
      setBannerContent({
        message: issue.message,
        buttonLabel: issue.buttonLabel,
        buttonHref: issue.buttonHref,
        color: "blue",
        active: true,
      });
      setDisplayAlertBanner(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue, alertBanners, validBanners, validBannersSize]);

  const handleDismiss = () => {
    setDisplayAlertBanner(false);

    if (!bannerContent?.slug) return;

    const dismissedBanners =
      JSON.parse(window.localStorage.getItem(DISMISSED_BANNERS_KEY)) || [];

    dismissedBanners.push(bannerContent.slug);
    validBanners.delete(bannerContent.slug);

    window.localStorage.setItem(
      DISMISSED_BANNERS_KEY,
      JSON.stringify(dismissedBanners)
    );
  };

  const { message, buttonLabel, buttonHref, dismissable, color } =
    bannerContent;

  const guestMsg = issue?.message === undefined;
  const nonIssueMsg =
    issue?.message && bannerContent?.message !== issue.message;
  const IssueMsg = issue?.message && bannerContent?.message === issue.message;

  return (
    <Banner
      color={color}
      dismissable={dismissable}
      onDismiss={handleDismiss}
      classNames="transition-all ease-in overflow-hidden duration-500"
    >
      {message && !displayStatusBanner && displayAlertBanner && (
        <div className="flex items-center justify-center mx-auto lg:text-center">
          {(guestMsg || nonIssueMsg) && (
            <div
              className="font-semibold text__b3"
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )}

          {IssueMsg && (
            <span className="font-semibold text__b3">
              {message}
              {buttonHref && (
                <Link
                  href={buttonHref}
                  className="ml-2 font-semibold underline text__b3"
                >
                  {buttonLabel}
                </Link>
              )}
            </span>
          )}
        </div>
      )}
    </Banner>
  );
};

export default AlertBanner;
