import { useAuth } from "@/context/AuthContext";
import { useUser } from "@/context/UserContext";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

const ShareableContentContext = createContext();

function saveHashOnLocalStorage(hash) {
  localStorage.setItem("shareHash", hash);
}

function getHashFromLocalStorage() {
  return localStorage.getItem("shareHash");
}

function clearHashFromLocalStorage() {
  localStorage.removeItem("shareHash");
}

function useProvideShareableContent() {
  const router = useRouter();
  const { user, authorizeUsingHash } = useUser();
  const { getUserData } = useAuth();

  const hasShareHash = () => {
    return !!getHashFromLocalStorage();
  };

  const syncShareableContentHashes = async () => {
    if (!user) {
      return;
    }
    const localHash = getHashFromLocalStorage();
    if (!localHash) {
      return;
    }
    try {
      const res = await authorizeUsingHash({ hash: localHash });
    } catch (err) {
      console.error("invalid hash", err);
    }
    clearHashFromLocalStorage();
    await getUserData();
  };

  useEffect(() => {
    if (router.query.sharehash) {
      saveHashOnLocalStorage(router.query.sharehash);
    }
  }, [router.query]);

  return {
    syncShareableContentHashes,
    hasShareHash,
  };
}

// Provider component that wraps your app and makes auth object
const ShareableContentProvider = ({ children }) => {
  const shareableContent = useProvideShareableContent();
  return (
    <ShareableContentContext.Provider value={shareableContent}>
      {children}
    </ShareableContentContext.Provider>
  );
};

// Hook for child components to get the auth object
const useShareableContent = () => {
  const context = useContext(ShareableContentContext);
  if (context === undefined) {
    throw new Error(
      "useShareableContent must be used within a ShareableContentProvider"
    );
  }
  return context;
};

export { ShareableContentProvider, useShareableContent };
