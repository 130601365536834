import { useUser } from "@/context/UserContext";
import { RETURNING_SUBSCRIBER_KEY } from "@/lib/constants";
import { ROUTES, RoutesValues } from "@/lib/routes";
import { useEffect, useState } from "react";

export function useHomeUrl() {
  const { user } = useUser();
  const [homeUrl, setHomeUrl] = useState<RoutesValues>(ROUTES.HOME);
  useEffect(() => {
    if (
      (typeof window != "undefined" &&
        window.localStorage.getItem(RETURNING_SUBSCRIBER_KEY)) ||
      user?.subscribe_active
    ) {
      setHomeUrl(ROUTES.LIBRARY);
    } else {
      setHomeUrl(ROUTES.HOME);
    }
  }, [user]);

  return homeUrl;
}

export default useHomeUrl;
