import { USER_PROGRESS_URL } from "@/lib/constants";

export async function getContentViews(contentID: number) {
  try {
    const contentViewsRes = await fetch(
      `${USER_PROGRESS_URL}/api/v2/content?contentID=${contentID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data: { totalViews: number; totalComplete: number } | null =
      await contentViewsRes.json();

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

