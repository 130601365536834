import { track } from "@/lib/helpers/analytics";
import { useRouter } from "next/router";

export function useTrackedLink() {
  const router = useRouter();
  const handleTrackedLink = (e, url, target) => {
    e.preventDefault();
    if (url.startsWith("http")) {
      track("Outbound Link Clicked", {
        link: url,
      });
      window.open(url, target || "_self").focus();
    } else {
      router.push(url);
    }
  };
  return { handleTrackedLink };
}
