import Icon from "@/components/Icon";
import { forwardRef } from "react";

const VideoModal = ({ modal, close }, ref) => {
  return (
    <dialog ref={ref}>
      <button
        className="fixed top-8 right-8 z-modalClose h-4 w-4 text-base transition-opacity hover:opacity-80 sm:h-6 sm:w-6 md:h-8 md:w-8"
        onClick={close}
      >
        <Icon name="close" color="white" />
      </button>
      <div
        className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-80"
        onClick={close}
      ></div>
      <section className="relative h-fit w-fit lg:min-h-[20em]">
        <div className="absolute top-0 left-0 z-50 flex h-16 w-full items-center justify-end px-8"></div>
        <div className="h-fit w-screen max-w-[85rem] overflow-auto px-4 py-20 md:px-12 lg:max-h-[95vh] lg:py-16">
          {modal.content}
        </div>
      </section>
    </dialog>
  );
};

export default forwardRef(VideoModal);
