import completionBadgeQuery from "../fragments/graphQLqueries/completionBadgeQuery";
import { tolstoyQuery, abTestQuery } from "@/lib/fragments/graphQLqueries";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getWorkshop({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query WorkshopQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID) {
        ... on Workshop {
          title
          slug
          databaseId
          id: workshopId
          excerpt
          featuredImage {
            node {
              mediaItemUrl
              altText
            }
          }
          contentDuration {
            timeToConsume
          }
          topics {
            nodes {
              name
              slug
              topicId
              topicCustomData {
                circleSpaceUrl
              }
            }
          }
          customSections {
            workshopSections {
              ... on Workshop_Customsections_WorkshopSections_FullBleedHero {
                fieldGroupName
                ageBar
                previewVideoId
                ctaLink {
                  title
                  url
                }
                gradientBottom
                gradientTop
                previewLink
                desktopBackground {
                  mediaItemUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
                mobileBackground {
                  mediaItemUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              ... on Workshop_Customsections_WorkshopSections_ContentArticle {
                fieldGroupName
                contentArticle
              }
              ... on Workshop_Customsections_WorkshopSections_SignedBenefits {
                fieldGroupName
                backgroundColor
                content
              }
              ... on Workshop_Customsections_WorkshopSections_CuratedWorkshops {
                fieldGroupName
                contentType
                title
                viewAllLink
                customList {
                  chooseContent {
                    ... on Workshop {
                      title
                      id: workshopId
                      slug
                      topics {
                        nodes {
                          name
                        }
                      }
                      featuredImage {
                        node {
                          mediaItemUrl
                          altText
                          mediaDetails {
                            width
                            height
                          }
                        }
                      }
                      contentDuration {
                        timeToConsume
                      }
                      workshopPageContent {
                        price
                        ${completionBadgeQuery}
                      }
                      excerpt
                    }
                  }
                }
              }
              ... on Workshop_Customsections_WorkshopSections_TestimonialCarousel {
                fieldGroupName
                headline
                testimonials {
                  name
                  testimonial
                  title
                }
              }
              ... on Workshop_Customsections_WorkshopSections_StampedReview {
                fieldGroupName
                title
                subtitle
                productId
                reviewsHeading
                reviewsImage {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
              }
              ... on Workshop_Customsections_WorkshopSections_Spacer {
                backgroundColor
                customOverride
                presetSpacer
                fieldGroupName
                customSpacer {
                  desktop
                  mobile
                }
              }
              ... on Workshop_Customsections_WorkshopSections_BuilderSymbol {
                builderSymbolId
                containerSize
                desktopHeight
                desktopWidth
                fieldGroupName
                mobileHeight
                mobileWidth
              }
              ... on Workshop_Customsections_WorkshopSections_TolstoyEmbed {
                ${abTestQuery}
                ${tolstoyQuery}
              }
            }
          }
          workshopPageContent {
            eventEndTime
            eventStartTime
            rsvpLink
            price
            stripePriceId
            ${completionBadgeQuery}
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          headerCtaContent{
            buttonDataId
          }
          drBeckysPromise {
            avatar {
              altText
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
            heading
            subheading
            signature {
              altText
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
            buttonText
          }
          workshopLanding {
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_GlobalHeader {
              fieldGroupName
              mobileImage {
                altText
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              desktopImage {
                altText
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              backgroundColor
            }
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_MembershipPoster {
              fieldGroupName
              heading
              subheading
              perks {
                title
                description
              }
              footer {
                heading
                subheading
                link {
                  title
                  url
                }
              }
              postfix {
                badge {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
                title
                description
              }
            }
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_Faqs {
              fieldGroupName
              faqs {
                title
                content
              }
            }
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_OtherWorkshopsCarousel {
              fieldGroupName
              title
              subtitle
              customList {
                chooseContent {
                  ... on Workshop {
                    id: workshopId
                    title
                    slug
                    topics {
                      nodes {
                        name
                      }
                    }
                    workshopPageContent {
                      ${completionBadgeQuery}
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                      timeToConsume
                    }
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                        mediaDetails {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
              link {
                title
                url
              }
            }
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_SectionAbout {
              fieldGroupName
              backgroundColor
              title
              introText
              text
              featureImage {
                mediaItemUrl
                altText
                mediaDetails {
                  width
                  height
                }
              }
            }
            ... on AcfOptionsGlobalContent_Optionsglobalcontent_WorkshopLanding_LogoMarquee {
              fieldGroupName
              backgroundColor
              logos {
                logo {
                  mediaItemUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return {
    workshop: data.contentNode,
    global: data.acfOptionsGlobalContent.optionsGlobalContent,
  };
}
