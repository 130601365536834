import Image from "@/components/Fallback/OptimizedImageWithFallback";
import Icon from "@/components/Icon";
import useHomeUrl from "@/hooks/useHomeUrl";
import cn from "classnames";

const HeaderIcon = ({ eventLogo }: { eventLogo: any }) => {
  const homeUrl = useHomeUrl();

  return (
    <a
      href={homeUrl}
      className={cn(
        "absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 transition-opacity "
      )}
    >
      {eventLogo?.mobile ? (
        <figure className="sm:hidden">
          <Image
            src={eventLogo.mobile.mediaItemUrl}
            alt={eventLogo.mobile.altText}
            quality={100}
            width="44"
            height="44"
          />
        </figure>
      ) : (
        <Icon name="giLogo" className="sm:hidden" />
      )}
      {eventLogo?.desktop ? (
        <figure className="hidden sm:block">
          <Image
            src={eventLogo.desktop.mediaItemUrl}
            alt={eventLogo.desktop.altText}
            quality={100}
            width="85"
            height="41"
          />
        </figure>
      ) : (
        <Icon
          name="giWordmark"
          className="hidden transition-colors duration-200 sm:block"
        />
      )}
    </a>
  );
};

export default HeaderIcon;
