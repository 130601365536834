const ScriptTypeFields = `
  fragment ScriptTypeFields on Script {
    title
    slug
    date
    excerpt
    contentDuration {
      timeToConsume
    }
    featuredImage {
      node {
        altText
        mediaItemUrl
        mediaDetails {
          height
          width
        }
      }
    }
    customThumbnail {
      featuredImageThumbnail {
        mediaItemUrl
      }
    }
    topics {
      nodes {
        topicId
        name
      }
    }
    contentType {
      node {
        labels {
          singularName
        }
      }
    }
  }
`;
export default ScriptTypeFields;
