import Image from "@/components/Fallback/OptimizedImageWithFallback";
import cn from "classnames";

const ProfilePicture = ({
  photoUrl,
  variant = "small",
  className,
  ...props
}) => {
  const size = {
    small: {
      container: "w-8 h-8",
      height: 32,
      weidth: 32,
    },
    large: {
      container: "w-20 h-20",
      height: 80,
      width: 80,
    },
  };
  return (
    <div
      className={cn(
        "overflow-hidden rounded-full bg-light-gray relative",
        size[variant].container,
        className,
        { ...props }
      )}
    >
      <Image
        src={photoUrl}
        alt="User Avatar"
        className="object-cover"
        fill
        sizes="2rem"
      />
    </div>
  );
};

export default ProfilePicture;
