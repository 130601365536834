import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { useUserAgent } from "@/context/UserAgentContext";
import { GOOGLE_AUTH_URL } from "@/lib/constants";
import cn from "classnames";
import { useRouter } from "next/router";
import { useEffect, useState, useRef, MouseEvent } from "react";

interface GoogleButtonProps {
  children?: React.ReactNode;
  className?: string;
  buttonText?: string;
  queryParams?: {
    referrerUrl?: string;
    isRegistering?: string;
    [key: string]: string | undefined;
  };
}

const filterUndefined = (obj: { [key: string]: string | undefined }) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== undefined)
  ) as { [key: string]: string };
};

const GoogleButton: React.FC<GoogleButtonProps> = ({
  children,
  className = "",
  buttonText,
  queryParams,
}) => {
  const { userDevice, userBrowser } = useUserAgent();
  const router = useRouter();
  const [googleHref, setGoogleHref] = useState<string | null>(null);
  const [queryParamsAssigned, setQueryParamsAssigned] =
    useState<boolean>(false);
  const [showMessagingModal, setShowMessagingModal] = useState<boolean>(false);
  const messagingRef = useRef<HTMLDivElement | null>(null);

  const FALLBACK_BUTTON_TEXT = "Sign up with Google";

  if (!queryParams) {
    queryParams = {
      referrerUrl: router.asPath,
    };
  } else if (!queryParams.referrerUrl) {
    queryParams.referrerUrl = router.asPath.includes("nextUrl=")
      ? router.asPath.split("nextUrl=")[1]
      : router.asPath;
  }

  useEffect(() => {
    if (queryParamsAssigned) return;

    setGoogleHref(() => {
      if (!queryParams) return GOOGLE_AUTH_URL;
      const filteredParams = filterUndefined(queryParams);
      const queryString = new URLSearchParams(filteredParams).toString();
      const updatedHref = `${GOOGLE_AUTH_URL}?${queryString}`;

      return updatedHref;
    });

    setQueryParamsAssigned(true);
  }, [queryParams, queryParamsAssigned, router.asPath]);

  useEffect(() => {
    if (!googleHref) return;
    if (userDevice === "Android" && userBrowser === "Instagram") {
      if (googleHref.includes("intent://")) return;
      setGoogleHref(
        `intent://${googleHref
          .replace("https://", "")
          .replace(
            "http://",
            ""
          )}#Intent;scheme=http;package=com.android.chrome;end`
      );
    } else if (userDevice === "iOS" && userBrowser === "Instagram") {
      setShowMessagingModal(true);
      setGoogleHref(null);
    }
  }, [userDevice, userBrowser, googleHref]);

  const openMessagingModal = () => {
    if (messagingRef.current) {
      messagingRef.current.style.display = "block";
      messagingRef.current.setAttribute("role", "dialog");
      messagingRef.current.tabIndex = 0;
    }
  };

  const closeMessagingModal = () => {
    if (messagingRef.current) {
      messagingRef.current.style.display = "none";
      messagingRef.current.removeAttribute("role");
      messagingRef.current.tabIndex = -1;
    }
  };

  return (
    <>
      {showMessagingModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-[100000]"
          style={{ display: "none" }}
          ref={messagingRef}
          onClick={(e: MouseEvent<HTMLDivElement>) => {
            if (e.target === messagingRef.current) {
              closeMessagingModal();
            }
          }}
        >
          <div className="absolute z-[100000] bg-white top-0 right-0 w-full p-2">
            <p>
              To login with Google, please tap{" "}
              <Icon name="webview" className="inline-block mx-2 align-middle" />{" "}
              in the top right, and then &ldquo;Open in external browser&rdquo;.
            </p>
            <Button
              variant="text"
              className="justify-center w-full mt-3 mb-1 text-xs"
              onClick={closeMessagingModal}
            >
              Return to login
            </Button>
          </div>
        </div>
      )}
      <Button
        variant="base"
        className={cn(
          "rounded-md bg-white px-4 py-3 font-defaultSans text-sm font-medium normal-case tracking-normal text-[#556064] shadow transition-shadow hover:shadow-md",
          "w-full justify-center flex sm:w-[auto] sm:inline-flex",
          className
        )}
        prefixIcon="googleWordmark"
        href={googleHref ?? undefined}
        onClick={showMessagingModal ? openMessagingModal : undefined}
      >
        {children || buttonText || FALLBACK_BUTTON_TEXT}
      </Button>
    </>
  );
};

export default GoogleButton;
