import { LibraryDropdownItem } from "@/components/Menu/LibraryDropdownItem";
import { useUI } from "@/context/UIContext";
import { useMenu } from "@/hooks/useMenu";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import { useState, useEffect } from "react";

const PlaceholderResults = ({ search, searchLinks }) => {
  const [searches, setSearches] = useState([]);
  const menuData = useMenu();
  const { dispatchModal } = useUI();

  const closeSearch = () => {
    dispatchModal({
      type: "SET_OPEN",
      value: false,
    });
  };

  const topicTiles = menuData.Topics;
  const disallowedTopics = ["All", "Gi 365"];
  const allowedTopics = topicTiles.filter(
    (tile) => !disallowedTopics.includes(tile.label)
  );

  try {
    const { data } = useQuery({
      queryKey: ["popularSearches"],
      queryFn: () =>
        fetch(
          `https://analytics.algolia.com/2/searches?index=${process.env.NEXT_PUBLIC_ALGOLIA_INDEX}&limit=6`,
          {
            method: "GET",
            headers: {
              "X-Algolia-Application-Id":
                process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
              "X-Algolia-API-Key":
                process.env.NEXT_PUBLIC_ALGOLIA_ANALYTICS_KEY,
            },
          }
        ).then((response) => response.json()),
    });

    useEffect(() => {
      if (data?.searches?.length) setSearches(data.searches);
    }, [data]);
  } catch (error) {
    console.error(error);
    throw error;
  }

  const handleSearchInput = (term) => {
    const searchInput = document.querySelector("#algolia-search-box");
    searchInput.value = term;
    search.refine(term);
    dispatchModal({
      type: "SET_EVENT",
      value: "populated",
    });
  };

  return (
    <div id="focus-me">
      {searches?.length > 0 && (
        <>
          <h5 className="heading__sm">Popular Searches</h5>
          <ul className="flex flex-col gap-1 p-0 m-0 mt-2">
            {searches.map((term) => (
              <>
                {term.search.length > 0 && (
                  <li
                    className="inline-block p-0 text-sm font-medium capitalize list-none"
                    key={term.search}
                  >
                    <span
                      className="cursor-pointer hoverable:hover:text-gray-10"
                      onClick={() => handleSearchInput(term.search)}
                    >
                      {term.search}
                    </span>
                  </li>
                )}
              </>
            ))}
          </ul>
        </>
      )}
      {searchLinks && (
        <>
          <h5 className="mt-5 heading__sm">{searchLinks.title}</h5>
          <ul className="flex flex-col gap-1 p-0 m-0 mt-2">
            {searchLinks?.posts?.length > 0 &&
              searchLinks.posts.map(({ title, id, slug, contentType }) => (
                <Link
                  href={`/${contentType.node.labels.singularName.toLowerCase()}/${id}/${slug}`}
                  key={title}
                  className="text-sm font-medium capitalize hoverable:hover:text-gray-10"
                  onClick={() => closeSearch()}
                >
                  {title}
                </Link>
              ))}
          </ul>
        </>
      )}
      {topicTiles?.length > 0 && (
        <>
          <h5 className="mt-5 heading__sm">Browse By Topic</h5>
          <ul className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-3.5 gap-x-4 px-0 mt-5">
            {allowedTopics.map((sub, i) => (
              <LibraryDropdownItem
                key={`${i}-${sub.label}`}
                href={sub.url}
                onClick={() => closeSearch()}
                label={sub.label}
                newItem={sub.new}
                iconUrl={sub.mediaItemUrl}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default PlaceholderResults;
