import parse from "html-react-parser";
import Head from "next/head";
import { useRouter } from "next/router";

/**
 * This SEO component renders meta tags and open graph data
 *
 * nb: SEO needs to be queried at the page level to be made available as `seo` in the app.js
 * pageParams object.
 *
 *
 * @param {seo} SEO object provided by Yoast via GraphQL
 */
const SEO = ({ seo }) => {
  const router = useRouter();

  if (!seo) return null;

  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
  const nofollow = isProduction ? seo.metaRobotsNofollow : "nofollow";
  const noindex = isProduction ? seo.metaRobotsNoindex : "noindex";
  const canonicalUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`;

  return (
    <>
      <Head>
        <title>{seo.title || "Good Inside"}</title>
        <link rel="canonical" href={canonicalUrl} />
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        <meta name="robots" content={`${nofollow}, ${noindex}`} />
        {parse(seo.fullHead)}
      </Head>
    </>
  );
};
export default SEO;
