import { track } from "@/lib/helpers/analytics";
import type { Context } from "@growthbook/growthbook-react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";

const GrowthbookLoadContext = createContext({});
const growthbook = new GrowthBook({
  // Callback when a user is put into an A/B experiment
  trackingCallback: (experiment, result) => {
    track("Experiment Viewed", {
      experiment_id: experiment.key,
      variation_id: result.variationId,
      variation_name: result.value,
      ab_tool: "growthbook",
    });
  },
  apiHost: "https://cdn.growthbook.io",
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_KEY,
  enableDevMode:
    process.env.NEXT_PUBLIC_ENABLE_GROWTHBOOK_DEBUG_MODE === "true",
} as Context);

function GrowthbookProvider({ children }) {
  const router = useRouter();
  const [growthbookLoading, setGrowthbookLoading] = useState(false);

  useEffect(() => {
    const fetchGbFeatures = async () => {
      await growthbook.loadFeatures({
        autoRefresh: true,
        timeout: 4000,
      });
      setGrowthbookLoading(true);
    };
    fetchGbFeatures();
  }, []);

  function updateGrowthBookURL() {
    growthbook.setURL(window.location.href);
  }

  useEffect(() => {
    router.events.on("routeChangeComplete", updateGrowthBookURL);
    return () => router.events.off("routeChangeComplete", updateGrowthBookURL);
  }, [growthbook]);

  const value = {
    growthbookLoading,
  };
  return (
    <GrowthbookLoadContext.Provider value={value}>
      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    </GrowthbookLoadContext.Provider>
  );
}

function useGrowthbookLoaded() {
  const context = useContext(GrowthbookLoadContext);
  if (context === undefined) {
    throw new Error(
      "useGrowthbookLoaded must be used within a GrowthbookProvider"
    );
  }
  return context;
}

export { GrowthbookProvider, useGrowthbookLoaded };
