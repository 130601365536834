import completionBadgeQuery from "../fragments/graphQLqueries/completionBadgeQuery";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";
import z from "zod";

const baseWorkshopSchema = z.object({
  databaseId: z.number().nullable(),
  title: z.string(),
  slug: z.string(),
  date: z.string(),

  workshopPageContent: z
    .object({
      eventEndTime: z.string().nullable(),
      eventStartTime: z.string().nullable(),
      completionBadge: z
        .object({
          altText: z.string().nullable(),
          mediaItemUrl: z.string().nullable(),
          mediaDetails: z
            .object({
              height: z.number().nullable(),
              width: z.number().nullable(),
            })
            .nullable(),
        })
        .nullable(),
    })
    .nullable(),
  contentDuration: z.object({
    timeToConsume: z.number().nullable(),
  }),
  seo: z.object({
    title: z.string(),
    fullHead: z.string(),
    metaRobotsNofollow: z.string(),
    metaRobotsNoindex: z.string(),
  }),
  topics: z.object({
    nodes: z.array(
      z.object({
        name: z.string(),
        slug: z.string(),
        topicId: z.number(),
      })
    ),
  }),
  workshopAltTitle: z
    .object({
      altTitle: z.string().nullable(),
    })
    .nullable(),
});

const momentSchema = z.object({
  momentModuleName: z.string().nullable(),
  momentLessonName: z.string().nullable(),
  momentColorScheme: z.string().nullable(),
  firstSlide: z
    .object({
      firstSlideIllustration: z
        .object({
          altText: z.string().nullable(),
          mediaItemUrl: z.string().nullable(),
          mediaDetails: z
            .object({
              height: z.number().nullable(),
              width: z.number().nullable(),
            })
            .nullable(),
        })
        .nullable(),
      firstSlideTitle: z.string().nullable(),
      minimumNumberOfUsers: z.number().nullable(),
      firstSlideTitleSize: z.string().nullable(),
      firstSlideText: z.string().nullable(),
      firstSlideCtaText: z.string().nullable(),
      firstSlideTimer: z.number().nullable(),
    })
    .nullable(),
  secondarySlides: z.object({
    slideTwoTitle: z.string().nullable(),
    slideThreeTitle: z.string().nullable(),
    secondarySlidesTitleSize: z.string().nullable(),
    repeatSecondarySlides: z.number().nullable(),
  }),
});

const momentsSchema = z.object({
  momentSettings: z.array(momentSchema).nullable(),
});

const gatedWorkshopSchema = z.object({
  workshopGatedContent: z
    .object({
      videoId: z.string().nullable(),
      isPlaylist: z.boolean().nullable(),
      summary: z.string().nullable(),
      zoomLink: z.string().nullable(),
      downloadableContent: z
        .array(
          z
            .object({
              downloadableFile: z.object({
                mediaItemUrl: z.string(),
                mimeType: z.string(),
                fileSize: z.number(),
                title: z.string(),
                id: z.string(),
              }),
            })
            .nullable()
        )
        .nullable(),
      modules: z
        .array(
          z.object({
            moduleName: z.string().nullable(),
            lessons: z
              .array(
                z.object({
                  lessonName: z.string().nullable(),
                  timestamp: z.string().nullable(),
                })
              )
              .nullable(),
          })
        )
        .nullable(),

      heading: z.string().nullable(),
      subheading: z.string().nullable(),
      bulletPoints: z
        .array(
          z.object({
            bulletPoint: z.string().nullable(),
          })
        )
        .nullable(),
      dataFallback: z.number().nullable(),
      completionLink: z
        .object({
          url: z.string().nullable(),
          target: z.string().nullable(),
          title: z.string().nullable(),
        })
        .nullable(),
      message: z.string().nullable(),
    })
    .and(momentsSchema)
    .nullable(),
});

const workshopSchema = baseWorkshopSchema.merge(gatedWorkshopSchema);

export type GatedWorkshop = z.infer<typeof workshopSchema>;
type MomentSettings = z.infer<typeof momentsSchema>;
export type MomentsType = MomentSettings["momentSettings"];
export type MomentType = z.infer<typeof momentSchema>;

export async function getWorkshopDetail({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query WorkshopQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID, asPreview: ${preview}) {
        ... on Workshop {
          databaseId
          title
          slug
          date
          workshopGatedContent {
            videoId
            isPlaylist
            summary
            zoomLink
            downloadableContent {
              downloadableFile {
                mediaItemUrl
                mimeType
                fileSize
                title
                id
              }
            }
            bulletPoints {
              bulletPoint
            }
            subheading
            heading
            modules {
              moduleName
              lessons {
                lessonName
                timestamp
              }
            }
            momentSettings {
              momentModuleName
              momentLessonName
              momentColorScheme
              firstSlide {
                firstSlideIllustration {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
                firstSlideTitle
                minimumNumberOfUsers
                firstSlideTitleSize
                firstSlideText
                firstSlideCtaText
                firstSlideTimer
              }
              secondarySlides {
                slideTwoTitle
                slideThreeTitle
                secondarySlidesTitleSize
                repeatSecondarySlides
              }
            }
            dataFallback
            completionLink {
              url
              target
              title
            }
            message
          }
          workshopPageContent {
            eventEndTime
            eventStartTime
            ${completionBadgeQuery}
          }
          contentDuration {
            timeToConsume
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
          topics {
            nodes {
              name
              slug
              topicId
            }
          }
          workshopAltTitle {
            altTitle
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          headerCtaContent{
            buttonDataId
          }
          marketingBanner {
            text
            headline
            featureImage {
              altText
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
            cta {
              target
              title
              url
            }
          }
        }
      }
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );
  const { contentNode, acfOptionsGlobalContent } = data;
  const workshop = workshopSchema.parse(contentNode);

  return {
    workshop,
    acfOptionsGlobalContent,
  };
}
