import debounce from "../../util/debounce";
import InfiniteHits from "./InfiniteHits";
import PlaceholderResults from "./PlaceholderResults";
import { useUI } from "@/context/UIContext";
import { track } from "@/lib/helpers/analytics";
import { useEffect } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch-hooks-web";

const Results = ({ placeholders, searchLinks }) => {
  const { results } = useInstantSearch();
  const search = useSearchBox();
  const { modal } = useUI();

  useEffect(() => {
    if (results.query.length > 2) {
      debounce(
        track("Library Searched", {
          query: results?.query?.toLowerCase(),
          method: modal.event,
          result_count: results.nbHits,
        }),
        1500
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.query]);

  const NoResults = () => (
    <>
      <div className="pb-5 mb-5 border-b-1 border-gray">
        <h4 className="heading__md">
          No results (yet!) for &quot;{results.query}&quot;
        </h4>
        <p className="mt-3 text__b2">
          We&apos;re working on finding the right match for this search. In the
          meantime, we recommend starting with a popular search or browsing by
          topic; we&apos;re confident that something will be help.
        </p>
      </div>
      <PlaceholderResults search={search} searchLinks={searchLinks} />
    </>
  );

  return (
    <div className="pb-10 mt-6">
      {results.query === "" && <PlaceholderResults search={search} />}
      {results.query !== "" && results.nbHits === 0 && <NoResults />}
      {results.query !== "" && results.nbHits > 0 && (
        <InfiniteHits
          classNames={{
            disabledLoadPrevious: "!hidden",
          }}
          placeholders={placeholders}
        />
      )}
    </div>
  );
};

export default Results;
