import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Analytics",
  items: [
    { name: "ExperimentTracking" },
  ],
});

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/ExperimentTracking")),
  {
    name: "ExperimentTracking",
    image: assembleTablerIconUrl("test-pipe"),
    inputs: [
      {
        name: "experimentName",
        type: "string",
      },
      {
        name: "experimentVariant",
        type: "string",
      },
    ],
  }
);
