// This request is only meant to be called server-side - getStaticProps getStaticProps etc.
import {
  COMMUNITY_API_URL,
  COMMUNITY_ID,
  COMMUNITY_API_KEY,
} from "@/lib/constants";

export async function getCirclePosts(spaceId, numPosts = 7) {
  try {
    const url = new URL(`${COMMUNITY_API_URL}/posts`);
    url.searchParams.append("community_id", COMMUNITY_ID);
    url.searchParams.append("space_id", spaceId);
    url.searchParams.append("per_page", numPosts);

    const headers = new Headers();
    headers.append("Authorization", `Token ${COMMUNITY_API_KEY}`);

    const options = {
      method: "GET",
      headers,
      redirect: "follow",
    };

    const res = await fetch(url, options);
    const data = await res.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
