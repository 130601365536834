import cn from "classnames";

const OrDivider = ({ bgColor = "bg-white", margin = "my-4" }) => (
  <div className={cn("relative flex items-center justify-center", margin)}>
    <div className="absolute w-full border-solid top-1/2 border-b-1 border-gray" />
    <span
      className={cn(
        "relative z-[2] inline-block px-3 py-1 text-center text-sm font-bold text-black/50",
        bgColor
      )}
    >
      or
    </span>
  </div>
);

export default OrDivider;
