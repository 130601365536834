import cn from "classnames";
import { ReactNode } from "react";

const Container = ({
  children,
  className = "",
  width = "",
}: {
  children: ReactNode;
  className?: string;
  width?: "narrow" | "box" | string;
}) => {
  return (
    <div
      className={cn(
        className,
        {
          "px-4 md:px-6 lg:px-8 xl:px-12 2xl:px-14 mx-auto max-w-full xl-min-container:w-[1248px]": width !== 'box',
          "!w-[690px] !px-0": width === "narrow",
        }
      )}
    >
      {children}
    </div>
  );
};

export default Container;
