import { useUser } from "@/context/UserContext";
import { BILLING_PORTAL_URL } from "@/lib/constants";
import { ROUTES } from "@/lib/routes";
import differenceInDays from "date-fns/differenceInDays";
import IntlMessageFormat from "intl-messageformat";

export const USER_ISSUES_TYPES = {
  ONBOARDING_INCOMPLETE: "onboarding_incomplete",
  SUBSCRIPTION_EXPIRING: "subscription_expiring",
};

const calcDaysLeft = (date) => {
  return differenceInDays(new Date(date), new Date());
};

const renderExpiryDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export function useIssue() {
  const { user, issues } = useUser();

  if (!issues.size) return null;
  const [first] = issues;

  const buildOnboardingMessage = () => {
    return "Don’t forget to finish your Good Inside onboarding quiz! Your answers help us improve your experience across the membership.";
  };
  const buildCCMessage = () => {
    return "The credit card on your account is expiring. Please update your payment information to continue using Good Inside.";
  };

  const buildExpiryMessage = () => {
    const { ends_at } = user?.subscription;
    const daysLeft = calcDaysLeft(ends_at);
    const dateExpiring = renderExpiryDate(ends_at);

    return new IntlMessageFormat(
      "Your membership is expiring in {count} days on {expiry_date}. Please renew your subscription to continue using Good Inside.",
      "en-US"
    ).format({ count: daysLeft, expiry_date: dateExpiring });
  };

  const userIssues = {
    cc_expiring: {
      messageBuilder: buildCCMessage,
      buttonHref: BILLING_PORTAL_URL,
      buttonLabel: "UPDATE PAYMENT INFO",
    },
    onboarding_incomplete: {
      messageBuilder: buildOnboardingMessage,
      buttonHref: ROUTES.SUCCESS,
      buttonLabel: "Complete Onboarding",
    },
    subscription_expiring: {
      messageBuilder: buildExpiryMessage,
      buttonHref: BILLING_PORTAL_URL,
      buttonLabel: "Renew Subscription",
    },
  };

  return {
    message: userIssues[first]?.messageBuilder(),
    buttonLabel: userIssues[first]?.buttonLabel,
    buttonHref: userIssues[first]?.buttonHref,
  };
}
