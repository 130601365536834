import { useUI } from "@/context/UIContext";
import { SOCIAL_LINKS_KEY } from "@/lib/constants";
import { getSocialLinks } from "@/lib/requests";
import { useQuery } from "@tanstack/react-query";

export function useSocialLinks() {
  const { setDisplayStatusBanner } = useUI();

  try {
    const socialLinkResponse = useQuery(
      ["getSocialLinks"],
      async () => {
        return await getSocialLinks();
      },
      {
        placeholderData: JSON.parse(
          window.localStorage.getItem(SOCIAL_LINKS_KEY)
        ),
        staleTime: 60 * 60 * 1000, // 60 minutes in milliseconds
        cacheTime: 60 * 60 * 1000, // 60 minutes in milliseconds
        retryDelay: 10 * 1000, // 10 seconds in milliseconds
      }
    );

    if (socialLinkResponse.data) {
      window.localStorage.setItem(
        SOCIAL_LINKS_KEY,
        JSON.stringify(socialLinkResponse.data)
      );
    }

    return socialLinkResponse.data;
  } catch (error) {
    setDisplayStatusBanner(true);
    return [];
  }
}
