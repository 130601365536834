import { useMobileMenu } from "@/context/MobileMenuContext";
import { MappedMenuItemWithChildren } from "@/types/menu";
import { ReactNode, useState } from "react";

export interface MobileMenuProps {
  items: MappedMenuItemWithChildren[];
  children: ReactNode;
}

export const useMobileMenuLogic = ({ items, children }: MobileMenuProps) => {
  const [showSubMenu, setShowSubMenu] = useState<boolean[]>(
    Array(items?.length).fill(false)
  );

  const { showMobileMenu, setShowMobileMenu, dispatch } = useMobileMenu();

  const handleOpen = (i: number) => {
    setShowSubMenu((prevState) => {
      const newState = [...prevState];
      newState[i] = true;
      return newState;
    });
  };
  const closeMainMenu = () => {
    dispatch({ type: "SHOW_OPEN" });
    setShowMobileMenu(false);
  };

  const handleClose = () => {
    setShowSubMenu((prevState) => prevState.map(() => false));
  };

  return {
    items,
    children,
    showSubMenu,
    showMobileMenu,
    handleOpen,
    handleClose,
    closeMainMenu,
  };
};

export type MobileMenuComponentProps = ReturnType<typeof useMobileMenuLogic>;
