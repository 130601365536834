import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getTypes() {
  const types = [],
    data = await serverSideCmsFetch(
      `query Types {
      contentTypes {
        nodes {
          labels {
            singularName
          }
        }
      }
    }`,
      {}
    );

  data.contentTypes.nodes.map((node) => {
    if (
      node.labels.singularName !== "Post" &&
      node.labels.singularName !== "Page" &&
      node.labels.singularName !== "Media"
    ) {
      types.push({
        name: node.labels.singularName,
      });
    }
  });

  return types;
}
