import {
  LibraryDropdownItemProps,
  useLibraryDropdownItemLogic,
} from "./useLibraryDropdownItemLogic";
import Badge from "@/components/Badge";
import Image from "@/components/Fallback/OptimizedImageWithFallback";
import NextLink from "@/components/NextLink";
import cn from "classnames";

export const LibraryDropdownItem = (props: LibraryDropdownItemProps) => {
  const {
    href,
    label,
    newItem,
    textOnly,
    iconUrl,
    isMouseOver,
    isCurrentlySelected,
    onClick,
    handleMouseOver,
    handleMouseLeave,
  } = useLibraryDropdownItemLogic(props);

  return (
    <li className={"w-full m-0 list-none group"}>
      <NextLink
        href={href}
        className={cn({
          "flex items-center justify-between px-3 py-3 text-xs font-semibold leading-4 text-black transition-colors rounded-2xl bg-light-gray group-hover:bg-yellow":
            !textOnly,
          "visited: text-[#454444]": textOnly,
        })}
        onClick={onClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <span
          className={cn("text__eb3", {
            "text-sm font-mono text-[#454444]": textOnly,
            "underline decoration-yellow decoration-4 underline-offset-8":
              textOnly && (isCurrentlySelected || isMouseOver),
            "hover: opacity-60": textOnly && isMouseOver,
          })}
        >
          {newItem && (
            <Badge className="px-2 py-1 mr-3 font-medium rounded-xl group-hover:bg-white group-hover:transition-colors">
              NEW
            </Badge>
          )}
          {label}
        </span>
        {iconUrl && (
          <div className="relative w-16 h-10">
            <Image
              src={iconUrl}
              alt={`${label} icon`}
              fill
              style={{ objectFit: "contain" }}
            />
          </div>
        )}
      </NextLink>
    </li>
  );
};
