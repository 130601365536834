import { MobileMenuComponentProps } from "@/components/Menu/MobileMenu/useMobileMenuLogic";
import { MappedMenuItem, MappedMenuItemWithChildren } from "@/types/menu";

export interface MobileMenuItemProps {
  index: number;
  item: MappedMenuItemWithChildren;
  showSubMenu: MobileMenuComponentProps["showMobileMenu"];
  handleClose: MobileMenuComponentProps["handleClose"];
  handleOpen: MobileMenuComponentProps["handleOpen"];
  closeMainMenu: MobileMenuComponentProps["closeMainMenu"];
}
export const useMobileMenuItemLogic = ({
  item,
  index,
  showSubMenu,
  handleOpen,
  handleClose,
  closeMainMenu,
}: MobileMenuItemProps) => {
  const suffixIconForSingleNavItem =
    item.title === "gift"
      ? ""
      : item.url?.includes("https://")
      ? "arrowRight"
      : "";
  const isActiveMenuItem = showSubMenu[index];
  const isBookNavItem = item.url?.startsWith("/book");
  const isBookNavItemActive = isBookNavItem && isActiveMenuItem;

  const handleParentNavItemClick = () => {
    if (isActiveMenuItem) {
      handleClose();
      return;
    }
    handleOpen(index);
  };

  const handleChildNavItemClick = () => {
    handleClose();
    closeMainMenu();
  };

  return {
    item,
    index,
    isBookNavItem,
    isBookNavItemActive,
    suffixIconForSingleNavItem,
    showSubMenu,
    handleClose,
    closeMainMenu,
    handleChildNavItemClick,
    handleParentNavItemClick,
  };
};

export type MobileMenuItemComponentProps = ReturnType<
  typeof useMobileMenuItemLogic
>;
