import { MappedMenuItemWithChildren } from "@/types/menu";
import { useRef } from "react";

export interface LibraryDropdownProps {
  isTextItemDropdown?: boolean;
  items: MappedMenuItemWithChildren[];
  close?: VoidFunction;
}

export const useLibraryDropdownLogic = ({
  items,
  isTextItemDropdown = false,
  close,
}: LibraryDropdownProps) => {
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();

  const handleMouseLeave = () => {
    if (!close) return;
    // if (hoverTimeoutRef.current) clearTimeout(hoverTimeoutRef.current);
    hoverTimeoutRef.current = setTimeout(() => {
      close();
    }, 500);
  };

  return {
    items,
    isTextItemDropdown,
    close,
    handleMouseLeave,
  };
};

export type LibraryDropdownComponentProps = ReturnType<
  typeof useLibraryDropdownLogic
>;
