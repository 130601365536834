import Image from "@/components/Fallback/OptimizedImageWithFallback";
import { useUI } from "@/context/UIContext";
import { track } from "@/lib/helpers/analytics";
import { decodeHtml } from "@/lib/helpers/decodeHtml";
import cn from "classnames";
import Link from "next/link";
import React, { useRef, useEffect } from "react";
import {
  useInfiniteHits,
  useInstantSearch,
} from "react-instantsearch-hooks-web";

const InfiniteHits = ({ placeholders }) => {
  const { hits, isLastPage, showMore, sendEvent } = useInfiniteHits();
  const { results } = useInstantSearch();
  const sentinelRef = useRef(null);
  const { modal, dispatchModal } = useUI();

  useEffect(() => {
    const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    let supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    // modern Chrome requires { passive: false } when adding event
    const wheelOpt = supportsPassive ? { passive: false } : false;
    const wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    function enableScroll() {
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    function disableScroll() {
      window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    if (modal.type === "search") {
      if (modal.open && results.nbHits > 0 && results.nbHits < 3) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
    return () => {
      enableScroll();
    };
  }, [results.nbHits, modal]);

  const trackHit = (hit, index) => {
    track("Search Result Clicked", {
      query: results?.query?.toLowerCase(),
      result_title: hit.title,
      result_type: hit.type,
      result_topic_ids: hit.topics?.map(({ id }) => id),
      result_topics: hit.topics?.map(({ name }) => name),
      position: index,
    });
  };

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const handleClick = (e, i, hit) => {
    trackHit(hit, i + 1);
    sendEvent("conversion", hit, "Search Result Clicked");
    if (!e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
      dispatchModal({
        type: "SET_OPEN",
        value: false,
      });
    }
  };

  return (
    <div className="ais-hits">
      <h4 className="mb-5 lg:mb-7 heading__md">
        Showing {hits.length} of {results.nbHits} Results for &quot;
        {results.query}&quot;
      </h4>
      {hits.map((hit, i) => (
        <Link
          passHref
          href={hit.url}
          key={hit.objectID}
          onClick={(e) => handleClick(e, i, hit)}
          className={cn(
            "flex gap-3 transition-opacity hoverable:hover:opacity-80 duration-300",
            {
              "border-b-1 pb-3 lg:pb-4 mb-4 border-gray": i !== hits.length - 1,
            }
          )}
        >
          <figure className="relative flex-shrink-0 overflow-hidden rounded-lg w-22 h-22 bg-silver lg:w-32 lg:h-32">
            <Image
              src={
                hit.imageURL
                  ? hit.imageURL
                  : placeholders[`${hit.type}Placeholder`].mediaItemUrl
              }
              alt={hit.title}
              fill
              className="absolute top-0 left-0 object-cover w-full h-full"
            />
          </figure>
          <div className="flex flex-col w-full lg:py-3">
            <h5
              className="heading__sm"
              dangerouslySetInnerHTML={{
                __html: decodeHtml(
                  hit._highlightResult?.title?.value ?? hit.title
                ),
              }}
            />
            <p
              className="mt-2 mb-3 text__b2 lg:mb-0"
              dangerouslySetInnerHTML={{
                __html: decodeHtml(
                  hit._highlightResult?.excerpt?.value ?? hit.excerpt
                ),
              }}
            />
            <div className="mt-auto text__cta ">
              <span className="text-dark-green">{hit.type}</span>
              {hit.timeToConsume && (
                <>
                  &nbsp;|&nbsp;<span>{hit.timeToConsume} MINS</span>
                </>
              )}
            </div>
          </div>
        </Link>
      ))}
      <div ref={sentinelRef} className="w-full h-1" />
    </div>
  );
};

export default InfiniteHits;
