import Icon from "@/components/Icon";
import cn from "classnames";
import { forwardRef } from "react";

const TestimonialModal = ({ modal, close }, ref) => {
  return (
    <>
      <section
        ref={ref}
        className="relative h-fit w-fit bg-white sm:max-w-[90vw] md:max-w-[80vw] lg:min-h-[20em] lg:min-w-[50em] lg:max-w-[50em] xl:min-w-[55em] xl:max-w-[55em]"
      >
        <div
          className={cn(
            "absolute top-6 right-2 z-50 flex h-16 w-full items-center justify-end bg-white px-5 sm:top-0 sm:left-0"
          )}
        >
          <button
            className="text-2xl transition-opacity hover:opacity-80"
            onClick={close}
          >
            <Icon name="close" />
          </button>
        </div>
        <div
          className={cn(
            "h-fit w-screen overflow-auto bg-white pt-12 sm:max-w-[90vw] sm:pt-0 md:max-w-[80vw] lg:max-h-[100vh] lg:max-w-full"
          )}
        >
          {modal.content}
        </div>
      </section>
    </>
  );
};

export default forwardRef(TestimonialModal);
