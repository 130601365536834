export const builderFullHeadAssembler = (builderData) => {
  const builderTitle =
    builderData?.data?.title + " - Good Inside" || "Good Inside";

  const builderDescription =
    builderData?.data?.description || builderData?.data?.shortExcerpt;

  const builderSlug = builderData?.data?.url || "/";

  const lastUpdated = builderData?.lastUpdated
    ? new Date(builderData?.lastUpdated || "").toISOString()
    : new Date().toISOString();

  // todo update og:image section to reference the builder featuredImage

  const builderFullHead = `<meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="${builderTitle}" />
    <meta property="og:url" content="https://www.goodinside.com${builderSlug}" />
    <meta property="og:site_name" content="Good Inside" />
    <meta property="og:description" content="${builderDescription}">
    <meta property="article:publisher" content="https://www.facebook.com/drbeckyatgoodinside" />
    <meta property="article:modified_time" content="${lastUpdated}" />
    <meta property="og:image" content="https://media.goodinside.com/wp-content/uploads/2022/03/22201017/GI-Open-Graph.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
    <meta property="og:image:type" content="image/jpeg">
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json" class="yoast-schema-graph">
      {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebPage",
            "@id": "https://www.goodinside.com${builderSlug}",
            "url": "https://www.goodinside.com${builderSlug}",
            "name": "${builderTitle}",
            "isPartOf": { "@id": "https://www.goodinside.com/#website" },
            "datePublished": "2022-05-19T21:10:54+00:00",
            "dateModified": "2023-01-18T03:01:34+00:00",
            "description": "Parenting strategies robust enough to tackle your biggest challenges, but simple enough to remember. #1 New York Times Bestseller",
            "breadcrumb": { "@id": "https://www.goodinside.com${builderSlug}#breadcrumb" },
            "inLanguage": "en-US",
            "potentialAction": [
              {
                "@type": "ReadAction",
                "target": ["https://www.goodinside.com${builderSlug}"]
              }
            ]
          },
          {
            "@type": "BreadcrumbList",
            "@id": "https://www.goodinside.com${builderSlug}#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "name": "Home",
                "item": "https://www.goodinside.com/"
              },
              { "@type": "ListItem", "position": "2", "name": "${builderTitle}" }
            ]
          },
          {
            "@type": "WebSite",
            "@id": "https://www.goodinside.com/#website",
            "url": "https://www.goodinside.com/",
            "name": "Good Inside",
            "description": "I’m Dr. Becky, and this is Good Inside.",
            "publisher": { "@id": "https://www.goodinside.com/#organization" },
            "potentialAction": [
              {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://www.goodinside.com/?s={search_term_string}"
                },
                "query-input": "required name=search_term_string"
              }
            ],
            "inLanguage": "en-US"
          },
          {
            "@type": "Organization",
            "@id": "https://www.goodinside.com/#organization",
            "name": "Good Inside",
            "url": "https://www.goodinside.com/",
            "logo": {
              "@type": "ImageObject",
              "inLanguage": "en-US",
              "@id": "https://www.goodinside.com/#/schema/logo/image/",
              "url": "https://media.goodinside.com/wp-content/uploads/2021/10/16170113/screenshot.png",
              "contentUrl": "https://media.goodinside.com/wp-content/uploads/2021/10/16170113/screenshot.png",
              "width": "1200",
              "height": "900",
              "caption": "Good Inside"
            },
            "image": { "@id": "https://www.goodinside.com/#/schema/logo/image/" },
            "sameAs": [
              "https://www.instagram.com/drbeckyatgoodinside/",
              "https://www.linkedin.com/company/goodinside",
              "https://www.facebook.com/drbeckyatgoodinside"
            ]
          }
        ]
      }    
    </script>`;

  return builderFullHead;
};