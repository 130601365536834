// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "development",
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV || "development",
  // These errors can safely be ignored
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    // Brightcove timeout errors
    "TypeError: null is not an object (evaluating 'this.el_[e]')",
    "TypeError: this.el_ is null",
  ],
  tracesSampleRate: process.env.NEXT_PUBLIC_ERROR_TRACKING_SAMPLE_RATE || 1.0,
});
