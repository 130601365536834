import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getLengths() {
  const data = await serverSideCmsFetch(
    `query Lengths {
      lengths(first: 100) {
        nodes {
          lengthId
          name
        }
      }
    }`,
    {}
  );
  return data.lengths.nodes;
}
