import { MobileMenuComponentProps } from "@/components/Menu/MobileMenu/useMobileMenuLogic";
import { useMobileMenu } from "@/context/MobileMenuContext";
import { MappedMenuItemWithChildren } from "@/types/menu";

export interface MobileSubMenuProps {
  index: number;
  showMenu: MobileMenuComponentProps["showSubMenu"];
  setShowMenu: VoidFunction;
  items: MappedMenuItemWithChildren[];
}

export const useMobileSubMenuLogic = ({
  items,
  index,
  showMenu,
  setShowMenu,
}: MobileSubMenuProps) => {
  const { dispatch, setShowMobileMenu } = useMobileMenu();

  const show = showMenu[index];

  const closeMobileMenus = () => {
    dispatch({ type: "SHOW_OPEN" });
    setShowMobileMenu(false);
  };

  const handleDropdownItemClick = () => {
    setShowMenu();
    closeMobileMenus();
  };

  return { show, items, index, showMenu, setShowMenu, handleDropdownItemClick };
};

export type MobileSubMenuComponentProps = ReturnType<
  typeof useMobileSubMenuLogic
>;
