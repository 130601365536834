import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";

export async function getMembershipModal() {
  const data = await clientSideCmsFetch(
    `
    query getMembershipModal {
        acfOptionsGlobalContent {
            optionsGlobalContent {
            membershipModalContent {
                image {
                mediaItemUrl
                mediaDetails {
                    width
                    height
                }
                altText
                }
                title
                subtitle
                perks {
                perk
                }
            }
            }
        }
    }
    `,
    {}
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent
    .membershipModalContent;
}
