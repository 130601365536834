import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getCheckoutBenefits() {
  const data = await serverSideCmsFetch(
    `
    query CheckoutBenefitQuery{
      acfOptionsGlobalContent {
        optionsGlobalContent {
          checkoutBenefits {
            benefits {
              benefit
            }
          }
        }
      }
    }
    `
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent.checkoutBenefits
    .benefits;
}
