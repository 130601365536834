import { useState, useEffect, useCallback } from "react";

const useMediaQuery = (query) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`${query}`);
    if (media.matches) {
      setTargetReached(true);
    }
    if (typeof media.addEventListener === "function") {
      media.addEventListener("change", updateTarget);
      return () => media.removeEventListener("change", updateTarget);
    } else {
      media.addListener(updateTarget);
      return () => media.removeListener(updateTarget);
    }
  }, [query, updateTarget]);

  return targetReached;
};

export default useMediaQuery;

// Define Global breakpoints here.
export const mobileQuery = "(max-width: 625px)";
export const desktopQuery = "(min-width: 625px)";
export const desktopShortQuery = "(max-height: 800px)";
export const desktopQueryMd = "(min-width: 768px)";
export const desktopQueryLg = "(min-width: 1024px)";
export const desktopQueryXl = "(min-width: 1280px)";
