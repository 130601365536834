import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPromoCardPerks() {
  const data = await serverSideCmsFetch(
    `
    query PerksQuery{
      acfOptionsGlobalContent {
        optionsGlobalContent {
          workshopPerks {
            perks {
              perk
            }
          }
        }
      }
    }
    `
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent.workshopPerks.perks;
}
