import { DesktopMenu } from "./DesktopMenu";
import { MenuButton } from "./MenuButton";
import { MobileMenu } from "./MobileMenu";
import { useMenuLogic, MenuProps } from "./useMenuLogic";
import ActionButton from "@/components/ActionButton";
import OnboardingButton from "@/components/OnboardingButton";
import SkeletonMenuItem from "@/components/Skeleton/MenuItem";
import { CTA_TEXT } from "@/lib/constants";

const Menu = (props: MenuProps) => {
  const {
    items,
    mobileSubMenu,
    isUserSubscribed,
    innerWidth,
    buttonDataId,
    socialLinks,
    workshopNav,
    authVerified,
    handleMenuChange,
    menuToggleState,
    closeMobileMenus,
  } = useMenuLogic(props);

  return (
    <>
      <nav className={`flex h-17 md:w-full items-center`}>
        {!innerWidth ? (
          <></>
        ) : (
          <>
            {innerWidth < 1024 ? (
              <>
                <MenuButton
                  buttonHandler={handleMenuChange}
                  iconName={menuToggleState.icon}
                />
                <MobileMenu items={items}>
                  {!isUserSubscribed && (
                    <div
                      onClick={closeMobileMenus}
                      className="flex flex-col items-center justify-center w-full gap-4 pt-8 -mb-6 md:mb-0"
                    >
                      <OnboardingButton
                        className="justify-center w-full"
                        dataId={buttonDataId || undefined}
                      >
                        {CTA_TEXT}
                      </OnboardingButton>
                    </div>
                  )}
                  {!!mobileSubMenu?.length && (
                    <ul className="flex flex-col p-0 pt-2 m-0">
                      {mobileSubMenu.map((item, k) => (
                        <li
                          key={`${item}-${k}`}
                          className="pt-5 list-none text__eb3"
                        >
                          <a
                            href={item.url || undefined}
                            onClick={closeMobileMenus}
                          >
                            {item.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}

                  {!!socialLinks && (
                    <ul className="flex flex-row-reverse justify-end gap-4 p-0 py-8 m-0">
                      {Object.entries(socialLinks).map(([key, value], i) => (
                        <li key={`${key}-${i}`} className="list-none">
                          <ActionButton
                            href={value}
                            icon={key}
                            iconClassName={"scale-75"}
                            target="_blank"
                            rel="noreferrer"
                            onClick={closeMobileMenus}
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </MobileMenu>
              </>
            ) : (
              <>
                {authVerified ? (
                  <DesktopMenu items={items} workshopNav={workshopNav} />
                ) : (
                  <>
                    <SkeletonMenuItem />
                    <SkeletonMenuItem />
                    <SkeletonMenuItem />
                  </>
                )}
              </>
            )}
          </>
        )}
      </nav>
    </>
  );
};
export default Menu;
