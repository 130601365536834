import {
  MobileSubMenuProps,
  useMobileSubMenuLogic,
} from "./useMobileSubMenuLogic";
import Button from "@/components/Button";
import { LibraryDropdownItem } from "@/components/Menu/LibraryDropdownItem";
import Slideover from "@/components/Slideover";

export const MobileSubMenu = (props: MobileSubMenuProps) => {
  const { show, items, setShowMenu, handleDropdownItemClick } =
    useMobileSubMenuLogic(props);
  return (
    <Slideover
      show={show}
      setShow={setShowMenu}
      headerLabel="Library"
      fullHeight={true}
      closeButton={
        <Button
          prefixIcon="caretLeft"
          className="h-[4.254rem] w-[2.254rem] lg:ml-[0.54rem] lg:hidden lg:pt-[0.125rem]"
          variant="text"
          onClick={setShowMenu}
        />
      }
    >
      <nav>
        <ul className="grid w-full grid-cols-1 gap-4 p-0 m-0 my-4 transition-all bg-white sm:grid-cols-2 md:grid-cols-2 top-full">
          {items.map((sub, i) => (
            <LibraryDropdownItem
              key={`${i}-${sub.label}`}
              href={sub.url}
              label={sub.label}
              newItem={sub.new}
              iconUrl={sub.mediaItemUrl}
              onClick={handleDropdownItemClick}
            />
          ))}
        </ul>
      </nav>
    </Slideover>
  );
};
