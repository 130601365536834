import { Component } from "react";
import LayoutError from "@/components/LayoutError";

class AppBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <LayoutError
          heading="Oops, there was an error!"
          message={
            <>
              Refresh the page and try again or visit our{" "}
              <a className="underline" href="https://support.goodinside.com">
                support site
              </a>
              .
            </>
          }
        ></LayoutError>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default AppBoundary;
