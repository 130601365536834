import {
  placeholderQuery,
  searchLinksQuery,
} from "@/lib/fragments/graphQLqueries";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPodcast({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query PodcastQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID, asPreview: ${preview}) {
        ... on Podcast {
          databaseId
          title
          slug
          date
          excerpt
          status
          contentDuration {
            timeToConsume
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
          topics {
              nodes {
                name
                slug
                topicCustomData {
                  circleSpaceUrl
                }
                topicId
              }
          }
          podcastContent {
            podcastEmbed
            podcastHighlights
            podcastTranscript
            visibility
          }
        }
      }
      acfOptions3rdPartyLinks {
        socialChannels {
          podcastSubscribeUrls {
            apple {
              url
              title
            }
            spotify {
              url
              title
            }
          }
          socialLinks {
            facebook
            instagram
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          headerCtaContent{
            buttonDataId
          }
          relatedContentCarouselHeadings {
            heading
            subheading
          }
          eventLogo {
            desktop {
              altText
              mediaItemUrl
            }
            mobile {
              altText
              mediaItemUrl
            }
          }
          marketingBanner {
            text
            headline
            featureImage {
              altText
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
            cta {
              target
              title
              url
            }
          }
        }
      }
      ${placeholderQuery}
      ${searchLinksQuery}
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return data;
}
