import { DesktopMenuComponentProps } from "@/components/Menu/DesktopMenu/useDesktopMenuLogic";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import { MappedMenuItemWithChildren } from "@/types/menu";
import { useRouter } from "next/router";
import { usePopper } from "react-popper";

export interface DesktopMenuItemProps {
  index: number;
  itemsCount: number;
  item: MappedMenuItemWithChildren;
  openIndex: DesktopMenuComponentProps["open"];
  menuRef: DesktopMenuComponentProps["menuRef"];
  workshopNav: DesktopMenuComponentProps["workshopNav"];
  workshopView: DesktopMenuComponentProps["workshopView"];
  buttonRef: DesktopMenuComponentProps["buttonRef"];
  popoverRef: DesktopMenuComponentProps["popoverRef"];
  setButtonRef: DesktopMenuComponentProps["setButtonRef"];
  setPopoverRef: DesktopMenuComponentProps["setPopoverRef"];
  toggleDropdown: DesktopMenuComponentProps["toggleDropdown"];
}

const modifiers = [
  { name: "offset", options: { offset: [0, 19] } },
  { name: "hide", enabled: false },
];

export const useDesktopMenuItemLogic = ({
  item,
  index,
  menuRef,
  openIndex,
  itemsCount,
  workshopNav,
  workshopView,
  buttonRef,
  popoverRef,
  setButtonRef,
  setPopoverRef,
  toggleDropdown,
}: DesktopMenuItemProps) => {
  const { user } = useUser();
  const router = useRouter();
  const { focusHeader, modal } = useUI();

  const isUserLoggedIn = !!user;
  const isUserSubscribed = !!user?.subscription_active;

  const childrenUrls: string[] =
    item.children?.map((item) => item.url || "").filter(Boolean) || [];
  const isActive =
    router.asPath === item.url ||
    childrenUrls.some((url) => router.asPath.startsWith(url)) ||
    (item.url === "/library/" && router.asPath.includes("/topic/"));

  const { styles: popoverStyles, attributes: popoverAttributes } = usePopper(
    buttonRef,
    popoverRef,
    {
      placement: "bottom-start",
      modifiers: modifiers,
    }
  );

  const isBookNavItem = item.url?.startsWith("/book");

  const handleButtonInteraction = () => {
    toggleDropdown(index, !!item?.children?.length);
  };

  const suffixIcon = !isBookNavItem
    ? openIndex === index
      ? "caretUp"
      : "caretDown"
    : undefined;

  return {
    item,
    index,
    isActive,
    isUserLoggedIn,
    isUserSubscribed,
    modal,
    focusHeader,
    suffixIcon,
    isBookNavItem,
    handleButtonInteraction,
    // from parent
    menuRef,
    openIndex,
    itemsCount,
    workshopNav,
    workshopView,
    popoverStyles,
    popoverAttributes,
    setButtonRef,
    setPopoverRef,
  };
};

export type DesktopMenuItemComponentProps = ReturnType<
  typeof useDesktopMenuItemLogic
>;
