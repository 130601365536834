import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";
import placeholderQuery from "@/lib/fragments/graphQLqueries/placeholderQuery";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

/**
 * This request is unique in that it is used both server-side and client-side
 * each method requires a different URL therefore we need to use either clientSideCmsFetch or
 * serverSideCmsFetch. Because we do not want to expose the URL to the client by accident, the default behaviour is using clientSideCmsFetch. To use this request server-side (getServerSideProps, getStaticProps) you will need to set isServer = true.
 *
 * @param {object} filters
 * @param {array} bookmarks
 * @param {array} topics
 * @param {boolean} isServer
 * @returns {object} filtered CMS data
 */
export async function getFilteredContent({
  filters,
  bookmarks,
  topics,
  isServer = false,
}) {
  let data;

  const variables = {
    topics: topics,
    lengths: filters.lengths,
    types: filters.types,
    bookmarked: bookmarks,
  };

  const query = `
  query FilterContent($topics: [ID], $lengths: [ID], $types: [String], $bookmarked: [ID]) {
    filterContent(topics: $topics, lengths: $lengths, types: $types, bookmarked: $bookmarked) {
      id
      title
      image {
        url
        alt
      }
      slug
      length
      topics {
        name
        id
      }
      type
      excerpt
      date
      content
      contentDuration {
        timeToConsume
      }
    }
    ${placeholderQuery}
  }`;

  if (isServer) {
    data = await serverSideCmsFetch(query, { variables });
  } else {
    data = await clientSideCmsFetch(query, variables);
  }

  return data;
}
