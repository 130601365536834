import {
  ArticleTypeFields,
  PodcastTypeFields,
  ScriptTypeFields,
  VideoTypeFields,
  WorkshopTypeFields,
} from "@/lib/fragments";
import {
  placeholderQuery,
  abTestQuery,
  topicWorkshopQuery,
  tolstoyQuery,
  filterByAgeQuery
} from "@/lib/fragments/graphQLqueries";
import { buildLatestTopics, buildUpcomingEvents } from "@/lib/queryHelpers";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getTopic({ slug }) {
  if (!slug) return;

  const data = await serverSideCmsFetch(
    `
    query TopicQuery($id: ID!) {
      topic(id: $id, idType: SLUG) {
        seo {
          title
          fullHead
          metaRobotsNofollow
          metaRobotsNoindex
        }
        customPageSections {
          flexibleSections {
            ... on Topic_Custompagesections_FlexibleSections_CustomCarousel {
              title
              fieldGroupName
              contentType
              hidden
              ${abTestQuery}
              ${filterByAgeQuery}
              customList {
                chooseContent {
                  ... on Article {
                    id: articleId
                    ...ArticleTypeFields
                  }
                  ... on Video {
                    id: videoId
                    ...VideoTypeFields
                  }
                  ... on Podcast {
                    id: podcastId
                    ...PodcastTypeFields
                  }
                  ... on Script {
                    id: scriptId
                    ...ScriptTypeFields
                  }
                  ... on Workshop {
                    id: workshopId
                    ...WorkshopTypeFields
                  }
                }
              }
              latestFromTopic {
                chooseTopic {
                  articles(first: 10) {
                    nodes {
                      id: articleId
                      ...ArticleTypeFields
                    }
                  }
                  podcasts(first: 10) {
                    nodes {
                      id: podcastId
                      ...PodcastTypeFields
                    }
                  }
                  videos(first: 10) {
                    nodes {
                      id: videoId
                      ...VideoTypeFields
                    }
                  }
                  scripts(first: 10) {
                    nodes {
                      id: scriptId
                      ...ScriptTypeFields
                    }
                  }
                  workshops(first: 10) {
                    nodes {
                      id: workshopId
                      ...WorkshopTypeFields
                    }
                  }
                }
              }
              subtitle
            }
            ... on Topic_Custompagesections_FlexibleSections_PersonalizedCarousel {
              title
              subtitle
              fieldGroupName
              ${abTestQuery}
              ${filterByAgeQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_WorkshopListing {
              title
              subtitle
              fieldGroupName
              contentType
              viewAllLink
              ${abTestQuery}
              ${filterByAgeQuery}
              customList {
                chooseContent {
                  ... on Workshop {
                    id: workshopId
                    ...WorkshopTypeFields
                  }
                }
              }
              latestFromTopic {
                chooseTopic {
                  workshops(first: 15) {
                    nodes {
                      id: workshopId
                      ...WorkshopTypeFields
                    }
                  }
                }
              }
            }
            ... on Topic_Custompagesections_FlexibleSections_Showreel {
              fieldGroupName
              ${abTestQuery}
              showreelText
              showreelTitle
            }
            ... on Topic_Custompagesections_FlexibleSections_ThumbnailCards {
              fieldGroupName
              thumbnailCardsTitle
              ${abTestQuery}
              link {
                title
                url
              }
              thumbnailCardsCards {
                image {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
                title
                text
              }
            }
            ... on Topic_Custompagesections_FlexibleSections_RecentlyViewedCarousel {
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_PurchasedWorkshops {
              fieldGroupName
              heading
              subheading
              viewAllLink
              ${abTestQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_PodcastCarousel {
              fieldGroupName
              testimonialsHeadline
              ${abTestQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_UpcomingEventsListing {
              fieldGroupName
              preview
              ${abTestQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_MarketingBanner {
              fieldGroupName
              headline
              text
              ${abTestQuery}
              cta {
                title
                url
                target
              }
              featureImage {
                altText
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              override
            }
            ... on Topic_Custompagesections_FlexibleSections_TopicTiles {
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
            }
            ... on Topic_Custompagesections_FlexibleSections_CircleCarousel {
              fieldGroupName
              heading
              spaceId
              ${abTestQuery}
              link {
                title
                url
              }
            }
            ... on Topic_Custompagesections_FlexibleSections_PersonalizedCircleCarousel {
              fieldGroupName
              ${abTestQuery}
              ${filterByAgeQuery}
              heading
              topicSearchTerms {
                onboardingTopic
                spaceId
                searchTerm
                communityUrl
              }
              linkTitle
            }
            ... on Topic_Custompagesections_FlexibleSections_PersonalizedWorkshop {
              fieldGroupName
              ${abTestQuery}
              ${filterByAgeQuery}
              heading
              subheading
            }
            ... on Topic_Custompagesections_FlexibleSections_TolstoyEmbed {
              ${abTestQuery}
              ${tolstoyQuery}
            }
          }
        }
        description
        name
        slug
        topicCustomData {
          articleFeatureImageFallback {
            mediaItemId
            mediaItemUrl
          }
          featureIllustration {
            mediaItemId
            mediaItemUrl
            mediaDetails {
              width
              height
            }
          }
        }
      }
      events(first:50) {
        nodes {
          eventId: databaseId
          eventContent {
            eventDateTime
            eventDetailLink
            eventSummary
            eventType
          }
          featuredImage {
            node {
              mediaItemUrl
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          title
        }
      }
      ${placeholderQuery}
      ${topicWorkshopQuery}
      acfOptionsGlobalContent {
        optionsGlobalContent {
          upcomingLiveEventsContent {
            subtitle
            title
            link {
              title
              url
            }
          }
          marketingBanner {
            headline
            text
            cta {
              title
              url
              target
            }
            featureImage {
              altText
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
        }
      }
      acfOptionsOnboardingFlow {
        optionsOnboardingFlow {
          topic {
            choices {
              displayName
              choice {
                name
              }
              featuredWorkshop {
                ...WorkshopTypeFields
              }
            }
          }
        }
      }
    }
    ${ArticleTypeFields}
    ${ScriptTypeFields}
    ${VideoTypeFields}
    ${PodcastTypeFields}
    ${WorkshopTypeFields}
    `,
    { variables: { id: slug } }
  );

  /**
   * Get the "latest from topic" data, organize into a single array, sort by date, and push back to main data object.
   */
  const flexibleSections = data.topic.customPageSections.flexibleSections;

  for (const section of flexibleSections) {
    if (section.contentType === "latestFromTopic") {
      section.latestFromTopic.postData = [buildLatestTopics(section)];
    } else if (
      section.fieldGroupName ===
      "Topic_Custompagesections_FlexibleSections_PersonalizedWorkshop"
    ) {
      section.choices =
        data.acfOptionsOnboardingFlow?.optionsOnboardingFlow?.topic?.choices;
    }
  }

  /**
   * Sort Events by next upcoming, trim events that are past
   */
  const eventPosts = data.events.nodes;

  if (eventPosts) {
    data.events.postData = [buildUpcomingEvents(eventPosts)];
  }

  return data;
}
