import { useMobileMenu } from "@/context/MobileMenuContext";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import { useMenu } from "@/hooks/useMenu";
import { useSocialLinks } from "@/hooks/useSocialLinks";
import {
  MappedMenuItemsDictionary,
  MappedMenuItemWithChildren,
} from "@/types/menu";
import { useMemo } from "react";

export interface MenuProps {
  workshopNav?: boolean;
  buttonDataId?: string | null;
}

export const useMenuLogic = ({
  workshopNav = false,
  buttonDataId = null,
}: MenuProps) => {
  const menus = useMenu() as MappedMenuItemsDictionary;
  const { innerWidth } = useUI();
  const socialLinks = useSocialLinks();
  const { user, userSubscriptionDate, authVerified } = useUser();
  const { menuToggleState, handleMenuChange, setShowMobileMenu, dispatch } =
    useMobileMenu();

  const closeMobileMenus = () => {
    dispatch({ type: "SHOW_OPEN" });
    setShowMobileMenu(false);
  };

  const userPurchasesCount = user?.purchases?.length;
  const isUserSubscribed = !!user?.subscription_active;
  const mobileSubMenu = menus?.["Mobile Footer"] || [];

  const items: MappedMenuItemWithChildren[] = useMemo(() => {
    if (!menus) return [];

    if (isUserSubscribed) {
      const menuItems = menus["Primary"]?.map((item) => {
        if (item.label !== "Library") return item;
        const filteredChildren = item.children?.filter(
          (child) =>
            child.subscriptionDateThreshold == null ||
            new Date(
              child.subscriptionDateThreshold
                .substring(0, 10)
                .replaceAll("-", "/")
            ).getTime() >= userSubscriptionDate
        );
        item.children = filteredChildren || [];
        return item;
      });

      return menuItems;
    }

    if (!!userPurchasesCount) return menus["Purchaser"] || [];

    return menus["Guest"] || [];
  }, [menus, isUserSubscribed, userPurchasesCount, userSubscriptionDate]);

  return {
    items,
    mobileSubMenu,
    isUserSubscribed,
    innerWidth,
    socialLinks,
    workshopNav,
    buttonDataId,
    authVerified,
    menuToggleState,
    handleMenuChange,
    closeMobileMenus,
  };
};

export type MenuComponentProps = ReturnType<typeof useMenuLogic>;
