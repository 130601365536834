import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getGeneralTestimonials() {
  const data = await serverSideCmsFetch(
    `query getGeneralTestimonials {
        testimonials(where: {relatedTo: "general"}, first:100) {
            nodes {
              testimonial_content {
                author
                content
                date
                relatedTo
                fieldGroupName
              }
              title
            }
          }
      }
      `,
    {}
  );
  return data.testimonials.nodes;
}
