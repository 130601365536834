import { createContext, useContext } from "react";
import "@/../builder.config";
import "@/../builder.analytics.config";
import "@/../builder.blogsections.config";
import "@/../builder.layout.config";
import "@/../builder.media.config";
import "@/../builder.forms.config";
import "@/../builder.registration.config";
import { builder } from "@builder.io/react";
import { BUILDER_API_KEY } from "lib/constants";

builder.init(BUILDER_API_KEY);

export const BuilderContext = createContext({});

export const useBuilderContext = () => {
  return useContext(BuilderContext);
};
