import {
  ArticleTypeFields,
  PodcastTypeFields,
  ScriptTypeFields,
  VideoTypeFields,
  WorkshopTypeFields,
} from "@/lib/fragments";
import {
  placeholderQuery,
  abTestQuery,
  topicWorkshopQuery,
  tolstoyQuery,
  imageBannerQuery,
  smallHeroQuery,
} from "@/lib/fragments/graphQLqueries";
import { buildLatestTopics, buildUpcomingEvents } from "@/lib/queryHelpers";

/**
 * IMPORTANT NOTE!
 * The data query below is copied from topics/[slug].js and adapted to use "page" instead of "topic" in multiple places.
 * ACF prefixes the field group with the "object type", so we can't get a clean custom object to work with for both pages and topics. (ex. "Page_Custompagesections_FlexibleSections_CustomCarousel")
 */
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPage({ slug, preview, previewData }) {
  if (!slug) return;

  const data = await serverSideCmsFetch(
    `
    query PageQuery($id: ID!) {
      page(id: $id, idType: URI, asPreview: ${preview}) {
        title
        slug
        featuredImage {
          node {
            mediaItemUrl
            altText
          }
        }
        seo {
          title
          fullHead
          metaRobotsNofollow
          metaRobotsNoindex
        }
        customPageSections {
          flexibleSections {
            ... on Page_Custompagesections_FlexibleSections_CustomCarousel {
              title
              fieldGroupName
              contentType
              ${abTestQuery}
              customList {
                chooseContent {
                  ... on Article {
                    id: articleId
                    ...ArticleTypeFields
                  }
                  ... on Video {
                    id: videoId
                    ...VideoTypeFields
                  }
                  ... on Podcast {
                    id: podcastId
                    ...PodcastTypeFields
                  }
                  ... on Script {
                    id: scriptId
                    ...ScriptTypeFields
                  }
                  ... on Workshop {
                    id: workshopId
                    ...WorkshopTypeFields
                  }
                }
              }
              latestFromTopic {
                chooseTopic {
                  articles(first: 10) {
                    nodes {
                      id: articleId
                      ...ArticleTypeFields
                    }
                  }
                  podcasts(first: 10) {
                    nodes {
                      id: podcastId
                      ...PodcastTypeFields
                    }
                  }
                  videos(first: 10) {
                    nodes {
                      id: videoId
                      ...VideoTypeFields
                    }
                  }
                  scripts(first: 10) {
                    nodes {
                      id: scriptId
                      ...ScriptTypeFields
                    }
                  }
                  workshops(first: 10) {
                    nodes {
                      id: workshopId
                      ...WorkshopTypeFields
                    }
                  }
                }
              }
              subtitle
            }
            ... on Page_Custompagesections_FlexibleSections_WorkshopListing {
              title
              subtitle
              fieldGroupName
              contentType
              viewAllLink
              buttonDataId
              ${abTestQuery}
              customList {
                chooseContent {
                  ... on Workshop {
                    id: workshopId
                    ...WorkshopTypeFields
                  }
                }
              }
              latestFromTopic {
                chooseTopic {
                  workshops(first: 15) {
                    nodes {
                      id: workshopId
                      ...WorkshopTypeFields
                    }
                  }
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_ThumbnailCards {
              fieldGroupName
              thumbnailCardsTitle
              buttonDataId
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Featuring {
              fieldGroupName
              caption
              author
              byline
              ${abTestQuery}
              image {
                mediaItemUrl
                altText
                mediaDetails {
                  width
                  height
                }
              }
              logos {
                logo {
                  mediaItemUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_Showreel {
              fieldGroupName
              showreelText
              showreelTitle
              footnote
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_ThumbnailCards {
              fieldGroupName
              thumbnailCardsTitle
              link {
                title
                url
              }
              ${abTestQuery}
              thumbnailCardsCards {
                image {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                  altText
                }
                title
                text
              }
              footnote
            }
            ... on Page_Custompagesections_FlexibleSections_RecentlyViewedCarousel {
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Plans {
              fieldGroupName
              title
              text
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Testimonials {
              fieldGroupName
              title
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Faqs {
              fieldGroupName
              title
              showMembershipCta
              footnote
              buttonDataId
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_CaptionedVideo {
              captionedPlayerCaption
              captionedPlayerVideoId
              fieldGroupName
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_PageHero {
              brandColor
              featureIllustration {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              heading
              subHeading
              newsletter
              ${abTestQuery}
              link {
                url
                title
              }
              buttonDataId
              sectionDataId
              fieldGroupName
              eyebrow
              reinforceText
              mobileIllustration {
                altText
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              mobileFeaturedImg {
                altText
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              featuredImageDisplay
              showAccentCircle
              mobileTextBoxBackground
            }
            ... on Page_Custompagesections_FlexibleSections_ArchiveHero {
              brandColor
              featureIllustration {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              ${abTestQuery}
              title
              description
              fieldGroupName
            }
            ... on Page_Custompagesections_FlexibleSections_TextSingleColumn {
              fieldGroupName
              textSingleColumnContent
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Callout {
              fieldGroupName
              heading
              subheading
              backgroundColor
              foregroundColor
              topPadding
              bottomPadding
              ${abTestQuery}
              image {
                altText
                sourceUrl
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
              hideFromUsers
            }
            ... on Page_Custompagesections_FlexibleSections_CheckoutCallout {
              backgroundColor
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
              image {
                altText
                sourceUrl
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_AboutUsHero {
              description
              fieldGroupName
              heading
              subHeading
              ${abTestQuery}
              featureIllustration {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
                altText
              }
              featureIllustrationBadge {
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
                altText
              }
            }
            ... on Page_Custompagesections_FlexibleSections_AboutUsFeature {
              description
              fieldGroupName
              heading
              subHeading
              ${abTestQuery}
              featuredIllustration {
                altText
                title
                mediaItemUrl
              }
            }
            ... on Page_Custompagesections_FlexibleSections_AboutUsCards {
              fieldGroupName
              heading
              ${abTestQuery}
              cards {
                icon {
                  mediaItemUrl
                  altText
                }
                text
                title
              }
            }
            ... on Page_Custompagesections_FlexibleSections_PodcastHero {
              applePodcastsLink
              fieldGroupName
              heroDescription
              heroHeadline
              spotifyLink
              ${abTestQuery}
              heroImage {
                title
                sourceUrl
                mediaDetails {
                  width
                  height
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_RecentPodcastEpisodes {
              fieldGroupName
              headline
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_ContactUsCallout {
              description
              fieldGroupName
              headline
              ${abTestQuery}
              image {
                altText
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              mobileImage {
                altText
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              cta {
                target
                title
                url
              }
            }
            ... on Page_Custompagesections_FlexibleSections_MembershipCallout {
              calloutDescription
              calloutHeadline
              pricePerMonth
              fieldGroupName
              ${abTestQuery}
              callToAction {
                target
                title
                url
              }
              buttonDataId
              backgroundImage {
                sourceUrl
              }
            }
            ... on Page_Custompagesections_FlexibleSections_PurchasedWorkshops {
              fieldGroupName
              heading
              subheading
              viewAllLink
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_PodcastCarousel {
              fieldGroupName
              testimonialsHeadline
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_UpcomingEventsListing {
              fieldGroupName
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_PressReleases {
              fieldGroupName
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_BookHero {
              fieldGroupName
              title
              description
              buttonText
              ${abTestQuery}
              badge {
                topText
                bottomText
                middleText
                backgroundColor
              }
              featureIllustration {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
              backgroundImage {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_SingleTestimonial {
              fieldGroupName
              testimonialText
              testimonialName
              testimonialRef
              backgroundColor
              accentColor
              ${abTestQuery}
              testimonialImage {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_TwoColumnArticle {
              fieldGroupName
              introText
              article
              ${abTestQuery}
              featureImage {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_SectionAbout {
              fieldGroupName
              title
              introText
              text
              socialTitle
              backgroundColor
              ${abTestQuery}
              socialLinks {
                link {
                  url
                  title
                  target
                }
              }
              featureImage {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_InfoCards {
              fieldGroupName
              ${abTestQuery}
              infoCards{
                title
                text
                background
                link {
                  url
                  title
                }
                buttonDataId
                featureImage {
                  mediaItemUrl
                  altText
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_PromoCta {
              fieldGroupName
              heading
              headline
              subHeading
              text
              footerTitle
              footerText
              backgroundColor
              footerBackground
              ${abTestQuery}
              ctaLinks {
                link {
                  url
                  title
                }
              }
              singleLink {
                url
                title
              }
              logo {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_Reviews {
              fieldGroupName
              title
              ${abTestQuery}
              featureImage {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
              reviews {
                text
                name
                reference
              }
            }
            ... on Page_Custompagesections_FlexibleSections_CircleCarousel {
              fieldGroupName
              heading
              spaceId
              ${abTestQuery}
              link {
                title
                url
              }
            }
            ... on Page_Custompagesections_FlexibleSections_MarketingBanner {
              fieldGroupName
              headline
              text
              ${abTestQuery}
              cta {
                title
                url
                target
              }
              featureImage {
                altText
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              override
            }
            ... on Page_Custompagesections_FlexibleSections_TopicTiles {
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_PreOrderBook {
              fieldGroupName
              heading
              subheading
              ${abTestQuery}
              features {
                guest
                public
                user
              }
              step1Title
              availabilityDate
              links {
                link {
                  target
                  title
                  url
                }
              }
              step2Title
              thankYou {
                title
                text
              }
              footerTitle
              footerText
              footerBackground
            }
            ... on Page_Custompagesections_FlexibleSections_SupportCallout {
              backgroundColor
              description
              fieldGroupName
              ${abTestQuery}
              image {
                mediaItemUrl
                altText
                mediaDetails {
                  height
                  width
                }
              }
              link {
                target
                title
                url
              }
            }
            ... on Page_Custompagesections_FlexibleSections_StampedReview {
              fieldGroupName
              title
              subtitle
              sectionType
              reviewsPerPage
              productId
              reviewsHeading
              ${abTestQuery}
              reviewsImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                altText
              }
            }
            ... on Page_Custompagesections_FlexibleSections_PressReviews {
              fieldGroupName
              headline
              ${abTestQuery}
              pressItems {
                description
                title
                logo {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_WeeklyFlex {
              aboveFormContent
              belowFormContent
              ctaText
              successText
              comingSoonText
              fieldGroupName
              backgroundColor
              componentWidth
              ${abTestQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_SeasonalCallout {
              ${abTestQuery}
              seasonalCallout {
                headline
                text
                backgroundColor
                button {
                  title
                  url
                  target
                }
                featuredImage {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
                decorationImageMobile {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
                decorationImageDesktop {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
              charityBanner {
                title
                text
                image {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                }
              }
              enableBanner
              fieldGroupName
            }
            ... on Page_Custompagesections_FlexibleSections_EditorialCarousel {
              fieldGroupName
              ${abTestQuery}
              headline
              subHeadline
              cta {
                title
                url
                target
              }
              ctaCard {
                title
                subTitle
                backgroundColor
                link
              }
              chooseContent {
                ... on Workshop {
                  id: workshopId
                  carouselPreview {
                    shortTitle
                    shortDescription
                  }
                  featuredImage {
                    node {
                      mediaItemUrl
                      altText
                      mediaDetails {
                        width
                        height
                      }
                    }
                  }
                  slug
                  contentType {
                    node {
                      labels {
                        singularName
                      }
                    }
                  }
                }
              }
            }
            ... on Page_Custompagesections_FlexibleSections_PersonalizedWorkshop {
              fieldGroupName
              ${abTestQuery}
              heading
              subheading
            }
            ... on Page_Custompagesections_FlexibleSections_TolstoyEmbed {
              ${abTestQuery}
              ${tolstoyQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_Spacer {
              backgroundColor
              customOverride
              fieldGroupName
              presetSpacer
              customSpacer {
                desktop
                mobile
              }
            }
            ... on Page_Custompagesections_FlexibleSections_ImageBanner {
              ${abTestQuery}
              ${imageBannerQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_SmallHero {
              ${abTestQuery}
              ${smallHeroQuery}
            }
            ... on Page_Custompagesections_FlexibleSections_AcquisitionQuiz {
              fieldGroupName
              quizId
              ${abTestQuery}
            }
          }
        }
      }
      events(first: 50) {
        nodes {
          eventId: databaseId
          eventContent {
            eventDateTime
            eventDetailLink
            eventSummary
            eventType
          }
          featuredImage {
            node {
              mediaItemUrl
              altText
              mediaDetails {
                width
                height
              }
            }
          }
          title
        }
      }
      ${placeholderQuery}
      ${topicWorkshopQuery}
      acfOptionsGlobalContent {
        optionsGlobalContent {
          upcomingLiveEventsContent {
            subtitle
            title
            link {
              title
              url
            }
            ctaButtonText
            pastEventLink {
              url
              title
            }
          }
          pressReleases {
            pressItems {
              description
              logo {
                mediaItemUrl
                altText
              }
              title
            }
            headline
          }
          marketingBanner {
            headline
            text
            cta {
              title
              url
              target
            }
            featureImage {
              altText
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          checkoutSettings{
            selectPlanHeading
            selectPlanSubheading
          }
          headerCtaContent{
            buttonDataId
          }
        }
      }
      testimonials(where: {relatedTo: "podcast"}, first:100) {
        nodes {
          testimonial_content {
            author
            content
            date
            relatedTo
            fieldGroupName
          }
          title
        }
      }
      podcasts (where: {orderby: {field: DATE, order: DESC}}, first: 4) {
        nodes {
          id: podcastId
          title
          excerpt
          date
          slug
          topics {
            nodes {
              name
              slug
            }
          }
          podcastContent {
            visibility
          }
          contentDuration {
            timeToConsume
          }
        }
      }
    }
    ${ArticleTypeFields}
    ${ScriptTypeFields}
    ${VideoTypeFields}
    ${PodcastTypeFields}
    ${WorkshopTypeFields}
    `,
    { variables: { id: slug } },
    previewData?.bearerToken
  );

  /**
   * Get the "latest from topic" data, organize into a single array, sort by date, and push back to main data object.
   */
  const flexibleSections = data.page.customPageSections.flexibleSections;

  for (const section of flexibleSections) {
    if (section.contentType === "latestFromTopic") {
      section.latestFromTopic.postData = [buildLatestTopics(section)];
    } else if (
      section.fieldGroupName ===
      "Topic_Custompagesections_FlexibleSections_PersonalizedWorkshop"
    ) {
      section.choices =
        data.acfOptionsOnboardingFlow?.optionsOnboardingFlow?.topic?.choices;
    }
  }

  /**
   * Sort Events by next upcoming, trim events that are past
   */
  const eventPosts = data.events.nodes;
  if (eventPosts) {
    data.events.postData = [buildUpcomingEvents(eventPosts)];
  }

  return data;
}
