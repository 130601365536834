import { UserMenuProps, useUserMenuLogic } from "./useUserMenuLogic";
import Icon from "@/components/Icon";
import ProfilePicture from "@/components/ProfilePicture";
import { COMMUNITY_PROFILE_URL, PROFILE_URL } from "@/lib/constants";
import { ROUTES } from "@/lib/routes";
import { Menu, Transition } from "@headlessui/react";
import Link from "next/link";

const userMenuItems = [
  {
    label: "Account Settings",
    url: PROFILE_URL,
    gated: false,
  },
  {
    label: "My Community Profile",
    url: COMMUNITY_PROFILE_URL,
    gated: true,
  },
];

export const UserMenu = (props: UserMenuProps) => {
  const { user, isOpen, toggle } = useUserMenuLogic(props);
  return (
    <Menu
      as="div"
      className="relative flex items-center justify-center focus:border-none focus:ring-0 md-max:focus:outline-none"
    >
      <>
        <button
          onClick={(e) => toggle(e)}
          className="z-50 inline-flex items-center justify-center w-full font-mono font-medium tracking-wide uppercase translate-y-1 focus:ring-0 focus:border-none md-max:focus:outline-none"
        >
          <ProfilePicture
            photoUrl={user?.profile_photo_path || user?.profile_photo_url}
            className={undefined}
          />
          <Icon className="ml-1.5" name={isOpen ? "caretUp" : "caretDown"} />
        </button>
        <Transition
          show={isOpen}
          enter="transition duration-200 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-125 ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Menu.Items
            static
            className={`absolute z-[100] top-[52px] right-0 rounded-[3px] w-60 overflow-x-hidden bg-white p-4 shadow-xl focus:outline-none`}
          >
            {userMenuItems.map(({ label, url, gated }, i) => {
              if (gated && !user?.subscription_active) return null;
              return (
                <div
                  className="px-1 py-1 font-mono font-medium text-dark-gray hover:text-black"
                  key={`${label}-${i}`}
                >
                  <Menu.Item>
                    <Link
                      href={url}
                      className={`group flex w-full items-center rounded-md `}
                      legacyBehavior
                    >
                      {label}
                    </Link>
                  </Menu.Item>
                </div>
              );
            })}
            <div className="px-1 pt-3 mt-2 font-mono font-medium border-t border-gray text-dark-gray hover:text-black">
              <Menu.Item>
                <Link
                  href={ROUTES.LOGOUT}
                  className={`group flex w-full items-center rounded-md`}
                >
                  Logout
                </Link>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  );
};
