function track(event_name, properties = {}) {
  window?.analytics?.track(event_name, properties);
}

function page(properties) {
  window?.analytics?.page(properties);
}

function identify(userId, traits = {}) {
  window?.analytics?.identify(userId, traits);
}

export { track, page, identify };
