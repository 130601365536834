import throttle from "@/util/throttle";
import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";

export const initialState = {
  open: false,
  content: null,
  dismissable: true,
  type: null,
  event: null,
};

export const UIContext = createContext();

export const ModalReducer = (state, action) => {
  switch (action.type) {
    case "SET_OPEN":
      return { ...state, open: action.value };
    case "SET_CONTENT":
      return { ...state, content: action.value };
    case "SET_TYPE":
      return { ...state, type: action.value };
    case "SET_EVENT":
      return { ...state, event: action.value };
    case "SET_DISMISSABLE":
      return { ...state, dismissable: action.value };
    case "SET_POSITION":
      return { ...state, position: action.value };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export function UIProvider({ children }) {
  const [modal, dispatchModal] = useReducer(ModalReducer, initialState);
  const [displayAlertBanner, setDisplayAlertBanner] = useState(false);
  const [displayStatusBanner, setDisplayStatusBanner] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [focusHeader, setFocusHeader] = useState(false);
  const [minimalHeader, setMinimalHeader] = useState(false);
  const headerRef = useRef(null);
  const [audioOnly, setAudioOnly] = useState(false);

  const [innerWidth, setInnerWidth] = useState(0);
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (modal.type === "search") {
      setFocusHeader(modal.open);
    }
  }, [modal.open, modal.type]);

  useEffect(() => {
    const handleResize = throttle(() => {
      const { innerHeight, innerWidth } = window;
      setIsPortrait(innerHeight > innerWidth);
      setInnerWidth(innerWidth);
    }, 300);

    handleResize();
    window.addEventListener("resize", handleResize, { passive: true });
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <UIContext.Provider
      value={{
        modal,
        dispatchModal,
        scrollPosition,
        setScrollPosition,
        pagination,
        setPagination,
        displayAlertBanner,
        displayStatusBanner,
        displaySearch,
        setDisplayAlertBanner,
        setDisplayStatusBanner,
        setDisplaySearch,
        innerWidth,
        isPortrait,
        focusHeader,
        setFocusHeader,
        minimalHeader,
        setMinimalHeader,
        headerRef,
        setAudioOnly,
        audioOnly,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export const useUI = () => useContext(UIContext);
