import { useUser } from "@/context/UserContext";
import { page } from "@/lib/helpers/analytics";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const PageTracking = () => {
  const { user, authVerified } = useUser();
  const router = useRouter();
  const [pageTracked, setPageTracked] = useState(false);

  useEffect(() => {
    if (
      !authVerified ||
      (router.asPath === "/" && user?.subscription_active) ||
      pageTracked
    )
      return;

    const trackPage = () => {
      page({
        title: document.title,
        url: window?.location.href,
        identified: !!user,
        path: router.asPath,
      });
    };

    trackPage();
    setPageTracked(true);
  }, [authVerified, pageTracked, router, user]);

  useEffect(() => {
    if (!authVerified || (router.asPath === "/" && user?.subscription_active))
      return;
    router.events.on("routeChangeComplete", setPageTracked(false));

    return () => {
      router.events.off("routeChangeComplete", setPageTracked(false));
    };
  }, [authVerified, router, user]);

  return null;
};

export default PageTracking;
