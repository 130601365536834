import { useUser } from "@/context/UserContext";
import { MouseEventHandler, useEffect, useState } from "react";

export interface UserMenuProps {}

export const useUserMenuLogic = ({}: UserMenuProps) => {
  const { user } = useUser();
  const [isOpen, setOpen] = useState(false);

  const toggle: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", close);
    return () => window.removeEventListener("click", close);
  }, []);

  return { user, isOpen, toggle };
};

export type UserMenuComponentProps = ReturnType<typeof useUserMenuLogic>;
