import fallBackSrc from "../../public/misc/lost-illustration.svg";
import Image, { ImageProps } from "next/image";
import { useState } from "react";

interface OptimizedImageWithFallbackProps extends ImageProps {
  onLoad?: () => void;
}

function OptimizedImageWithFallback({
  src,
  alt,
  width,
  height,
  fill = false,
  quality = "75",
  priority = false,
  className,
  style,
  sizes,
  onLoad,
}: OptimizedImageWithFallbackProps) {
  const [imageError, setImageError] = useState(!src);

  const options = {
    quality,
    priority,
    width,
    height,
    fill,
    style,
    sizes,
  };

  const handleImageLoad = () => {
    if (onLoad) {
      onLoad();
    }
  };

  return (
    <Image
      alt={alt}
      src={imageError ? fallBackSrc.src : src}
      {...options}
      className={className}
      onError={() => setImageError(true)}
      onLoad={handleImageLoad}
    />
  );
}

export default OptimizedImageWithFallback;
