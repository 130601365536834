import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getPersonalizedCarousel() {
  const data = await serverSideCmsFetch(
    `query PersonalizedCarousel {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          personalizedCarouselContent {
            sectionContent {
                topic
                title
                subtitle
                cards {
                  ... on Article {
                    databaseId
                    title
                    slug
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                      }
                    }
                    topics {
                      nodes {
                        name
                      }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                      timeToConsume
                    }
                  }
                  ... on Video {
                    databaseId
                    title
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                      }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Script {
                    databaseId
                    title
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                      }
                    }
                    slug
                    topics {
                      nodes {
                        name
                      }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Workshop {
                    databaseId
                    title
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                      }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
                  ... on Podcast {
                    databaseId
                    title
                    featuredImage {
                      node {
                        mediaItemUrl
                        altText
                      }
                    }
                    slug
                    topics {
                        nodes {
                        name
                        }
                    }
                    contentType {
                      node {
                        labels {
                          singularName
                        }
                      }
                    }
                    contentDuration {
                        timeToConsume
                    }
                  }
              }
            }
          }
        }
      }
    }`,
    {}
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent
    .personalizedCarouselContent;
}
