type FlatToTreeKeys<T> = {
  idKey?: string | keyof T;
  parentKey?: string | keyof T;
  childrenKey?: string | keyof T;
};
const flatToTree = <T>(data: T[] = [], keys?: FlatToTreeKeys<T>) => {
  const {
    idKey = "id",
    parentKey = "parentId",
    childrenKey = "children",
  } = keys || {};
  const tree: Array<T & { [childrenKey: string]: T[] }> = [];
  const childrenOf: Record<string, T[]> = {};
  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id as string] = childrenOf[id as string] || [];
    newItem[childrenKey as string] = childrenOf[id as string];
    parentId
      ? (childrenOf[parentId as string] =
          childrenOf[parentId as string] || []).push(newItem)
      : tree.push(newItem as T & { [childrenKey: string]: T[] });
  });
  return tree;
};

export default flatToTree;
