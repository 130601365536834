import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getTestimonials() {
  const data = await serverSideCmsFetch(
    `query getTestimonials {
        testimonials {
          nodes {
            title
            testimonial_content {
              author
              content
            }
          }
        }
      }
      `,
    {}
  );
  return data.testimonials.nodes;
}
