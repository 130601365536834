import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getGlobalContent() {
  const data = await serverSideCmsFetch(
    `
    query GlobalContent {
      acfOptionsGlobalContent {
        optionsGlobalContent {
          upcomingLiveEventsContent {
            subtitle
            title
            link {
              title
              url
            }
            ctaButtonText
            pastEventLink {
              url
              title
            }
          }
          pressReleases {
            pressItems {
              description
              logo {
                mediaItemUrl
                altText
              }
              title
            }
            headline
          }
          marketingBanner {
            headline
            text
            cta {
              title
              url
              target
            }
            featureImage {
              altText
              mediaItemUrl
              mediaDetails {
                height
                width
              }
            }
          }
          checkoutSettings{
            selectPlanHeading
            selectPlanSubheading
          }
          headerCtaContent{
            buttonDataId
          }
        }
      }
    }`
  );

  return data;
}
