import Button from "@/components/Button";
import cn from "classnames";

const Banner = ({
  bgColor,
  color,
  children,
  dismissable,
  onDismiss,
  classNames,
}) => {
  return (
    <section
      className={cn("z-50 block text-black", classNames, bgColor, {
        "bg-yellow": !bgColor && color === "yellow",
        "bg-light-green": !bgColor && color === "green",
        "bg-light-red": !bgColor && color === "red",
        "bg-medium-blue": !bgColor && color === "blue",
        "h-12 md:h-10": children,
        "h-0 md:h-0": !children,
      })}
    >
      {children && (
        <div className="container flex items-center justify-between h-full px-4 mx-auto lg:px-8 xl:px-12 2xl:px-14 md:flex-nowrap xl-min-container:max-w-[1248px]">
          {children}
          {dismissable && (
            <Button
              variant="text"
              prefixIcon="close"
              onClick={onDismiss}
              iconClasses="mr-0 scale-75"
            />
          )}
        </div>
      )}
    </section>
  );
};

export default Banner;
