import Icon from "@/components/Icon";
import { ButtonProps, btnVariants, btnSizes } from "@/types/button";
import cn from "classnames";
import Link from "next/link";
import { forwardRef } from "react";

const Button = (
  {
    children,
    className = "",
    disabled = false,
    loading = false,
    href = false,
    target = "_self",
    fullWidth = false,
    spaceBetween = false,
    prefixIcon = null,
    role = "button",
    suffixIcon = null,
    iconClasses = false,
    largeIcon = false,
    size = "large",
    title = "",
    variant = "primary",
    ...props
  }: ButtonProps,
  ref: any
) => {
  const renderIcon = (icon = "") => (
    <div
      className={cn(
        {
          ["btn-icon ml-2.5"]: suffixIcon,
          ["btn-icon mr-2.5"]: prefixIcon,
          ["btn-icon-large"]: largeIcon,
        },
        iconClasses
      )}
    >
      <Icon name={icon} />
    </div>
  );

  return (
    <>
      {typeof href === "string" ? (
        // NOTE: If a href found, render anchor element...
        <Link
          href={href}
          className={cn(
            btnVariants[variant],
            btnSizes[size],
            { ["btn-disabled"]: disabled },
            { ["btn-full-width"]: fullWidth },
            { ["btn-space-between"]: spaceBetween },
            "md-max:outline-none",
            className
          )}
          title={title}
          target={target}
          {...props}
          ref={ref}
        >
          {prefixIcon && renderIcon(prefixIcon)}
          {children}
          {suffixIcon && renderIcon(suffixIcon)}
        </Link>
      ) : (
        // NOTE: Otherwise render button element...
        <button
          role={role}
          className={cn(
            btnVariants[variant],
            btnSizes[size],
            { ["btn-full-width"]: fullWidth },
            { ["btn-space-between"]: spaceBetween },
            { ["btn-loading"]: loading },
            "md-max:outline-none",
            className
          )}
          disabled={disabled}
          ref={ref}
          {...props}
        >
          {loading ? (
            <span className="absolute inset-0 flex items-center justify-center">
              <span className="block w-2 h-2 rounded-full bg-dark-yellow animate-pulse-grow"></span>
            </span>
          ) : (
            <>
              {prefixIcon && renderIcon(prefixIcon)}
              {children}
              {suffixIcon && renderIcon(suffixIcon)}
            </>
          )}
        </button>
      )}
    </>
  );
};

export default forwardRef(Button);
