import {
  placeholderQuery,
  searchLinksQuery,
  acfGlobalOptionsQuery,
} from "@/lib/fragments/graphQLqueries";
import { referralsQuery } from "@/lib/fragments/graphQLqueries/referralsQuery";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getVideo({ id, preview, previewData }) {
  if (!id) return;

  const data = await serverSideCmsFetch(
    `
    query VideoQuery($id: ID!) {
      contentNode(id: $id, idType: DATABASE_ID, asPreview: ${preview}) {
          ... on Video {
          databaseId
          title
          slug
          date
          excerpt
          contentDuration {
            timeToConsume
          }
          topics {
              nodes {
                name
                slug
                topicCustomData {
                  circleSpaceUrl
                }
                topicId
              }
          }
          seo {
            title
            fullHead
            metaRobotsNofollow
            metaRobotsNoindex
          }
          videoContent {
            videoId
            videoSummary
          }
        }
      }
      acfOptionsGlobalContent {
        optionsGlobalContent {
          ${acfGlobalOptionsQuery}
          ${referralsQuery}
        }
      }
      ${placeholderQuery}
      ${searchLinksQuery}
    }
    `,
    {
      variables: {
        id,
      },
    },
    previewData?.bearerToken
  );

  return data;
}
