import { serverSideCmsFetch } from "../serverSideCmsApi";
import placeholderQuery from "@/lib/fragments/graphQLqueries/placeholderQuery";

export async function getPlaceholders() {
  const data = await serverSideCmsFetch(
    `
    query placeholderImages {
      ${placeholderQuery}
      topics(first: 100) {
        nodes {
          name
          topicId
          slug
          topicCustomData {
            topicThumbnail {
              mediaItemUrl
            }
          }
        }
      }
    }
    `,
    {}
  );

  const badgePlaceholders = {};
  data?.topics?.nodes?.forEach((el) => {
    if (!!el.name && !!el.topicCustomData?.topicThumbnail?.mediaItemUrl) {
      badgePlaceholders[el.name] =
        el.topicCustomData?.topicThumbnail?.mediaItemUrl;
    }
  });
  data.acfOptionsPlaceholders.optionsPlaceholderImages["topics"] =
    badgePlaceholders;

  return data;
}
