import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { useUser } from "@/context/UserContext";
import { ROUTES } from "@/lib/routes";
import cn from "classnames";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const AuthFormWrapper = ({
  className = "",
  initialState = "login",
  purchaseUrl = undefined,
  postId = undefined,
  isRegistering = false,
  isModal = true, // TODO we should use composition here instead of passing in presentation props
  nextUrl = undefined,
}) => {
  const router = useRouter();
  const { user } = useUser();

  useEffect(() => {
    if (isModal) return;
    if (user?.subscription_active) {
      router.replace(ROUTES.LIBRARY);
    }
  }, [user, router, isModal]);

  const [formState, setFormState] = useState(initialState);

  return (
    <div
      className={cn(
        "relative px-4 pt-12 pb-5 bg-light-gray mx-auto",
        "sm:px-12 md:pb-12 md:my-0 md:px-18",
        "w-full sm:w-[588px] h-fit",
        {
          "full-height-with-dvh sm:h-auto max-h-screen": isModal,
        },
        className
      )}
    >
      {formState === "login" ? (
        <LoginForm
          purchaseUrl={purchaseUrl}
          postId={postId}
          isRegistering={isRegistering}
          nextUrl={nextUrl}
        ></LoginForm>
      ) : (
        <RegisterForm
          setFormState={setFormState}
          purchaseUrl={purchaseUrl}
          postId={postId}
          nextUrl={nextUrl}
        ></RegisterForm>
      )}
    </div>
  );
};

export default AuthFormWrapper;
