import Button from "@/components/Button";
import Icon from "@/components/Icon";
import cn from "classnames";
import { forwardRef } from "react";

const ActionButton = (
  { icon = "save", className, iconClassName, iconHover = "yellow", label, ...props },
  ref
) => {
  return (
    <Button
      variant="base"
      size="small"
      className={cn(
        "btn-round bg-light-gray",
        "group hoverable:hover:bg-black",
        {"hoverable:hover:text-yellow": iconHover === "yellow"},
        {"hoverable:hover:text-white": iconHover !== "yellow"},
        className
      )}
      ref={ref}
      {...props}
    >
      {label && <span className="sr-only">{label}</span>}
      <Icon name={icon} className={cn("text-current", iconClassName)} />
    </Button>
  );
};

export default forwardRef(ActionButton);
