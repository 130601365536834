import Icon from "@/components/Icon";
import { useUI } from "@/context/UIContext";
import useDebounce from "@/hooks/useDebounce";
import { useRef, useEffect, useState } from "react";
import { useSearchBox } from "react-instantsearch-hooks-web";

const Search = (props) => {
  const { query, refine, clear } = useSearchBox(props);
  const [showClose, setShowClose] = useState(false);
  const searchRef = useRef();
  const { modal, dispatchModal } = useUI();

  const handleRefine = useDebounce((value) => {
    if (value.length > 2) {
      refine(value);
      dispatchModal({
        type: "SET_EVENT",
        value: "typed",
      });
    }
    if (value === "") refine("");
  }, 500);

  const handleChange = (e) => {
    handleRefine(e.target.value);
    setShowClose(!!e.target.value);
  };

  useEffect(() => {
    if (modal.type === "search") {
      if (modal.open) {
        setTimeout(() => searchRef.current.focus(), 500);
      } else {
        clear();
        searchRef.current.blur();
        setShowClose(false);
      }
    }
  }, [modal, searchRef, clear]);

  const handleClear = () => {
    clear();
    searchRef.current.value = "";
    setShowClose(false);
    dispatchModal({
      type: "SET_EVENT",
      value: "cleared",
    });
  };

  const handleBlur = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <div className="relative">
      <Icon
        id="search-input"
        name="search"
        className="absolute -translate-y-1/2 pointer-events-none top-1/2 left-4"
      />
      <input
        id="algolia-search-box"
        ref={searchRef}
        onChange={(e) => handleChange(e)}
        onKeyUp={(e) => handleBlur(e)}
        type="text"
        placeholder="Search by topic, workshop, title"
        className="w-full !pl-12 h-12 overflow-hidden !rounded-tl-sm !rounded-br-sm !bg-light-gray !border-gray !border-1 !rounded-tr-xl !rounded-bl-xl focus:!ring-0"
      />
      {(showClose || query.length > 0) && (
        <button
          className="absolute text-black transition-colors scale-75 -translate-y-1/2 top-1/2 right-4 hover:text-gray-10"
          onClick={() => handleClear()}
        >
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};

export default Search;
