import Head from "next/head";

export default function Meta() {
  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      ></meta>

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png?v=2"
      />
      <link rel="manifest" href="/favicon/site.webmanifest?v=2" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg?v=2"
        color="#000000"
      />
      <link rel="shortcut icon" href="/favicon/favicon.ico?v=2" />
      <meta name="msapplication-TileColor" content="#facd06" />
      <meta
        name="msapplication-config"
        content="/favicon/browserconfig.xml?v=2"
      />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
}
