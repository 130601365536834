import { BFF_URL } from "@/lib/constants";
import axios, { AxiosInstance, AxiosResponse } from "axios";

interface ApiOptions {
 token?: string | null;
 method?: "GET" | "POST" | "PUT" | "DELETE";
}

export default function bffapi({ token = null, method = "GET" }: ApiOptions): AxiosInstance {
 const api: AxiosInstance = axios.create({
    baseURL: BFF_URL,
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    method,
    withCredentials: true,
 });

 api.interceptors.response.use(
    (response: AxiosResponse) => { 
      return response;
    },
    (error) => {
      console.log(error.response);
      switch (error.response?.status) {
        case 400: // Not logged in
        case 401: // Not logged in
          return Promise.reject(error.response);
        case 419: // Session expired
        case 503: // Down for maintenance
          // Bounce the user to the login screen with a redirect back
          window.location.reload();
          break;
        case 500:
          console.error("Oops, something went wrong! The team have been notified.");
          break;
        default:
          return Promise.reject(error);
      }
    }
 );
 return api;
}
