import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder } from "@builder.io/react";
import dynamic from "next/dynamic";

Builder.register("insertMenu", {
  name: "GI Registration",
  items: [{ name: "GoogleButton" }, { name: "LoginOrRegisterText" }],
});

Builder.registerComponent(
  dynamic(() => import("@/components/GoogleButton")),
  {
    name: "GoogleButton",
    image: assembleTablerIconUrl("brand-google"),
    inputs: [
      {
        name: "buttonText",
        type: "string",
        defaultValue: "Sign in with Google",
      },
      {
        name: "queryParams",
        type: "object",
        subFields: [
          {
            name: "isRegistering",
            friendlyName: "Is registration button",
            type: "boolean",
            defaultValue: true,
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/LoginOrRegisterText")),
  {
    name: "LoginOrRegisterText",
    image: assembleTablerIconUrl("text-color"),
    inputs: [
      {
        name: "text",
        type: "string",
        defaultValue: "Or create your account [here](register)",
        helperText:
          "Use [text](register) or [text](login) to create links to the login and registration pages, respectively.",
      },
    ],
  }
);
