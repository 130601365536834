import AuthFormWrapper from "./AuthFormWrapper";
import Button from "@/components/Button";
import { useMobileMenu } from "@/context/MobileMenuContext";
import { useUI } from "@/context/UIContext";
import cn from "classnames";
import { useRouter } from "next/router";

const AUTH_ROUTES = ["/register/", "/login/"];

const LoginButton = ({ className }) => {
  const { dispatchModal } = useUI();
  const router = useRouter();
  const { dispatch, setShowMobileMenu } = useMobileMenu();

  const closeMobileMenu = () => {
    dispatch({ type: "SHOW_OPEN" });
    setShowMobileMenu(false);
  };

  const openLoginModal = () => {
    if (AUTH_ROUTES.includes(router.asPath)) {
      router.push("/login/");
    } else {
      dispatchModal({
        type: "SET_CONTENT",
        value: <AuthFormWrapper initialState="login" />,
      });
      dispatchModal({
        type: "SET_OPEN",
        value: true,
      });
    }
  };

  return (
    <Button
      variant="base"
      size=""
      onClick={() => {
        openLoginModal();
        closeMobileMenu();
      }}
      className={cn(
        "text__cta group relative flex h-full items-center text-current after:absolute after:left-0 after:w-full after:h-1 after:bottom-[1.125rem] after:bg-yellow after:transition-opacity after:opacity-0 hover:after:opacity-100",
        className
      )}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
