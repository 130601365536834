import { useUI } from "@/context/UIContext";
import { useReducer, useState, useContext, createContext } from "react";

const initialState = { icon: "menu" };
const MobileMenuContext = createContext();

const menuReducer = (state, action) => {
  switch (action.type) {
    case "SHOW_CLOSE":
      return {
        ...state,
        icon: "close",
      };
    case "SHOW_OPEN":
    default:
      return initialState;
  }
};

function useProvideMobileMenu() {
  const [menuToggleState, dispatch] = useReducer(menuReducer, initialState);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { setFocusHeader } = useUI();

  /* 
  This function handles the icon in the top left mobile nav 
  If we are in initial state toggle to open next, otherwise we will always toggle close
  */

  const handleMenuChange = () => {
    if (menuToggleState.icon === "menu") {
      setShowMobileMenu(true);
      setFocusHeader(true);
      dispatch({ type: "SHOW_CLOSE" });
    } else {
      setShowMobileMenu(false);
      if (window.scrollY === 0) setFocusHeader(false);
      dispatch({ type: "SHOW_OPEN" });
    }
  };

  return {
    menuToggleState,
    handleMenuChange,
    dispatch,
    showMobileMenu,
    setShowMobileMenu,
  };
}

const MobileMenuProvider = ({ children }) => {
  const mobileMenu = useProvideMobileMenu();
  return (
    <MobileMenuContext.Provider value={mobileMenu}>
      {children}
    </MobileMenuContext.Provider>
  );
};

const useMobileMenu = () => {
  return useContext(MobileMenuContext);
};

export { useMobileMenu, MobileMenuProvider };
