import AuthFormWrapper from "@/components/Registration/AuthFormWrapper";
import { useUI } from "@/context/UIContext";
import { useUserAgent } from "@/context/UserAgentContext";
import { builder, BuilderComponent } from "@builder.io/react";
import { useEffect, useState } from "react";

const BuilderIORegistration = ({ type }) => {
  const { dispatchModal } = useUI();
  const { userDevice, userBrowser } = useUserAgent();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedWithoutContent, setLoadedWithoutContent] = useState(false);
  const [builderContent, setBuilderContent] = useState(null);

  useEffect(() => {
    if (!loadedWithoutContent) return;

    // set Auth Form Wrapper as fallback, if no builder section exists
    dispatchModal({
      type: "SET_POSITION",
      value: "center",
    });
    dispatchModal({
      type: "SET_CONTENT",
      value: (
        <AuthFormWrapper
          initialState="register"
          isRegistering={true}
        ></AuthFormWrapper>
      ),
    });
  }, [loadedWithoutContent, dispatchModal]);

  useEffect(() => {
    if (!userDevice || !userBrowser) return;
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        if (!userDevice || !userBrowser) return;

        const response = await builder
          .get("registration-modals", {
            query: {
              "data.type": type,
            },
          })
          .promise();

        if (!response) setLoadedWithoutContent(true);
        setIsLoading(false);
        setBuilderContent(response);
      } catch (error) {
        if (error.name === "AbortError") return;

        setIsLoading(false);
        setLoadedWithoutContent(true);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [userBrowser, userDevice, type]);

  if (isLoading) {
    // TODO - get skeleton from builder
    return <div>Loading...</div>;
  }

  return (
    <BuilderComponent model="registration-modals" content={builderContent} />
  );
};

export default BuilderIORegistration;
