export const ROUTES = {
  HOME: "/",
  LIBRARY: "/library",
  WORKSHOP: "/workshops",
  ABOUT: "/about",
  REVIEWS: "/reviews",
  PODCASTS: "/podcasts",
  ONBOARDING: "/welcome/quiz/orientation",
  MEMBERSHIP_CHECKOUT: "/welcome/checkout/register",
  MEMBERSHIP_PAYMENT: "/welcome/checkout/plan",
  MEMBERSHIP_FAILED: "/membership/failed",
  LIVE_EVENTS: "/live-events",
  LOGIN: "/login",
  LOGOUT: "/logout",
  SUCCESS: "/welcome/checkout/download",
  SUPPORT: "https://support.goodinside.com/",
  CIRCLE_LIVE_EVENTS:
    "https://community.goodinside.com/c/upcomingevents/?sort=asc",
  BOOK: "/book",
  TERMS_OF_USE: "/terms-of-use/",
  PRIVACY: "/privacy-policy/",
  TERMS: "/terms/",
  ACCESSIBILITY: "/accessibility/",
  INTAKE_CHILDREN: "/welcome/intake/children",
  INTAKE_CHILD: "/welcome/intake/child",
} as const;

export type RoutesKeys = keyof typeof ROUTES;
export type RoutesValues = typeof ROUTES[RoutesKeys];
