import { useUI } from "@/context/UIContext";
import { HEADER_HEIGHT, MOBILE_BANNER_HEIGHT } from "@/lib/constants";
import { Dialog, Transition } from "@headlessui/react";
import cn from "classnames";
import { Fragment } from "react";

const ANIMATIONS = {
  slideFromLeft: "-translate-x-full",
  slideFromRight: "translate-x-full",
};
export default function Slideover({
  show,
  setShow,
  children,
  fullHeight = false,
  closeButton,
  headerLabel,
  slideFromLeft = true,
}) {
  const { displayStatusBanner, displayAlertBanner } = useUI();
  const minHeight =
    displayAlertBanner || displayStatusBanner
      ? MOBILE_BANNER_HEIGHT + HEADER_HEIGHT
      : HEADER_HEIGHT;
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className={cn("fixed inset-0 overflow-hidden", {
          "z-20": !fullHeight,
          "z-50": fullHeight,
        })}
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed top-0 bottom-0 right-0 flex max-w-full transition-all duration-500 ease-in pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-400"
              enterFrom={
                slideFromLeft
                  ? ANIMATIONS.slideFromLeft
                  : ANIMATIONS.slideFromRight
              }
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400"
              leaveFrom="translate-x-0"
              leaveTo={
                slideFromLeft
                  ? ANIMATIONS.slideFromLeft
                  : ANIMATIONS.slideFromRight
              }
            >
              <div className="w-screen pointer-events-auto">
                <div className="relative flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                  <div
                    style={{ minHeight: `${minHeight}px` }}
                    className={cn(
                      "sticky top-0 z-30 flex min-h-[4.5rem] items-center justify-center bg-white px-4",
                      {
                        "border-b-1 border-silver": fullHeight,
                      }
                    )}
                  >
                    {closeButton && (
                      <div className="absolute left-4">
                        {closeButton}
                        <button
                          type="button"
                          className="sr-only outline-0"
                          onClick={setShow}
                        >
                          Close panel
                        </button>
                      </div>
                    )}
                    <span className="text__cta">{headerLabel}</span>
                  </div>
                  <div className="relative flex-1 px-4 sm:px-6">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
