import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getTopics() {
  const data = await serverSideCmsFetch(
    `query Topics {
      topics(first: 100) {
        nodes {
          topicId
          name
          topicCustomData {
            active
          }
        }
      }
    }`,
    {}
  );

  const filteredTopics = data?.topics?.nodes?.filter(
    (topic) => topic.topicCustomData?.active
  );

  return filteredTopics;
}
