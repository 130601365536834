import cn from "classnames";

const SplitLayout = ({
  leftClasses,
  rightClasses,
  leftContent,
  rightContent,
}) => (
  <section className="flex flex-col items-stretch justify-start w-full md:flex-row">
    <div
      className={cn(
        "flex flex-1 items-center justify-center py-12 md:py-0 2xl:justify-end relative",
        leftClasses
      )}
    >
      {leftContent}
    </div>

    <div className="flex flex-row items-center justify-start flex-1 bg-white">
      <div className={cn("px-6 lg:pl-20", rightClasses)}>{rightContent}</div>
    </div>
  </section>
);

export default SplitLayout;
