import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getTermsContent() {
  const data = await serverSideCmsFetch(
    `
    query TermsContentQuery{
      acfOptionsGlobalContent {
        optionsGlobalContent {
          termsOfUseComplianceContent {
            title
            text
            ctaText
            footerText
          }
        }
      }
    }
    `
  );
  return data.acfOptionsGlobalContent.optionsGlobalContent.termsOfUseComplianceContent;
}