import abTestQuery from "./abTestQuery";
import acfGlobalOptionsQuery from "./acfGlobalOptionsQuery";
import imageBannerQuery from "./imageBannerQuery";
import placeholderQuery from "./placeholderQuery";
import searchLinksQuery from "./searchLinksQuery";
import smallHeroQuery from "./smallHeroQuery";
import tolstoyQuery from "./tolstoyQuery";
import topicWorkshopQuery from "./topicWorkshopQuery";
import filterByAgeQuery from "./filterByAgeQuery";

export {
  abTestQuery,
  imageBannerQuery,
  placeholderQuery,
  smallHeroQuery,
  topicWorkshopQuery,
  tolstoyQuery,
  searchLinksQuery,
  acfGlobalOptionsQuery,
  filterByAgeQuery
};
