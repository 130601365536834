import Button from "@/components/Button";
import {
  ConsentManager,
  loadPreferences,
  onPreferencesSaved,
} from "@segment/consent-manager";
import inEU from "@segment/in-eu";
import Link from "next/link";
import React from "react";
import { useEffect, useState, useRef } from "react";

const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

function ActionButtons({ acceptAll, denyAll, changePreferences }) {
  return (
    <div className="lg:ml-10 lg:pr-8 md:flex md-max:space-y-2.5 md:space-x-2.5 md:ml-6 md:pr-4">
      <Button
        className="md:w-[6.75rem] md-max:btn-full-width flex justify-center"
        variant="primary"
        size="small"
        onClick={() => acceptAll()}
      >
        Accept
      </Button>
      <Button
        className="md:w-[7.75rem] md-max:btn-full-width flex justify-center"
        variant="transparent"
        size="small"
        onClick={() => denyAll()}
      >
        Opt-out
      </Button>
      <Button
        className="md:w-[4.25rem] md-max:btn-full-width md-max:!py-3.5 flex justify-center lg:!mr-5"
        variant="text"
        size="small"
        onClick={() => changePreferences()}
      >
        More Info
      </Button>
    </div>
  );
}

function BannerContent() {
  const ref = useRef(null);

  useEffect(() => {
    // This is the only way I found to delete an unused element created by `ConsentManager`
    ref?.current?.parentElement?.parentElement?.children?.[1]?.remove();
  }, []);

  return (
    <span className="!text-sm font-sans" ref={ref}>
      This site uses cookies and related technologies, as described in our{" "}
      <Link href="/privacy-policy/" target="_blank" className="!font-bold">
        privacy policy
      </Link>
      , for purposes that may include site operation, analytics, enhanced user
      experience, or advertising. You may choose to consent to our use of these
      technologies, or manage your own preferences.
    </span>
  );
}

export default function CookieConsentManager() {
  return <></>;
  const [hasPreferences, setHasPreferences] = useState();

  useEffect(() => {
    let pref = loadPreferences();
    setHasPreferences(!isObjectEmpty(pref));
    onPreferencesSaved(() => {
      setHasPreferences(true);
    });
  }, []);

  const preferencesDialogTitle = "Website Data Collection Preferences";
  const preferencesDialogContent =
    "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.";
  const cancelDialogTitle = "Are you sure you want to cancel?";
  const cancelDialogContent = "Your preferences have not been saved.";

  return (
    <>
      {!hasPreferences ? (
        <div id="target-container">
          <ConsentManager
            writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
            bannerContent={<BannerContent />}
            bannerSubContent={false}
            preferencesDialogTitle={preferencesDialogTitle}
            preferencesDialogContent={preferencesDialogContent}
            bannerHideCloseButton={true}
            cancelDialogTitle={cancelDialogTitle}
            cancelDialogContent={cancelDialogContent}
            bannerActionsBlock={ActionButtons}
            bannerBackgroundColor="#CFCFCF"
            bannerTextColor="#000000"
            shouldRequireConsent={inEU}
          />
        </div>
      ) : (
        <ConsentManager
          writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}
          bannerContent={<BannerContent />}
          bannerSubContent={false}
          preferencesDialogTitle={preferencesDialogTitle}
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle={cancelDialogTitle}
          cancelDialogContent={cancelDialogContent}
          bannerActionsBlock={ActionButtons}
          bannerBackgroundColor="#CFCFCF"
          bannerTextColor="#000000"
          shouldRequireConsent={inEU}
        />
      )}
    </>
  );
}
