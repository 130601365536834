import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getFAQs() {
  const data = await serverSideCmsFetch(
    `query getFAQs {
        faqs {
          nodes {
            title
            content
          }
        }
      }
      `,
    {}
  );
  return data.faqs.nodes;
}
