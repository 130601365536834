import { PROXIED_CMS_URL } from "@/lib/constants";
import axios from "axios";

/**
 * This method wraps an axios post to our CMS API.
 * It handles some logic for the WP preview.
 *
 * It is important to note that the AUTHENTICATED_CMS_URL constant is only accessible
 * on the server and not the client.
 *
 * @param {string} query - GraphQL query
 * @param {object} variables - GraphQL variables
 * @param {string} previewToken - The token used for authenticating the preview request
 * @returns {object}
 */
export async function serverSideCmsFetch(
  query,
  { variables } = {},
  previewToken = null,
  retries = 3,
  delay = 1000
) {
  const headers = { "Content-Type": "application/json" };

  if (previewToken) {
    headers["Authorization"] = `Bearer ${previewToken}`;
  }

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const { data } = await axios.post(
        PROXIED_CMS_URL,
        {
          query,
          variables,
        },
        { timeout: 5000, headers }
      );

      if (data.errors) {
        const queryLines = query.split("\n").slice(0, 2).join("\n");
        console.error("Error on CMS fetch:", data.errors);
        console.error("Query (first 2 lines):", queryLines);
        console.error("Variables:", variables);
      } else {
        return data.data;
      }
    } catch (error) {
      if (attempt === retries) {
        const queryLines = query.split("\n").slice(0, 2).join("\n");
        console.error("Error on CMS fetch:", error.message);
        console.error("Query (first 2 lines):", queryLines);
        console.error("Variables:", variables);
        throw error;
      } else {
        await new Promise(res => setTimeout(res, delay));
        delay *= 2;
      }
    }
  }
}
