import {
  DesktopMenuItemProps,
  useDesktopMenuItemLogic,
} from "./useDesktopMenuItemLogic";
import Button from "@/components/Button";
import { LibraryDropdown } from "@/components/Menu/LibraryDropdown";
import { Transition } from "@headlessui/react";
import cn from "classnames";
import FocusTrap from "focus-trap-react";

const split = (i: number, total: number, isUserSubscribed?: boolean) => {
  return isUserSubscribed
    ? Math.ceil(total / 2) === i
    : Math.ceil(total / 2) === i - 2;
};

export const DesktopMenuItem = (props: DesktopMenuItemProps) => {
  const {
    item,
    modal,
    index,
    isActive,
    itemsCount,
    workshopView,
    isUserSubscribed,
    menuRef,
    workshopNav,
    focusHeader,
    isUserLoggedIn,
    openIndex,
    popoverStyles,
    popoverAttributes,
    suffixIcon,
    isBookNavItem,
    setButtonRef,
    setPopoverRef,
    handleButtonInteraction,
  } = useDesktopMenuItemLogic(props);
  return (
    <li
      key={`${index}-${item.label}`}
      ref={menuRef}
      className={cn(
        "bg-green",
        "relative m-0 list-none p-0 py-12",
        "lg:flex lg:h-full lg:cursor-pointer lg:content-between lg:items-center lg:py-0",
        "text-current after:absolute after:left-0 after:w-full after:h-1 after:bottom-[0.9rem] after:bg-yellow hover:after:!opacity-100 after:transition-opacity after:opacity-0",
        {
          "mr-auto": split(index + 1, itemsCount, isUserSubscribed),
          "mr-6": !split(index + 1, itemsCount, isUserSubscribed),
          "lg:border-transparent": !isActive,
          "after:!opacity-100": isActive,
          "after:!opacity-0": modal.open && modal.type === "search",
          "after:!-bottom-[0.3rem]": workshopView,
          "ml-auto":
            itemsCount > 5 && index == itemsCount - 1 && !isUserLoggedIn,
          "lg:pt-2": isUserSubscribed,
        }
      )}
    >
      {!item?.children?.length && (
        <Button
          variant="base"
          size={""}
          href={item.url}
          target={item.target || undefined}
          className={cn(
            "flex items-center justify-between gap-4 text__cta text-current lg:h-full ",
            {
              "gap-0": item.title !== null,
            }
          )}
          prefixIcon={item.title === "gift" ? "gift" : ""}
          iconClasses={cn({
            "text-currentColor": workshopNav,
            "text-dark-green": !workshopNav || focusHeader,
          })}
          onMouseOver={handleButtonInteraction}
        >
          {item.label}
        </Button>
      )}
      {!!item?.children?.length && (
        <FocusTrap active={openIndex === index}>
          <div>
            <Button
              ref={setButtonRef}
              variant="base"
              size={""}
              suffixIcon={suffixIcon}
              target={item.target || undefined}
              className="flex text__cta text-current lg:h-full focus: outline-none"
              onClick={handleButtonInteraction}
              onMouseOver={isBookNavItem ? handleButtonInteraction : undefined}
              aria-expanded={openIndex === index}
            >
              {item.label}
            </Button>
            <Transition
              show={openIndex === index}
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-225 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                ref={setPopoverRef}
                style={{
                  ...popoverStyles.popper,
                  transform: !isBookNavItem
                    ? popoverStyles.popper.transform
                    : "translate3d(0px, 71px, 0px)",
                }}
                {...popoverAttributes.popper}
              >
                <LibraryDropdown
                  items={item.children || []}
                  isTextItemDropdown={isBookNavItem}
                  close={isBookNavItem ? handleButtonInteraction : undefined}
                />
              </div>
            </Transition>
          </div>
        </FocusTrap>
      )}
    </li>
  );
};
