export const validateStripeStatus = (subscription) => {
  if (!subscription) return false;

  const status = subscription.stripe_status;

  const isPastDue = status.includes("past_due");
  const isUnpaid = status.includes("unpaid");
  const isIncomplete = status.includes("incomplete_expired");

  return isPastDue || isUnpaid || isIncomplete;
};
