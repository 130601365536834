import { USER_AUTH_URL } from "@/lib/constants";
import axios from "axios";

export default function api({ token = null, method = "GET" }) {
  const api = axios.create({
    baseURL: USER_AUTH_URL || "http://localhost",
    ...(token && { headers: { Authorization: `Bearer ${token}` } }),
    method,
    withCredentials: true,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error(error.response);
      switch (error.response?.status) {
        case 400: // Not logged in
          return Promise.reject(error.response);
        case 401: // Not logged in
          return Promise.reject();
        case 419: // Session expired
        case 503: // Down for maintenance
          // Bounce the user to the login screen with a redirect back
          window.location.reload();
          break;
        case 500:
          alert("Oops, something went wrong! The team have been notified.");
          break;
        default:
          return Promise.reject(error);
      }
    }
  );
  return api;
}
