import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getAllTopicsWithSlug() {
  const data = await serverSideCmsFetch(
    `
    {
        topics(first: 500) {
            edges {
                node {
                    slug
                }
            }
        }
    }
    `,
    { variables: {} }
  );

  return data;
}
