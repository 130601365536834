import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getOnboardingContent() {
  const interstitialQuery = `interstitial {
      image {
        altText
        mediaItemUrl
        mediaDetails {
          height
          width
        }
      }
      messages {
        message
      }
      showAllUsers
      showIfConnector
      showIf {
        condition
        comparison
        value
      }
    }`;

  const showIfQuery = `
    showAllUsers
    showIf {
      condition
      comparison
      value
    }
    `;

  const data = await serverSideCmsFetch(
    `query OnboardingQuery {
        acfOptionsOnboardingFlow {
          optionsOnboardingFlow {
            description {
              choices {
                choice
              }
              icon {
                mediaItemUrl
              }
              instruction
              question
              ${interstitialQuery}
            }
            children {
              icon {
                mediaItemUrl
              }
              instruction
              question
              ${showIfQuery}
              ${interstitialQuery}
            }
            childrenFollowUp {
              icon {
                mediaItemUrl
              }
              instruction
              question
              ${showIfQuery}
              ${interstitialQuery}
            }
            expecting {
              choices {
                choice
              }
              icon {
                mediaItemUrl
              }
              question
              ${showIfQuery}
              showIfConnector
              ${interstitialQuery}
            }
            expectingFollowUp {
              congratulatoryMessage
              instruction
              question
              icon {
                mediaItemUrl
              }
              ${showIfQuery}
              showIfConnector
              ${interstitialQuery}
            }
            topic {
              icon {
                mediaItemUrl
              }
              instruction
              question
              choices {
                icon {
                  mediaItemUrl
                }
                displayName
                choice { 
                  name
                }
              }
              ${showIfQuery}
              showIfConnector
              ${interstitialQuery}
            }
            teenInterstitial {
              image {
                altText
                mediaItemUrl
                mediaDetails {
                  height
                  width
                }
              }
              messages {
                message
              }
              showAllUsers
              showIf {
                condition
                comparison
                value
              }
            }
            circle {
              question
              icon {
                mediaItemUrl
              }
              instruction
              ${interstitialQuery}
            }
            weeklyFlex {
              aboveFormContent
              belowFormContent
              fieldGroupName
              icon {
                mediaItemUrl
                altText
              }
              ${interstitialQuery}
            }
            weeklyFlexFollowUp {
              details
              fieldGroupName
              question
              icon {
                mediaItemUrl
              }
              ${showIfQuery}
              showIfConnector
              ${interstitialQuery}
            }
            affirmationOne {
              buttonText
              description
              question
              icon {
                mediaItemUrl
              }
            }
            affirmationTwo {
              buttonText
              description
              question
              icon {
                mediaItemUrl
              }
            }
            affirmationThree {
              buttonText
              description
              question
              icon {
                mediaItemUrl
              }
            }
            success {
              subheading
              successMessage
            }
          }
        }
      }
      `,
    {}
  );
  return data.acfOptionsOnboardingFlow.optionsOnboardingFlow;
}
