const completionBadgeQuery = `
completionBadge{
  altText
  mediaItemUrl
  mediaDetails {
    height
    width
  }
}`;

export default completionBadgeQuery;
