import {
  MobileMenuItemProps,
  useMobileMenuItemLogic,
} from "./useMobileMenuItemLogic";
import Button from "@/components/Button";
import { MobileSubMenu } from "@/components/Menu/MobileMenu/MobileSubMenu";
import cn from "classnames";

export const MobileMenuItem = (props: MobileMenuItemProps) => {
  const {
    item,
    index,
    showSubMenu,
    isBookNavItem,
    isBookNavItemActive,
    suffixIconForSingleNavItem,
    closeMainMenu,
    handleClose,
    handleChildNavItemClick,
    handleParentNavItemClick,
  } = useMobileMenuItemLogic(props);
  return (
    <li
      key={`${index}-${item.label}`}
      className={cn(
        "flex p-0 m-0 list-none transition-all border-b-1 border-silver",
        {
          "items-center h-18": !isBookNavItemActive,
          "flex-col items-start h-42": isBookNavItemActive,
        }
      )}
    >
      {!item?.children?.length && (
        <Button
          href={item.url}
          target={item.target || ""}
          variant="text"
          className={cn("flex w-full h-full", {
            "justify-between": item.title === null,
          })}
          suffixIcon={suffixIconForSingleNavItem}
          iconClasses={cn({
            "scale-95 -rotate-45": item.title === null,
            "text-dark-green": item.title === "gift",
          })}
          data-menu-item
          onClick={closeMainMenu}
          prefixIcon={item.title === "gift" ? "gift" : ""}
        >
          {item.label}
        </Button>
      )}
      {!!item?.children?.length && (
        <>
          <Button
            variant="text"
            iconClasses="-rotate-180"
            suffixIcon={isBookNavItemActive ? "caretUp" : "caretLeft"}
            className={cn("flex justify-between w-full", {
              "h-full": !isBookNavItemActive,
              "h-18": isBookNavItemActive,
            })}
            onClick={handleParentNavItemClick}
          >
            {item.label}
          </Button>
          {!isBookNavItem && (
            <MobileSubMenu
              showMenu={showSubMenu}
              setShowMenu={handleClose}
              items={item.children || []}
              index={index}
            />
          )}
          {/* Sub nav for books nav items */}
          {isBookNavItemActive && (
            <nav className="bg-gray-30 w-full border-silver">
              {item.children.map((childItem, idx) => (
                <li
                  key={`${idx}-${childItem.label}`}
                  className="h-12 pl-4 border-t-1 border-silver"
                >
                  <Button
                    href={childItem.url}
                    target={childItem.target || ""}
                    variant="text"
                    className={cn("flex w-full h-full", {
                      "justify-between": childItem.title === null,
                    })}
                    data-menu-item
                    onClick={handleChildNavItemClick}
                    prefixIcon={childItem.title === "gift" ? "gift" : ""}
                  >
                    {childItem.label}
                  </Button>
                </li>
              ))}
            </nav>
          )}
        </>
      )}
    </li>
  );
};
