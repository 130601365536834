import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useLayoutEffect } from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import aa from "search-insights";

function InsightsMiddleware() {
  const { use } = useInstantSearch();

  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: aa,
      onEvent: (event, aa) => {
        const { insightsMethod, payload } = event;
        if (insightsMethod) {
          aa(insightsMethod, payload);
        }
      },
    });

    return use(middleware);
  }, [use]);

  return null;
}

export default InsightsMiddleware;
