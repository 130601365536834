import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

export async function getWelcomeContent() {
  const data = await serverSideCmsFetch(
    `
    query WelcomeQuery {
        acfOptionsOnboardingFlow {
          optionsOnboardingFlow {
            welcomeScreen {
              buttonLabel
              greeting
              message
              images {
                image {
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                  }
                  altText
                }
              }
            }
          }
        }
      }
    `,
    {}
  );

  return data.acfOptionsOnboardingFlow.optionsOnboardingFlow.welcomeScreen;
}
