import {
  useLibraryDropdownLogic,
  LibraryDropdownProps,
} from "./useLibraryDropdownLogic";
import Container from "@/components/Containers/Container";
import { LibraryDropdownItem } from "@/components/Menu/LibraryDropdownItem";
import cn from "classnames";

export const LibraryDropdown = (props: LibraryDropdownProps) => {
  const {
    items,
    isTextItemDropdown,
    close,
    handleMouseLeave,
  } = useLibraryDropdownLogic(props);
  return (
    <nav
      onMouseLeave={handleMouseLeave}
      className={cn("flex justify-center flex-col p-0 py-6 bg-white top-full", {
        "w-screen": !isTextItemDropdown,
        "w-[180px]": isTextItemDropdown,
        "border-t-1 border-silver": !isTextItemDropdown,
        "shadow": isTextItemDropdown
      })}
    >
      <Container width={isTextItemDropdown ? "box" : undefined}>
        <ul
          className={cn("w-full", {
            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-3.5 gap-x-4 px-6 sm:px-0":
              !isTextItemDropdown,
            "flex flex-col gap-y-2": isTextItemDropdown,
          })}
        >
          {items.map((sub, i) => (
            <LibraryDropdownItem
              textOnly={isTextItemDropdown}
              key={`${i}-${sub.label}`}
              href={sub.url}
              onClick={close}
              label={sub.label}
              newItem={sub.new}
              iconUrl={sub.mediaItemUrl}
            />
          ))}
        </ul>
      </Container>
    </nav>
  );
};
