import ContentLoader from "react-content-loader";

const SkeletonMenuItem = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 200 70"
    backgroundColor="#D7D7D7"
    foregroundColor="#ecebeb"
    width={120}
    height={70}
  >
    <rect x={0} y={20} width="120" height="30" />
  </ContentLoader>
);

export default SkeletonMenuItem;
