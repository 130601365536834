import { useRouter } from "next/router";
import {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
  useMemo,
} from "react";

export const initialState = {
  topics: [],
  lengths: [],
  types: [],
  filterBookmarks: false,
  cleared: false,
};

const filterKeys = ["topics", "lengths", "types"];

export const FilterContext = createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case "topics":
      return {
        ...state,
        topics: action.value,
      };
    case "types":
      return {
        ...state,
        types: action.value,
      };
    case "lengths":
      return {
        ...state,
        lengths: action.value,
      };
    case "filter_bookmarks":
      return {
        ...state,
        filterBookmarks: action.value,
      };
    case "all_filters":
      return {
        ...state,
        topics: action.topics,
        lengths: action.lengths,
        types: action.types,
      };
    case "clear":
      return {
        ...initialState,
        cleared: true,
      };
  }
};

export function FilterProvider({ children }) {
  const router = useRouter();
  const [filters, dispatch] = useReducer(reducer, initialState);
  const [topicPageId, setTopicPageId] = useState(null);
  const [previousPage, setPreviousPage] = useState(router.pathname);

  const isFiltered = useMemo(
    () =>
      !!filters.topics.length ||
      !!filters.lengths.length ||
      !!filters.types.length ||
      filters.filterBookmarks,
    [filters.topics, filters.lengths, filters.types, filters.filterBookmarks]
  );

  const filterCount = useMemo(() => {
    return filterKeys.reduce((previousValue, currentValue, i) => {
      return previousValue + filters[filterKeys[i]].length;
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.topics, filters.lengths, filters.types]);

  /*
   * The filters should only clear when navigating between topics, if a user goes to a piece
   * of content and goes back, the filters should remain.
   * Defining userAction: "newTopic" in the dispatch to control whether the analytics tracking should be triggered.
   */
  useEffect(() => {
    if (
      previousPage.includes("/topic/[slug]") &&
      router.pathname.includes("/topic/[slug]")
    ) {
      dispatch({
        type: "clear",
        payload: {
          userAction: "newTopic",
        },
      });
    }
    setPreviousPage(router.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <FilterContext.Provider
      value={{
        filters,
        dispatch,
        topicPageId,
        setTopicPageId,
        isFiltered,
        filterCount,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export const useFilters = () => useContext(FilterContext);
