import { clientSideCmsFetch } from "@/lib/clientSideCmsApi";
import { serverSideCmsFetch } from "@/lib/serverSideCmsApi";

const getPodcastQuery = `query getPodcasts($offset: Int, $size: Int) {
  podcasts(where: {offsetPagination: {size: $size, offset: $offset}}) {
    nodes {
      id: podcastId
      title
      slug
      topics {
        nodes {
          name
          slug
        }
      }
      excerpt
      contentDuration {
        timeToConsume
      }
      date
      podcastContent {
        visibility
      }
    }
    pageInfo {
      offsetPagination {
        total
        hasMore
        hasPrevious
      }
    }
  }
}
`;

export async function getPodcasts({ offset = 0, size = 7, server = false }) {
  let data;
  if (!server) {
    data = await clientSideCmsFetch(getPodcastQuery, {
      variables: {
        offset,
        size
      },
    });
  } else {
    data = await serverSideCmsFetch(getPodcastQuery, {
      variables: {
        offset,
        size
      },
    });
  }
  return {
    podcasts: data.podcasts?.nodes,
    pagination: data.podcasts?.pageInfo,
  };
}